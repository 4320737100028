/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type FeedTeamFactCheckDialog_claimDescription$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedTeamFactCheckCard_clusterTeamFactCheck$ref: FragmentReference;
export type FeedTeamFactCheckCard_clusterTeamFactCheck = {|
  +claim: ?string,
  +rating: ?string,
  +fact_check_title: ?string,
  +fact_check_summary: ?string,
  +media_count: ?number,
  +requests_count: ?number,
  +claim_description: ?{|
    +$fragmentRefs: FeedTeamFactCheckDialog_claimDescription$ref
  |},
  +$refType: FeedTeamFactCheckCard_clusterTeamFactCheck$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "FeedTeamFactCheckCard_clusterTeamFactCheck",
  "type": "ClusterTeamFactCheck",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "claim",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fact_check_title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fact_check_summary",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "media_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requests_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "claim_description",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimDescription",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "FeedTeamFactCheckDialog_claimDescription",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b3020e9085ac24df5c36aa4ffbcc0440';
module.exports = node;
