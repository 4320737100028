/**
 * @flow
 * @relayHash 048bd52db710a3a87ee5c3c5c310f94c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkProjectMediaMarkReadInput = {
  ids: $ReadOnlyArray<?string>,
  read: boolean,
  clientMutationId?: ?string,
};
export type BulkActionsMenuMarkAsReadMutationVariables = {|
  input: BulkProjectMediaMarkReadInput
|};
export type BulkActionsMenuMarkAsReadMutationResponse = {|
  +bulkProjectMediaMarkRead: ?{|
    +updated_objects: ?$ReadOnlyArray<?{|
      +id: string,
      +is_read: ?boolean,
    |}>
  |}
|};
export type BulkActionsMenuMarkAsReadMutation = {|
  variables: BulkActionsMenuMarkAsReadMutationVariables,
  response: BulkActionsMenuMarkAsReadMutationResponse,
|};
*/


/*
mutation BulkActionsMenuMarkAsReadMutation(
  $input: BulkProjectMediaMarkReadInput!
) {
  bulkProjectMediaMarkRead(input: $input) {
    updated_objects {
      id
      is_read
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkProjectMediaMarkReadInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "bulkProjectMediaMarkRead",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "BulkProjectMediaMarkReadInput!"
      }
    ],
    "concreteType": "BulkProjectMediaMarkReadPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updated_objects",
        "storageKey": null,
        "args": null,
        "concreteType": "ProjectMedia",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "is_read",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "BulkActionsMenuMarkAsReadMutation",
  "id": null,
  "text": "mutation BulkActionsMenuMarkAsReadMutation(\n  $input: BulkProjectMediaMarkReadInput!\n) {\n  bulkProjectMediaMarkRead(input: $input) {\n    updated_objects {\n      id\n      is_read\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BulkActionsMenuMarkAsReadMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "BulkActionsMenuMarkAsReadMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b12840db572e6019fd9ba990d82fed25';
module.exports = node;
