/**
 * @flow
 * @relayHash f7197520bb3677ac54d860b8743d4e3a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DuplicateTeamMutationInput = {
  team_id: string,
  custom_slug?: ?string,
  custom_name?: ?string,
  clientMutationId?: ?string,
};
export type CreateTeamDialogDuplicateTeamMutationVariables = {|
  input: DuplicateTeamMutationInput
|};
export type CreateTeamDialogDuplicateTeamMutationResponse = {|
  +duplicateTeam: ?{|
    +team: ?{|
      +slug: string
    |}
  |}
|};
export type CreateTeamDialogDuplicateTeamMutation = {|
  variables: CreateTeamDialogDuplicateTeamMutationVariables,
  response: CreateTeamDialogDuplicateTeamMutationResponse,
|};
*/


/*
mutation CreateTeamDialogDuplicateTeamMutation(
  $input: DuplicateTeamMutationInput!
) {
  duplicateTeam(input: $input) {
    team {
      slug
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DuplicateTeamMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DuplicateTeamMutationInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateTeamDialogDuplicateTeamMutation",
  "id": null,
  "text": "mutation CreateTeamDialogDuplicateTeamMutation(\n  $input: DuplicateTeamMutationInput!\n) {\n  duplicateTeam(input: $input) {\n    team {\n      slug\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateTeamDialogDuplicateTeamMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "duplicateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "DuplicateTeamMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateTeamDialogDuplicateTeamMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "duplicateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "DuplicateTeamMutationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd17b4a69f1af4107689b4a3d932d5d4';
module.exports = node;
