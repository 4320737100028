/**
 * @flow
 * @relayHash 5805d28f874705031edeec4ea14c5bcb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateTagTextInput = {
  team_id: number,
  text: string,
  clientMutationId?: ?string,
};
export type SaveTagCreateTagTextMutationVariables = {|
  input: CreateTagTextInput
|};
export type SaveTagCreateTagTextMutationResponse = {|
  +createTagText: ?{|
    +team: ?{|
      +id: string,
      +tag_texts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +text: ?string,
            +tags_count: ?number,
            +updated_at: ?string,
          |}
        |}>
      |},
    |}
  |}
|};
export type SaveTagCreateTagTextMutation = {|
  variables: SaveTagCreateTagTextMutationVariables,
  response: SaveTagCreateTagTextMutationResponse,
|};
*/


/*
mutation SaveTagCreateTagTextMutation(
  $input: CreateTagTextInput!
) {
  createTagText(input: $input) {
    team {
      id
      tag_texts(first: 10000) {
        edges {
          node {
            id
            text
            tags_count
            updated_at
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTagTextInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createTagText",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateTagTextInput!"
      }
    ],
    "concreteType": "CreateTagTextPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tag_texts",
            "storageKey": "tag_texts(first:10000)",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000,
                "type": "Int"
              }
            ],
            "concreteType": "TagTextConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagTextEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagText",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "text",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "tags_count",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updated_at",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveTagCreateTagTextMutation",
  "id": null,
  "text": "mutation SaveTagCreateTagTextMutation(\n  $input: CreateTagTextInput!\n) {\n  createTagText(input: $input) {\n    team {\n      id\n      tag_texts(first: 10000) {\n        edges {\n          node {\n            id\n            text\n            tags_count\n            updated_at\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveTagCreateTagTextMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveTagCreateTagTextMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6453d89b379716f36efc9beda7deebc';
module.exports = node;
