/**
 * @flow
 * @relayHash f8ffe9eb21a63096a910d588f42153c9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SavedSearchQueryVariables = {|
  id: string
|};
export type SavedSearchQueryResponse = {|
  +saved_search: ?{|
    +id: string,
    +dbid: ?number,
    +title: ?string,
    +filters: ?string,
    +feeds: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +name: ?string,
          +id: string,
        |}
      |}>
    |},
    +is_part_of_feeds: ?boolean,
    +medias_count: ?number,
    +team: ?{|
      +id: string,
      +slug: string,
      +name: string,
      +permissions: ?string,
    |},
  |}
|};
export type SavedSearchQuery = {|
  variables: SavedSearchQueryVariables,
  response: SavedSearchQueryResponse,
|};
*/


/*
query SavedSearchQuery(
  $id: ID!
) {
  saved_search(id: $id) {
    id
    dbid
    title
    filters
    feeds(first: 100) {
      edges {
        node {
          name
          id
        }
      }
    }
    is_part_of_feeds
    medias_count: items_count
    team {
      id
      slug
      name
      permissions
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "saved_search",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id",
        "type": "ID!"
      }
    ],
    "concreteType": "SavedSearch",
    "plural": false,
    "selections": [
      v1,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "dbid",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "filters",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feeds",
        "storageKey": "feeds(first:100)",
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 100,
            "type": "Int"
          }
        ],
        "concreteType": "FeedConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "FeedEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Feed",
                "plural": false,
                "selections": [
                  v2,
                  v1
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "is_part_of_feeds",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": "medias_count",
        "name": "items_count",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "PublicTeam",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "permissions",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SavedSearchQuery",
  "id": null,
  "text": "query SavedSearchQuery(\n  $id: ID!\n) {\n  saved_search(id: $id) {\n    id\n    dbid\n    title\n    filters\n    feeds(first: 100) {\n      edges {\n        node {\n          name\n          id\n        }\n      }\n    }\n    is_part_of_feeds\n    medias_count: items_count\n    team {\n      id\n      slug\n      name\n      permissions\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SavedSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v3
  },
  "operation": {
    "kind": "Operation",
    "name": "SavedSearchQuery",
    "argumentDefinitions": v0,
    "selections": v3
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eebe57b5b3fad827b89702f2c18b0e20';
module.exports = node;
