/**
 * @flow
 * @relayHash db6d6c253a36fb4830db293ac5b26baf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MediaCardLarge_projectMedia$ref = any;
export type MediaCardLargeQueryVariables = {|
  ids: string
|};
export type MediaCardLargeQueryResponse = {|
  +project_media: ?{|
    +$fragmentRefs: MediaCardLarge_projectMedia$ref
  |}
|};
export type MediaCardLargeQuery = {|
  variables: MediaCardLargeQueryVariables,
  response: MediaCardLargeQueryResponse,
|};
*/


/*
query MediaCardLargeQuery(
  $ids: String!
) {
  project_media(ids: $ids) {
    ...MediaCardLarge_projectMedia
    id
  }
}

fragment MediaCardLarge_projectMedia on ProjectMedia {
  id
  team {
    id
    dbid
    slug
    verification_statuses
  }
  media {
    type
    url
    quote
    domain
    metadata
    embed_path
    file_path
    thumbnail_path
    id
  }
  ...AspectRatio_projectMedia
  ...WebPageMediaCard_projectMedia
  ...MediaCardLargeFooter_projectMedia
}

fragment AspectRatio_projectMedia on ProjectMedia {
  id
  show_warning_cover
  dynamic_annotation_flag {
    data
    annotator {
      name
      id
    }
    id
  }
  ...SensitiveContentMenuButton_projectMedia
}

fragment WebPageMediaCard_projectMedia on ProjectMedia {
  ...AspectRatio_projectMedia
  picture
  team {
    get_embed_whitelist
    id
  }
  media {
    metadata
    id
  }
}

fragment MediaCardLargeFooter_projectMedia on ProjectMedia {
  media_slug
  title
  last_seen
  requests_count
  type
  media {
    quote
    id
  }
  extracted_text: annotation(annotation_type: "extracted_text") {
    data
    id
  }
  transcription: annotation(annotation_type: "transcription") {
    data
    id
  }
  ...MediaCardLargeActions_projectMedia
}

fragment MediaCardLargeActions_projectMedia on ProjectMedia {
  id
  media {
    url
    type
    metadata
    id
  }
  picture
  url
  transcription: annotation(annotation_type: "transcription") {
    data
    id
  }
  extracted_text: annotation(annotation_type: "extracted_text") {
    data
    id
  }
  ...MediaLanguageSwitcher_projectMedia
}

fragment MediaLanguageSwitcher_projectMedia on ProjectMedia {
  language_code
  team {
    get_languages
    id
  }
  language: annotation(annotation_type: "language") {
    id
  }
}

fragment SensitiveContentMenuButton_projectMedia on ProjectMedia {
  dbid
  show_warning_cover
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ids",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "data",
  "args": null,
  "storageKey": null
},
v7 = [
  v6,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MediaCardLargeQuery",
  "id": null,
  "text": "query MediaCardLargeQuery(\n  $ids: String!\n) {\n  project_media(ids: $ids) {\n    ...MediaCardLarge_projectMedia\n    id\n  }\n}\n\nfragment MediaCardLarge_projectMedia on ProjectMedia {\n  id\n  team {\n    id\n    dbid\n    slug\n    verification_statuses\n  }\n  media {\n    type\n    url\n    quote\n    domain\n    metadata\n    embed_path\n    file_path\n    thumbnail_path\n    id\n  }\n  ...AspectRatio_projectMedia\n  ...WebPageMediaCard_projectMedia\n  ...MediaCardLargeFooter_projectMedia\n}\n\nfragment AspectRatio_projectMedia on ProjectMedia {\n  id\n  show_warning_cover\n  dynamic_annotation_flag {\n    data\n    annotator {\n      name\n      id\n    }\n    id\n  }\n  ...SensitiveContentMenuButton_projectMedia\n}\n\nfragment WebPageMediaCard_projectMedia on ProjectMedia {\n  ...AspectRatio_projectMedia\n  picture\n  team {\n    get_embed_whitelist\n    id\n  }\n  media {\n    metadata\n    id\n  }\n}\n\nfragment MediaCardLargeFooter_projectMedia on ProjectMedia {\n  media_slug\n  title\n  last_seen\n  requests_count\n  type\n  media {\n    quote\n    id\n  }\n  extracted_text: annotation(annotation_type: \"extracted_text\") {\n    data\n    id\n  }\n  transcription: annotation(annotation_type: \"transcription\") {\n    data\n    id\n  }\n  ...MediaCardLargeActions_projectMedia\n}\n\nfragment MediaCardLargeActions_projectMedia on ProjectMedia {\n  id\n  media {\n    url\n    type\n    metadata\n    id\n  }\n  picture\n  url\n  transcription: annotation(annotation_type: \"transcription\") {\n    data\n    id\n  }\n  extracted_text: annotation(annotation_type: \"extracted_text\") {\n    data\n    id\n  }\n  ...MediaLanguageSwitcher_projectMedia\n}\n\nfragment MediaLanguageSwitcher_projectMedia on ProjectMedia {\n  language_code\n  team {\n    get_languages\n    id\n  }\n  language: annotation(annotation_type: \"language\") {\n    id\n  }\n}\n\nfragment SensitiveContentMenuButton_projectMedia on ProjectMedia {\n  dbid\n  show_warning_cover\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaCardLargeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "MediaCardLarge_projectMedia",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaCardLargeQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "slug",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "verification_statuses",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "get_embed_whitelist",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "get_languages",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "media",
            "storageKey": null,
            "args": null,
            "concreteType": "Media",
            "plural": false,
            "selections": [
              v4,
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "quote",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "domain",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "metadata",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "embed_path",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "file_path",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "thumbnail_path",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "show_warning_cover",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "dynamic_annotation_flag",
            "storageKey": null,
            "args": null,
            "concreteType": "Dynamic",
            "plural": false,
            "selections": [
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "annotator",
                "storageKey": null,
                "args": null,
                "concreteType": "Annotator",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  v2
                ]
              },
              v2
            ]
          },
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "picture",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "media_slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "last_seen",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "requests_count",
            "args": null,
            "storageKey": null
          },
          v4,
          {
            "kind": "LinkedField",
            "alias": "extracted_text",
            "name": "annotation",
            "storageKey": "annotation(annotation_type:\"extracted_text\")",
            "args": [
              {
                "kind": "Literal",
                "name": "annotation_type",
                "value": "extracted_text",
                "type": "String!"
              }
            ],
            "concreteType": "Annotation",
            "plural": false,
            "selections": v7
          },
          {
            "kind": "LinkedField",
            "alias": "transcription",
            "name": "annotation",
            "storageKey": "annotation(annotation_type:\"transcription\")",
            "args": [
              {
                "kind": "Literal",
                "name": "annotation_type",
                "value": "transcription",
                "type": "String!"
              }
            ],
            "concreteType": "Annotation",
            "plural": false,
            "selections": v7
          },
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "language_code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "language",
            "name": "annotation",
            "storageKey": "annotation(annotation_type:\"language\")",
            "args": [
              {
                "kind": "Literal",
                "name": "annotation_type",
                "value": "language",
                "type": "String!"
              }
            ],
            "concreteType": "Annotation",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b3b0d8eccfd011fe4304ff22caf6078e';
module.exports = node;
