/**
 * @flow
 * @relayHash 263d9adafb4a2a8cd395f4dc7cbcd450
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateTiplineResourceInput = {
  uuid?: ?string,
  title?: ?string,
  content?: ?string,
  language?: ?string,
  header_type?: ?string,
  header_overlay_text?: ?string,
  content_type?: ?string,
  rss_feed_url?: ?string,
  number_of_articles?: ?number,
  clientMutationId?: ?string,
};
export type SmoochBotResourceEditorCreateMutationVariables = {|
  input: CreateTiplineResourceInput
|};
export type SmoochBotResourceEditorCreateMutationResponse = {|
  +createTiplineResource: ?{|
    +tipline_resource: ?{|
      +id: string,
      +dbid: ?number,
    |}
  |}
|};
export type SmoochBotResourceEditorCreateMutation = {|
  variables: SmoochBotResourceEditorCreateMutationVariables,
  response: SmoochBotResourceEditorCreateMutationResponse,
|};
*/


/*
mutation SmoochBotResourceEditorCreateMutation(
  $input: CreateTiplineResourceInput!
) {
  createTiplineResource(input: $input) {
    tipline_resource {
      id
      dbid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTiplineResourceInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createTiplineResource",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateTiplineResourceInput!"
      }
    ],
    "concreteType": "CreateTiplineResourcePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tipline_resource",
        "storageKey": null,
        "args": null,
        "concreteType": "TiplineResource",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dbid",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SmoochBotResourceEditorCreateMutation",
  "id": null,
  "text": "mutation SmoochBotResourceEditorCreateMutation(\n  $input: CreateTiplineResourceInput!\n) {\n  createTiplineResource(input: $input) {\n    tipline_resource {\n      id\n      dbid\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SmoochBotResourceEditorCreateMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SmoochBotResourceEditorCreateMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'abe210947fbe3261f9d1f14a7958512d';
module.exports = node;
