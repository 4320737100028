/**
 * @flow
 * @relayHash 20ee66f0eb866920df9146508a4a61fc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateFeedInput = {
  description?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  saved_search_id?: ?number,
  published?: ?boolean,
  discoverable?: ?boolean,
  name: string,
  licenses: $ReadOnlyArray<?number>,
  dataPoints?: ?$ReadOnlyArray<?number>,
  clientMutationId?: ?string,
};
export type SaveFeedCreateFeedMutationVariables = {|
  input: CreateFeedInput
|};
export type SaveFeedCreateFeedMutationResponse = {|
  +createFeed: ?{|
    +feed: ?{|
      +dbid: ?number,
      +saved_search: ?{|
        +is_part_of_feeds: ?boolean
      |},
    |},
    +team: ?{|
      +feeds: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +dbid: ?number,
            +name: ?string,
            +team_id: ?number,
            +type: string,
          |}
        |}>
      |}
    |},
  |}
|};
export type SaveFeedCreateFeedMutation = {|
  variables: SaveFeedCreateFeedMutationVariables,
  response: SaveFeedCreateFeedMutationResponse,
|};
*/


/*
mutation SaveFeedCreateFeedMutation(
  $input: CreateFeedInput!
) {
  createFeed(input: $input) {
    feed {
      dbid
      saved_search {
        is_part_of_feeds
        id
      }
      id
    }
    team {
      feeds(first: 10000) {
        edges {
          node {
            id
            dbid
            name
            team_id
            type: __typename
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateFeedInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateFeedInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "is_part_of_feeds",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "feeds",
  "storageKey": "feeds(first:10000)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000,
      "type": "Int"
    }
  ],
  "concreteType": "FeedConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "FeedEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Feed",
          "plural": false,
          "selections": [
            v4,
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "team_id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": "type",
              "name": "__typename",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveFeedCreateFeedMutation",
  "id": null,
  "text": "mutation SaveFeedCreateFeedMutation(\n  $input: CreateFeedInput!\n) {\n  createFeed(input: $input) {\n    feed {\n      dbid\n      saved_search {\n        is_part_of_feeds\n        id\n      }\n      id\n    }\n    team {\n      feeds(first: 10000) {\n        edges {\n          node {\n            id\n            dbid\n            name\n            team_id\n            type: __typename\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveFeedCreateFeedMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createFeed",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateFeedPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": [
                  v3
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveFeedCreateFeedMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createFeed",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateFeedPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": [
                  v3,
                  v4
                ]
              },
              v4
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v5,
              v4
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2d50fe5ff32d828d0713a17c8492f056';
module.exports = node;
