/**
 * @flow
 * @relayHash b9e7d1a76fb7c553450612e69eb9e355
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RulesQueryVariables = {|
  teamSlug: string
|};
export type RulesQueryResponse = {|
  +team: ?{|
    +id: string,
    +get_rules: ?any,
    +rules_json_schema: ?string,
  |}
|};
export type RulesQuery = {|
  variables: RulesQueryVariables,
  response: RulesQueryResponse,
|};
*/


/*
query RulesQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    id
    get_rules
    rules_json_schema
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "team",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "teamSlug",
        "type": "String"
      }
    ],
    "concreteType": "Team",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "get_rules",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rules_json_schema",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RulesQuery",
  "id": null,
  "text": "query RulesQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    id\n    get_rules\n    rules_json_schema\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RulesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "RulesQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1eb865adcc0c2742c16b1dd903db429e';
module.exports = node;
