/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AddLanguageAction_team$ref = any;
type LanguageListItem_team$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type LanguagesComponent_team$ref: FragmentReference;
export type LanguagesComponent_team = {|
  +id: string,
  +get_language: ?string,
  +get_languages: ?string,
  +get_language_detection: ?boolean,
  +$fragmentRefs: LanguageListItem_team$ref & AddLanguageAction_team$ref,
  +$refType: LanguagesComponent_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "LanguagesComponent_team",
  "type": "Team",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_language",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_languages",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_language_detection",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "LanguageListItem_team",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AddLanguageAction_team",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '4cd6922643e269b8bb8f6458f3e89265';
module.exports = node;
