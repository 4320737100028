/**
 * @flow
 * @relayHash b1b0e9acecc57b99616301b03e4fe85f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroySavedSearchInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type SavedSearchDestroySavedSearchMutationVariables = {|
  input: DestroySavedSearchInput
|};
export type SavedSearchDestroySavedSearchMutationResponse = {|
  +destroySavedSearch: ?{|
    +deletedId: ?string,
    +team: ?{|
      +id: string
    |},
  |}
|};
export type SavedSearchDestroySavedSearchMutation = {|
  variables: SavedSearchDestroySavedSearchMutationVariables,
  response: SavedSearchDestroySavedSearchMutationResponse,
|};
*/


/*
mutation SavedSearchDestroySavedSearchMutation(
  $input: DestroySavedSearchInput!
) {
  destroySavedSearch(input: $input) {
    deletedId
    team {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroySavedSearchInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "destroySavedSearch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DestroySavedSearchInput!"
      }
    ],
    "concreteType": "DestroySavedSearchPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SavedSearchDestroySavedSearchMutation",
  "id": null,
  "text": "mutation SavedSearchDestroySavedSearchMutation(\n  $input: DestroySavedSearchInput!\n) {\n  destroySavedSearch(input: $input) {\n    deletedId\n    team {\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SavedSearchDestroySavedSearchMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SavedSearchDestroySavedSearchMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '36a9e978320802df3e6570316e6df8c7';
module.exports = node;
