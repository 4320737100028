/**
 * @flow
 * @relayHash 885c528900cf642bc003db32f9d99ce2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchFieldTagQueryVariables = {|
  teamSlug: string,
  keyword?: ?string,
  pageSize?: ?number,
  random: string,
|};
export type SearchFieldTagQueryResponse = {|
  +team: ?{|
    +tag_texts_count: ?number,
    +tag_texts: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +text: ?string
        |}
      |}>
    |},
  |}
|};
export type SearchFieldTagQuery = {|
  variables: SearchFieldTagQueryVariables,
  response: SearchFieldTagQueryResponse,
|};
*/


/*
query SearchFieldTagQuery(
  $teamSlug: String!
  $keyword: String
  $pageSize: Int
  $random: String!
) {
  team(slug: $teamSlug, random: $random) {
    tag_texts_count(keyword: $keyword)
    tag_texts(first: $pageSize, keyword: $keyword) {
      edges {
        node {
          text
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "keyword",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "random",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "random",
    "variableName": "random",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "Variable",
  "name": "keyword",
  "variableName": "keyword",
  "type": "String"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tag_texts_count",
  "args": [
    v2
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize",
    "type": "Int"
  },
  v2
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "text",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SearchFieldTagQuery",
  "id": null,
  "text": "query SearchFieldTagQuery(\n  $teamSlug: String!\n  $keyword: String\n  $pageSize: Int\n  $random: String!\n) {\n  team(slug: $teamSlug, random: $random) {\n    tag_texts_count(keyword: $keyword)\n    tag_texts(first: $pageSize, keyword: $keyword) {\n      edges {\n        node {\n          text\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SearchFieldTagQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tag_texts",
            "storageKey": null,
            "args": v4,
            "concreteType": "TagTextConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagTextEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagText",
                    "plural": false,
                    "selections": [
                      v5
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchFieldTagQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tag_texts",
            "storageKey": null,
            "args": v4,
            "concreteType": "TagTextConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagTextEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagText",
                    "plural": false,
                    "selections": [
                      v5,
                      v6
                    ]
                  }
                ]
              }
            ]
          },
          v6
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5ab0afb1050ff6ca5ba126f4d404152';
module.exports = node;
