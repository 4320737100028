/**
 * @flow
 * @relayHash c5d4fcd1a9af8e2239628b17ceb45faf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTiplineNewsletterInput = {
  id?: ?string,
  enabled?: ?boolean,
  introduction?: ?string,
  language?: ?string,
  header_type?: ?string,
  header_overlay_text?: ?string,
  content_type?: ?string,
  rss_feed_url?: ?string,
  number_of_articles?: ?number,
  first_article?: ?string,
  second_article?: ?string,
  third_article?: ?string,
  footer?: ?string,
  send_every?: ?any,
  send_on?: ?string,
  timezone?: ?string,
  time?: ?string,
  clientMutationId?: ?string,
};
export type NewsletterComponentUpdateMutationVariables = {|
  input: UpdateTiplineNewsletterInput
|};
export type NewsletterComponentUpdateMutationResponse = {|
  +updateTiplineNewsletter: ?{|
    +tipline_newsletter: ?{|
      +id: string,
      +introduction: ?string,
      +language: ?string,
      +header_type: ?string,
      +header_overlay_text: ?string,
      +content_type: ?string,
      +first_article: ?string,
      +second_article: ?string,
      +third_article: ?string,
      +rss_feed_url: ?string,
      +number_of_articles: ?number,
      +send_every: ?any,
      +send_on: ?string,
      +timezone: ?string,
      +time: ?string,
      +enabled: ?boolean,
      +last_delivery_error: ?string,
      +last_scheduled_at: ?number,
      +last_scheduled_by: ?{|
        +name: ?string
      |},
    |}
  |}
|};
export type NewsletterComponentUpdateMutation = {|
  variables: NewsletterComponentUpdateMutationVariables,
  response: NewsletterComponentUpdateMutationResponse,
|};
*/


/*
mutation NewsletterComponentUpdateMutation(
  $input: UpdateTiplineNewsletterInput!
) {
  updateTiplineNewsletter(input: $input) {
    tipline_newsletter {
      id
      introduction
      language
      header_type
      header_overlay_text
      content_type
      first_article
      second_article
      third_article
      rss_feed_url
      number_of_articles
      send_every
      send_on
      timezone
      time
      enabled
      last_delivery_error
      last_scheduled_at
      last_scheduled_by {
        name
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTiplineNewsletterInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateTiplineNewsletterInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "introduction",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "header_type",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "header_overlay_text",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content_type",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "first_article",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "second_article",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "third_article",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rss_feed_url",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "number_of_articles",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "send_every",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "send_on",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timezone",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "time",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enabled",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "last_delivery_error",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "last_scheduled_at",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "NewsletterComponentUpdateMutation",
  "id": null,
  "text": "mutation NewsletterComponentUpdateMutation(\n  $input: UpdateTiplineNewsletterInput!\n) {\n  updateTiplineNewsletter(input: $input) {\n    tipline_newsletter {\n      id\n      introduction\n      language\n      header_type\n      header_overlay_text\n      content_type\n      first_article\n      second_article\n      third_article\n      rss_feed_url\n      number_of_articles\n      send_every\n      send_on\n      timezone\n      time\n      enabled\n      last_delivery_error\n      last_scheduled_at\n      last_scheduled_by {\n        name\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewsletterComponentUpdateMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTiplineNewsletter",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTiplineNewsletterPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tipline_newsletter",
            "storageKey": null,
            "args": null,
            "concreteType": "TiplineNewsletter",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              v13,
              v14,
              v15,
              v16,
              v17,
              v18,
              v19,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "last_scheduled_by",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v20
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewsletterComponentUpdateMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTiplineNewsletter",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTiplineNewsletterPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tipline_newsletter",
            "storageKey": null,
            "args": null,
            "concreteType": "TiplineNewsletter",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              v13,
              v14,
              v15,
              v16,
              v17,
              v18,
              v19,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "last_scheduled_by",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v20,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1275f3b3a19a0b2153bfaa5c1b044e2d';
module.exports = node;
