/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ArticleForm_team$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExplainerForm_team$ref: FragmentReference;
export type ExplainerForm_team = {|
  +$fragmentRefs: ArticleForm_team$ref,
  +$refType: ExplainerForm_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExplainerForm_team",
  "type": "Team",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ArticleForm_team",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3e785fe6ffe512bb7b493910f087c0f6';
module.exports = node;
