/**
 * @flow
 * @relayHash dc607ad33b9d0bc3cf74d9092d45c9b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddKeywordToTiplineMenuInput = {
  language: string,
  keyword: string,
  menu: string,
  menuOptionIndex: number,
  clientMutationId?: ?string,
};
export type SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutationVariables = {|
  input: AddKeywordToTiplineMenuInput
|};
export type SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutationResponse = {|
  +addNluKeywordToTiplineMenu: ?{|
    +success: ?boolean
  |}
|};
export type SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutation = {|
  variables: SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutationVariables,
  response: SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutationResponse,
|};
*/


/*
mutation SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutation(
  $input: AddKeywordToTiplineMenuInput!
) {
  addNluKeywordToTiplineMenu(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddKeywordToTiplineMenuInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addNluKeywordToTiplineMenu",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddKeywordToTiplineMenuInput!"
      }
    ],
    "concreteType": "AddKeywordToTiplineMenuPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutation",
  "id": null,
  "text": "mutation SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutation(\n  $input: AddKeywordToTiplineMenuInput!\n) {\n  addNluKeywordToTiplineMenu(input: $input) {\n    success\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SmoochBotMenuKeywordsAddNluKeywordToTiplineMenuMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be9d36ee0054c90a078a1bc0f24fcd3f';
module.exports = node;
