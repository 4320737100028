/**
 * @flow
 * @relayHash 1266a44a760f9725d5f29304b2ac14a9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BulkActionsAssign_team$ref = any;
type BulkActionsRemoveTag_team$ref = any;
type BulkActionsStatus_team$ref = any;
type BulkActionsTag_team$ref = any;
export type BulkActionsMenuRendererQueryVariables = {|
  teamSlug: string
|};
export type BulkActionsMenuRendererQueryResponse = {|
  +team: ?{|
    +id: string,
    +dbid: ?number,
    +name: string,
    +permissions: ?string,
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +dbid: ?number
        |}
      |}>
    |},
    +check_search_trash: ?{|
      +id: string,
      +number_of_results: ?number,
    |},
    +check_search_spam: ?{|
      +id: string,
      +number_of_results: ?number,
    |},
    +public_team: ?{|
      +id: string,
      +trash_count: ?number,
      +spam_count: ?number,
    |},
    +search_id: ?string,
    +$fragmentRefs: BulkActionsAssign_team$ref & BulkActionsStatus_team$ref & BulkActionsTag_team$ref & BulkActionsRemoveTag_team$ref,
  |}
|};
export type BulkActionsMenuRendererQuery = {|
  variables: BulkActionsMenuRendererQueryVariables,
  response: BulkActionsMenuRendererQueryResponse,
|};
*/


/*
query BulkActionsMenuRendererQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    id
    dbid
    name
    permissions
    projects(first: 10000) {
      edges {
        node {
          dbid
          id
        }
      }
    }
    ...BulkActionsAssign_team
    ...BulkActionsStatus_team
    ...BulkActionsTag_team
    ...BulkActionsRemoveTag_team
    check_search_trash {
      id
      number_of_results
    }
    check_search_spam {
      id
      number_of_results
    }
    public_team {
      id
      trash_count
      spam_count
    }
    search_id
  }
}

fragment BulkActionsAssign_team on Team {
  team_users(first: 10000) {
    edges {
      node {
        id
        status
        user {
          id
          dbid
          name
          is_bot
        }
      }
    }
  }
}

fragment BulkActionsStatus_team on Team {
  verification_statuses
}

fragment BulkActionsTag_team on Team {
  dbid
  tag_texts(first: 100) {
    edges {
      node {
        text
        id
      }
    }
  }
}

fragment BulkActionsRemoveTag_team on Team {
  tag_texts(first: 100) {
    edges {
      node {
        dbid
        text
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
],
v7 = [
  v2,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "number_of_results",
    "args": null,
    "storageKey": null
  }
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "check_search_trash",
  "storageKey": null,
  "args": null,
  "concreteType": "CheckSearch",
  "plural": false,
  "selections": v7
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "check_search_spam",
  "storageKey": null,
  "args": null,
  "concreteType": "CheckSearch",
  "plural": false,
  "selections": v7
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "public_team",
  "storageKey": null,
  "args": null,
  "concreteType": "PublicTeam",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "trash_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "spam_count",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "search_id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BulkActionsMenuRendererQuery",
  "id": null,
  "text": "query BulkActionsMenuRendererQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    id\n    dbid\n    name\n    permissions\n    projects(first: 10000) {\n      edges {\n        node {\n          dbid\n          id\n        }\n      }\n    }\n    ...BulkActionsAssign_team\n    ...BulkActionsStatus_team\n    ...BulkActionsTag_team\n    ...BulkActionsRemoveTag_team\n    check_search_trash {\n      id\n      number_of_results\n    }\n    check_search_spam {\n      id\n      number_of_results\n    }\n    public_team {\n      id\n      trash_count\n      spam_count\n    }\n    search_id\n  }\n}\n\nfragment BulkActionsAssign_team on Team {\n  team_users(first: 10000) {\n    edges {\n      node {\n        id\n        status\n        user {\n          id\n          dbid\n          name\n          is_bot\n        }\n      }\n    }\n  }\n}\n\nfragment BulkActionsStatus_team on Team {\n  verification_statuses\n}\n\nfragment BulkActionsTag_team on Team {\n  dbid\n  tag_texts(first: 100) {\n    edges {\n      node {\n        text\n        id\n      }\n    }\n  }\n}\n\nfragment BulkActionsRemoveTag_team on Team {\n  tag_texts(first: 100) {\n    edges {\n      node {\n        dbid\n        text\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BulkActionsMenuRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "projects",
            "storageKey": "projects(first:10000)",
            "args": v6,
            "concreteType": "ProjectConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Project",
                    "plural": false,
                    "selections": [
                      v3
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "BulkActionsAssign_team",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "BulkActionsStatus_team",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "BulkActionsTag_team",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "BulkActionsRemoveTag_team",
            "args": null
          },
          v8,
          v9,
          v10,
          v11
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BulkActionsMenuRendererQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "projects",
            "storageKey": "projects(first:10000)",
            "args": v6,
            "concreteType": "ProjectConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Project",
                    "plural": false,
                    "selections": [
                      v3,
                      v2
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team_users",
            "storageKey": "team_users(first:10000)",
            "args": v6,
            "concreteType": "TeamUserConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamUserEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TeamUser",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v2,
                          v3,
                          v4,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "is_bot",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verification_statuses",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tag_texts",
            "storageKey": "tag_texts(first:100)",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100,
                "type": "Int"
              }
            ],
            "concreteType": "TagTextConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagTextEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagText",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "text",
                        "args": null,
                        "storageKey": null
                      },
                      v2,
                      v3
                    ]
                  }
                ]
              }
            ]
          },
          v8,
          v9,
          v10,
          v11
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4613ab9325099c44c546771cbe72a90e';
module.exports = node;
