/**
 * @flow
 * @relayHash 00211cf5e44fbcc502f103c5927668c1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProjectMediasInput = {
  ids: $ReadOnlyArray<?string>,
  action: string,
  params?: ?string,
  clientMutationId?: ?string,
};
export type BulkActionsStatusMutationVariables = {|
  input: UpdateProjectMediasInput
|};
export type BulkActionsStatusMutationResponse = {|
  +updateProjectMedias: ?{|
    +updated_objects: ?$ReadOnlyArray<?{|
      +id: string,
      +status: ?string,
      +last_status: ?string,
    |}>
  |}
|};
export type BulkActionsStatusMutation = {|
  variables: BulkActionsStatusMutationVariables,
  response: BulkActionsStatusMutationResponse,
|};
*/


/*
mutation BulkActionsStatusMutation(
  $input: UpdateProjectMediasInput!
) {
  updateProjectMedias(input: $input) {
    updated_objects {
      id
      status
      last_status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProjectMediasInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateProjectMedias",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateProjectMediasInput!"
      }
    ],
    "concreteType": "UpdateProjectMediasPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updated_objects",
        "storageKey": null,
        "args": null,
        "concreteType": "ProjectMedia",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "last_status",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "BulkActionsStatusMutation",
  "id": null,
  "text": "mutation BulkActionsStatusMutation(\n  $input: UpdateProjectMediasInput!\n) {\n  updateProjectMedias(input: $input) {\n    updated_objects {\n      id\n      status\n      last_status\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BulkActionsStatusMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "BulkActionsStatusMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '77051b9c84509a32f4bfa9975ff62d52';
module.exports = node;
