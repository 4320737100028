/**
 * @flow
 * @relayHash 12c2151b5ba21fc19189d0f594393e85
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TranscribeAudioInput = {
  id: string,
  clientMutationId?: ?string,
};
export type TranscriptionButtonTranscribeAudioMutationVariables = {|
  input: TranscribeAudioInput
|};
export type TranscriptionButtonTranscribeAudioMutationResponse = {|
  +transcribeAudio: ?{|
    +project_media: ?{|
      +id: string,
      +transcription: ?{|
        +data: ?any
      |},
    |}
  |}
|};
export type TranscriptionButtonTranscribeAudioMutation = {|
  variables: TranscriptionButtonTranscribeAudioMutationVariables,
  response: TranscriptionButtonTranscribeAudioMutationResponse,
|};
*/


/*
mutation TranscriptionButtonTranscribeAudioMutation(
  $input: TranscribeAudioInput!
) {
  transcribeAudio(input: $input) {
    project_media {
      id
      transcription: annotation(annotation_type: "transcription") {
        data
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TranscribeAudioInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "TranscribeAudioInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "annotation_type",
    "value": "transcription",
    "type": "String!"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "data",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TranscriptionButtonTranscribeAudioMutation",
  "id": null,
  "text": "mutation TranscriptionButtonTranscribeAudioMutation(\n  $input: TranscribeAudioInput!\n) {\n  transcribeAudio(input: $input) {\n    project_media {\n      id\n      transcription: annotation(annotation_type: \"transcription\") {\n        data\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TranscriptionButtonTranscribeAudioMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "transcribeAudio",
        "storageKey": null,
        "args": v1,
        "concreteType": "TranscribeAudioPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": "transcription",
                "name": "annotation",
                "storageKey": "annotation(annotation_type:\"transcription\")",
                "args": v3,
                "concreteType": "Annotation",
                "plural": false,
                "selections": [
                  v4
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TranscriptionButtonTranscribeAudioMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "transcribeAudio",
        "storageKey": null,
        "args": v1,
        "concreteType": "TranscribeAudioPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": "transcription",
                "name": "annotation",
                "storageKey": "annotation(annotation_type:\"transcription\")",
                "args": v3,
                "concreteType": "Annotation",
                "plural": false,
                "selections": [
                  v4,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0db0d5470733facfe14bfaef48a76026';
module.exports = node;
