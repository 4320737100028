/**
 * @flow
 * @relayHash 792e82664e8ee5e15ef3688341a249af
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchFieldChannelQueryVariables = {||};
export type SearchFieldChannelQueryResponse = {|
  +about: ?{|
    +channels: ?any
  |}
|};
export type SearchFieldChannelQuery = {|
  variables: SearchFieldChannelQueryVariables,
  response: SearchFieldChannelQueryResponse,
|};
*/


/*
query SearchFieldChannelQuery {
  about {
    channels
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "channels",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SearchFieldChannelQuery",
  "id": null,
  "text": "query SearchFieldChannelQuery {\n  about {\n    channels\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SearchFieldChannelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "about",
        "storageKey": null,
        "args": null,
        "concreteType": "About",
        "plural": false,
        "selections": [
          v0
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchFieldChannelQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "about",
        "storageKey": null,
        "args": null,
        "concreteType": "About",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '362be758c55ae0c92fe264ebbb00ee3a';
module.exports = node;
