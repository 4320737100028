/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedCollaboration_feed$ref: FragmentReference;
export type FeedCollaboration_feed = {|
  +dbid: ?number,
  +feed_teams: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +team: ?{|
          +dbid: ?number,
          +avatar: ?string,
          +name: string,
        |},
      |}
    |}>
  |},
  +feed_invitations: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +email: string,
        +state: string,
      |}
    |}>
  |},
  +team: ?{|
    +dbid: ?number
  |},
  +$refType: FeedCollaboration_feed$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100,
    "type": "Int"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "FeedCollaboration_feed",
  "type": "Feed",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "feed_teams",
      "storageKey": "feed_teams(first:100)",
      "args": v1,
      "concreteType": "FeedTeamConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "FeedTeamEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "FeedTeam",
              "plural": false,
              "selections": [
                v2,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "team",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PublicTeam",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "avatar",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "feed_invitations",
      "storageKey": "feed_invitations(first:100)",
      "args": v1,
      "concreteType": "FeedInvitationConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "FeedInvitationEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "FeedInvitation",
              "plural": false,
              "selections": [
                v2,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "email",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "state",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "PublicTeam",
      "plural": false,
      "selections": [
        v0
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b14d0b6c358f712b1c8aad973da13d5';
module.exports = node;
