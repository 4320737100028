/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MediaFeedInformation_projectMedia$ref = any;
type SmallMediaCard_media$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaSimilaritiesComponent_projectMedia$ref: FragmentReference;
export type MediaSimilaritiesComponent_projectMedia = {|
  +id: string,
  +demand: ?number,
  +permissions: ?string,
  +confirmedSimilarCount: ?number,
  +confirmed_similar_relationships: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +source_id: ?number,
        +target_id: ?number,
        +target: ?{|
          +id: string,
          +dbid: ?number,
          +title: ?string,
          +description: ?string,
          +picture: ?string,
          +type: ?string,
          +last_seen: ?string,
          +requests_count: ?number,
          +linked_items_count: ?number,
          +report_status: ?string,
          +added_as_similar_by_name: ?string,
          +show_warning_cover: ?boolean,
          +confirmed_as_similar_by_name: ?string,
          +is_confirmed_similar_to_another_item: ?boolean,
          +url: ?string,
          +quote: ?string,
          +imported_from_feed_id: ?number,
          +imported_from_project_media_id: ?number,
          +media: ?{|
            +$fragmentRefs: SmallMediaCard_media$ref
          |},
          +$fragmentRefs: MediaFeedInformation_projectMedia$ref,
        |},
      |}
    |}>
  |},
  +$refType: MediaSimilaritiesComponent_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MediaSimilaritiesComponent_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "demand",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": "confirmedSimilarCount",
      "name": "confirmed_similar_items_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "confirmed_similar_relationships",
      "storageKey": "confirmed_similar_relationships(first:10000)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000,
          "type": "Int"
        }
      ],
      "concreteType": "RelationshipConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RelationshipEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Relationship",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "source_id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "target_id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "target",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ProjectMedia",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "dbid",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "title",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "description",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "picture",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "type",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "last_seen",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "requests_count",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "linked_items_count",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "report_status",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "added_as_similar_by_name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "show_warning_cover",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "confirmed_as_similar_by_name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "is_confirmed_similar_to_another_item",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "url",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "quote",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "imported_from_feed_id",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "imported_from_project_media_id",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "MediaFeedInformation_projectMedia",
                      "args": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "media",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Media",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "FragmentSpread",
                          "name": "SmallMediaCard_media",
                          "args": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '83c2e29fb82a8f6b282b250a0c967c44';
module.exports = node;
