/**
 * @flow
 * @relayHash 0a5b2851b23f9705a21afba9f086e0db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SaveFeed_feedTeam$ref = any;
export type EditFeedTeamQueryVariables = {|
  feedId?: ?number,
  teamSlug?: ?string,
|};
export type EditFeedTeamQueryResponse = {|
  +feed_team: ?{|
    +$fragmentRefs: SaveFeed_feedTeam$ref
  |},
  +team: ?{|
    +permissions: ?string,
    +name: string,
  |},
|};
export type EditFeedTeamQuery = {|
  variables: EditFeedTeamQueryVariables,
  response: EditFeedTeamQueryResponse,
|};
*/


/*
query EditFeedTeamQuery(
  $feedId: Int
  $teamSlug: String
) {
  feed_team(feedId: $feedId, teamSlug: $teamSlug) {
    ...SaveFeed_feedTeam
    id
  }
  team {
    permissions
    name
    id
  }
}

fragment SaveFeed_feedTeam on FeedTeam {
  id
  saved_search_id
  team_id
  permissions
  team {
    slug
    name
    id
  }
  feed {
    id
    dbid
    name
    description
    licenses
    team {
      dbid
      name
      slug
      id
    }
    saved_search_id
    data_points
    ...FeedCollaboration_feed
    ...FeedMetadata_feed
  }
  ...FeedActions_feedTeam
}

fragment FeedCollaboration_feed on Feed {
  dbid
  feed_teams(first: 100) {
    edges {
      node {
        id
        team {
          dbid
          avatar
          name
          id
        }
      }
    }
  }
  feed_invitations(first: 100) {
    edges {
      node {
        id
        email
        state
      }
    }
  }
  team {
    dbid
    id
  }
}

fragment FeedMetadata_feed on Feed {
  dbid
  created_at
  updated_at
  team {
    name
    id
  }
  user {
    email
    id
  }
}

fragment FeedActions_feedTeam on FeedTeam {
  team_id
  permissions
  feed {
    permissions
    saved_search_id
    saved_search {
      title
      id
    }
    teams_count
    feed_invitations(first: 100) {
      edges {
        node {
          state
          id
        }
      }
    }
    team {
      name
      dbid
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "feedId",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "feedId",
    "variableName": "feedId",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "teamSlug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "saved_search_id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100,
    "type": "Int"
  }
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditFeedTeamQuery",
  "id": null,
  "text": "query EditFeedTeamQuery(\n  $feedId: Int\n  $teamSlug: String\n) {\n  feed_team(feedId: $feedId, teamSlug: $teamSlug) {\n    ...SaveFeed_feedTeam\n    id\n  }\n  team {\n    permissions\n    name\n    id\n  }\n}\n\nfragment SaveFeed_feedTeam on FeedTeam {\n  id\n  saved_search_id\n  team_id\n  permissions\n  team {\n    slug\n    name\n    id\n  }\n  feed {\n    id\n    dbid\n    name\n    description\n    licenses\n    team {\n      dbid\n      name\n      slug\n      id\n    }\n    saved_search_id\n    data_points\n    ...FeedCollaboration_feed\n    ...FeedMetadata_feed\n  }\n  ...FeedActions_feedTeam\n}\n\nfragment FeedCollaboration_feed on Feed {\n  dbid\n  feed_teams(first: 100) {\n    edges {\n      node {\n        id\n        team {\n          dbid\n          avatar\n          name\n          id\n        }\n      }\n    }\n  }\n  feed_invitations(first: 100) {\n    edges {\n      node {\n        id\n        email\n        state\n      }\n    }\n  }\n  team {\n    dbid\n    id\n  }\n}\n\nfragment FeedMetadata_feed on Feed {\n  dbid\n  created_at\n  updated_at\n  team {\n    name\n    id\n  }\n  user {\n    email\n    id\n  }\n}\n\nfragment FeedActions_feedTeam on FeedTeam {\n  team_id\n  permissions\n  feed {\n    permissions\n    saved_search_id\n    saved_search {\n      title\n      id\n    }\n    teams_count\n    feed_invitations(first: 100) {\n      edges {\n        node {\n          state\n          id\n        }\n      }\n    }\n    team {\n      name\n      dbid\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditFeedTeamQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feed_team",
        "storageKey": null,
        "args": v1,
        "concreteType": "FeedTeam",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SaveFeed_feedTeam",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditFeedTeamQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feed_team",
        "storageKey": null,
        "args": v1,
        "concreteType": "FeedTeam",
        "plural": false,
        "selections": [
          v4,
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "team_id",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "PublicTeam",
            "plural": false,
            "selections": [
              v6,
              v3,
              v4
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v4,
              v7,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "licenses",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicTeam",
                "plural": false,
                "selections": [
                  v7,
                  v3,
                  v6,
                  v4
                ]
              },
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "data_points",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:100)",
                "args": v8,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          v4,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "team",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PublicTeam",
                            "plural": false,
                            "selections": [
                              v7,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "avatar",
                                "args": null,
                                "storageKey": null
                              },
                              v3,
                              v4
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_invitations",
                "storageKey": "feed_invitations(first:100)",
                "args": v8,
                "concreteType": "FeedInvitationConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedInvitationEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedInvitation",
                        "plural": false,
                        "selections": [
                          v4,
                          v9,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "state",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "created_at",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updated_at",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v9,
                  v4
                ]
              },
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  v4
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "teams_count",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ad25016bc98a4342798a799db0af020';
module.exports = node;
