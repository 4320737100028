/**
 * @flow
 * @relayHash 126428340ce70d82b7946ae6fd677812
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyFeedTeamInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type SaveFeedLeaveFeedMutationVariables = {|
  input: DestroyFeedTeamInput
|};
export type SaveFeedLeaveFeedMutationResponse = {|
  +destroyFeedTeam: ?{|
    +deletedId: ?string,
    +feed: ?{|
      +name: ?string,
      +dbid: ?number,
      +id: string,
      +team_id: ?number,
      +type: string,
    |},
  |}
|};
export type SaveFeedLeaveFeedMutation = {|
  variables: SaveFeedLeaveFeedMutationVariables,
  response: SaveFeedLeaveFeedMutationResponse,
|};
*/


/*
mutation SaveFeedLeaveFeedMutation(
  $input: DestroyFeedTeamInput!
) {
  destroyFeedTeam(input: $input) {
    deletedId
    feed {
      name
      dbid
      id
      team_id
      type: __typename
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyFeedTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "destroyFeedTeam",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DestroyFeedTeamInput!"
      }
    ],
    "concreteType": "DestroyFeedTeamPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feed",
        "storageKey": null,
        "args": null,
        "concreteType": "Feed",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dbid",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "team_id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": "type",
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveFeedLeaveFeedMutation",
  "id": null,
  "text": "mutation SaveFeedLeaveFeedMutation(\n  $input: DestroyFeedTeamInput!\n) {\n  destroyFeedTeam(input: $input) {\n    deletedId\n    feed {\n      name\n      dbid\n      id\n      team_id\n      type: __typename\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveFeedLeaveFeedMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveFeedLeaveFeedMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c977b827828b63d01ae0cfbbb46b3252';
module.exports = node;
