/**
 * @flow
 * @relayHash 87921124a5b4a51e5aa4cb5b9978483b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserInvitationInput = {
  invitation?: ?string,
  members: any,
  clientMutationId?: ?string,
};
export type InviteDialogMutationVariables = {|
  input: UserInvitationInput
|};
export type InviteDialogMutationResponse = {|
  +userInvitation: ?{|
    +errors: ?any,
    +team: ?{|
      +id: string,
      +team_users: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +status: ?string,
            +role: ?string,
            +permissions: ?string,
            +user: ?{|
              +id: string,
              +dbid: ?number,
              +email: ?string,
              +is_bot: ?boolean,
              +name: ?string,
              +profile_image: ?string,
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type InviteDialogMutation = {|
  variables: InviteDialogMutationVariables,
  response: InviteDialogMutationResponse,
|};
*/


/*
mutation InviteDialogMutation(
  $input: UserInvitationInput!
) {
  userInvitation(input: $input) {
    errors
    team {
      id
      team_users(first: 10000, status: ["invited", "member", "banned"]) {
        edges {
          node {
            id
            status
            role
            permissions
            user {
              id
              dbid
              email
              is_bot
              name
              profile_image
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserInvitationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "userInvitation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UserInvitationInput!"
      }
    ],
    "concreteType": "UserInvitationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "errors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team_users",
            "storageKey": "team_users(first:10000,status:[\"invited\",\"member\",\"banned\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000,
                "type": "Int"
              },
              {
                "kind": "Literal",
                "name": "status",
                "value": [
                  "invited",
                  "member",
                  "banned"
                ],
                "type": "[String]"
              }
            ],
            "concreteType": "TeamUserConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamUserEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TeamUser",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "role",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "permissions",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v1,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "dbid",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "is_bot",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "profile_image",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "InviteDialogMutation",
  "id": null,
  "text": "mutation InviteDialogMutation(\n  $input: UserInvitationInput!\n) {\n  userInvitation(input: $input) {\n    errors\n    team {\n      id\n      team_users(first: 10000, status: [\"invited\", \"member\", \"banned\"]) {\n        edges {\n          node {\n            id\n            status\n            role\n            permissions\n            user {\n              id\n              dbid\n              email\n              is_bot\n              name\n              profile_image\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "InviteDialogMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "InviteDialogMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab267cd7c46fcabd3021b5bb4ae26f99';
module.exports = node;
