/**
 * @flow
 * @relayHash be6f0c71c2c63cbdc11a813a2449211b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyRelationshipInput = {
  id?: ?string,
  archive_target?: ?number,
  clientMutationId?: ?string,
};
export type MediaRelationshipDestroyRelationshipMutationVariables = {|
  input: DestroyRelationshipInput
|};
export type MediaRelationshipDestroyRelationshipMutationResponse = {|
  +destroyRelationship: ?{|
    +deletedId: ?string,
    +source_project_media: ?{|
      +id: string,
      +demand: ?number,
      +hasMain: ?boolean,
      +confirmedSimilarCount: ?number,
      +default_relationships_count: ?number,
    |},
    +target_project_media: ?{|
      +id: string,
      +demand: ?number,
      +is_suggested: ?boolean,
      +hasMain: ?boolean,
      +confirmedSimilarCount: ?number,
      +default_relationships_count: ?number,
    |},
  |}
|};
export type MediaRelationshipDestroyRelationshipMutation = {|
  variables: MediaRelationshipDestroyRelationshipMutationVariables,
  response: MediaRelationshipDestroyRelationshipMutationResponse,
|};
*/


/*
mutation MediaRelationshipDestroyRelationshipMutation(
  $input: DestroyRelationshipInput!
) {
  destroyRelationship(input: $input) {
    deletedId
    source_project_media {
      id
      demand
      hasMain: is_confirmed_similar_to_another_item
      confirmedSimilarCount: confirmed_similar_items_count
      default_relationships_count
    }
    target_project_media {
      id
      demand
      is_suggested
      hasMain: is_confirmed_similar_to_another_item
      confirmedSimilarCount: confirmed_similar_items_count
      default_relationships_count
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyRelationshipInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "demand",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "hasMain",
  "name": "is_confirmed_similar_to_another_item",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": "confirmedSimilarCount",
  "name": "confirmed_similar_items_count",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "default_relationships_count",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "destroyRelationship",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DestroyRelationshipInput!"
      }
    ],
    "concreteType": "DestroyRelationshipPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "source_project_media",
        "storageKey": null,
        "args": null,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v4,
          v5
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "target_project_media",
        "storageKey": null,
        "args": null,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "is_suggested",
            "args": null,
            "storageKey": null
          },
          v3,
          v4,
          v5
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaRelationshipDestroyRelationshipMutation",
  "id": null,
  "text": "mutation MediaRelationshipDestroyRelationshipMutation(\n  $input: DestroyRelationshipInput!\n) {\n  destroyRelationship(input: $input) {\n    deletedId\n    source_project_media {\n      id\n      demand\n      hasMain: is_confirmed_similar_to_another_item\n      confirmedSimilarCount: confirmed_similar_items_count\n      default_relationships_count\n    }\n    target_project_media {\n      id\n      demand\n      is_suggested\n      hasMain: is_confirmed_similar_to_another_item\n      confirmedSimilarCount: confirmed_similar_items_count\n      default_relationships_count\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaRelationshipDestroyRelationshipMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v6
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaRelationshipDestroyRelationshipMutation",
    "argumentDefinitions": v0,
    "selections": v6
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8da9232430c330b64b032347f4fdf05d';
module.exports = node;
