/**
 * @flow
 * @relayHash 4045988dd8d724783c8b48a364b0598d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenerateTwoFactorBackupCodesInput = {
  id: number,
  clientMutationId?: ?string,
};
export type UserSecurityGenerateTwoFactorBackupCodesMutationVariables = {|
  input: GenerateTwoFactorBackupCodesInput
|};
export type UserSecurityGenerateTwoFactorBackupCodesMutationResponse = {|
  +generateTwoFactorBackupCodes: ?{|
    +success: ?boolean,
    +codes: ?any,
  |}
|};
export type UserSecurityGenerateTwoFactorBackupCodesMutation = {|
  variables: UserSecurityGenerateTwoFactorBackupCodesMutationVariables,
  response: UserSecurityGenerateTwoFactorBackupCodesMutationResponse,
|};
*/


/*
mutation UserSecurityGenerateTwoFactorBackupCodesMutation(
  $input: GenerateTwoFactorBackupCodesInput!
) {
  generateTwoFactorBackupCodes(input: $input) {
    success
    codes
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "GenerateTwoFactorBackupCodesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "generateTwoFactorBackupCodes",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "GenerateTwoFactorBackupCodesInput!"
      }
    ],
    "concreteType": "GenerateTwoFactorBackupCodesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "codes",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UserSecurityGenerateTwoFactorBackupCodesMutation",
  "id": null,
  "text": "mutation UserSecurityGenerateTwoFactorBackupCodesMutation(\n  $input: GenerateTwoFactorBackupCodesInput!\n) {\n  generateTwoFactorBackupCodes(input: $input) {\n    success\n    codes\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserSecurityGenerateTwoFactorBackupCodesMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSecurityGenerateTwoFactorBackupCodesMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e92a9d51cd5770590800a7f8e038e73';
module.exports = node;
