/**
 * @flow
 * @relayHash 443f2d4bcdd6e611ffba5139691d8b55
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyTagTextInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type TeamTagsActionsDestroyTagTextMutationVariables = {|
  input: DestroyTagTextInput
|};
export type TeamTagsActionsDestroyTagTextMutationResponse = {|
  +destroyTagText: ?{|
    +team: ?{|
      +id: string,
      +get_rules: ?any,
      +tag_texts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +text: ?string,
            +tags_count: ?number,
            +updated_at: ?string,
          |}
        |}>
      |},
    |}
  |}
|};
export type TeamTagsActionsDestroyTagTextMutation = {|
  variables: TeamTagsActionsDestroyTagTextMutationVariables,
  response: TeamTagsActionsDestroyTagTextMutationResponse,
|};
*/


/*
mutation TeamTagsActionsDestroyTagTextMutation(
  $input: DestroyTagTextInput!
) {
  destroyTagText(input: $input) {
    team {
      id
      get_rules
      tag_texts(first: 100) {
        edges {
          node {
            id
            text
            tags_count
            updated_at
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyTagTextInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "destroyTagText",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DestroyTagTextInput!"
      }
    ],
    "concreteType": "DestroyTagTextPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_rules",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tag_texts",
            "storageKey": "tag_texts(first:100)",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100,
                "type": "Int"
              }
            ],
            "concreteType": "TagTextConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagTextEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagText",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "text",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "tags_count",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updated_at",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TeamTagsActionsDestroyTagTextMutation",
  "id": null,
  "text": "mutation TeamTagsActionsDestroyTagTextMutation(\n  $input: DestroyTagTextInput!\n) {\n  destroyTagText(input: $input) {\n    team {\n      id\n      get_rules\n      tag_texts(first: 100) {\n        edges {\n          node {\n            id\n            text\n            tags_count\n            updated_at\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamTagsActionsDestroyTagTextMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamTagsActionsDestroyTagTextMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54a94f22a0c396bcde06bebae93856ed';
module.exports = node;
