/**
 * @flow
 * @relayHash 4170391d54f198588faf9a91ef2d8b84
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SmoochBotResourceEditor_tiplineResource$ref = any;
export type UpdateTiplineResourceInput = {
  id?: ?string,
  uuid?: ?string,
  title?: ?string,
  content?: ?string,
  language?: ?string,
  header_type?: ?string,
  header_overlay_text?: ?string,
  content_type?: ?string,
  rss_feed_url?: ?string,
  number_of_articles?: ?number,
  clientMutationId?: ?string,
};
export type SmoochBotResourceEditorUpdateMutationVariables = {|
  input: UpdateTiplineResourceInput
|};
export type SmoochBotResourceEditorUpdateMutationResponse = {|
  +updateTiplineResource: ?{|
    +tipline_resource: ?{|
      +team: ?{|
        +tipline_resources: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +$fragmentRefs: SmoochBotResourceEditor_tiplineResource$ref
            |}
          |}>
        |}
      |},
      +$fragmentRefs: SmoochBotResourceEditor_tiplineResource$ref,
    |}
  |}
|};
export type SmoochBotResourceEditorUpdateMutation = {|
  variables: SmoochBotResourceEditorUpdateMutationVariables,
  response: SmoochBotResourceEditorUpdateMutationResponse,
|};
*/


/*
mutation SmoochBotResourceEditorUpdateMutation(
  $input: UpdateTiplineResourceInput!
) {
  updateTiplineResource(input: $input) {
    tipline_resource {
      ...SmoochBotResourceEditor_tiplineResource
      team {
        tipline_resources(first: 10000) {
          edges {
            node {
              ...SmoochBotResourceEditor_tiplineResource
              id
            }
          }
        }
        id
      }
      id
    }
  }
}

fragment SmoochBotResourceEditor_tiplineResource on TiplineResource {
  id
  dbid
  uuid
  language
  title
  header_type
  header_file_url
  header_overlay_text
  content_type
  content
  number_of_articles
  rss_feed_url
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTiplineResourceInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateTiplineResourceInput!"
  }
],
v2 = {
  "kind": "FragmentSpread",
  "name": "SmoochBotResourceEditor_tiplineResource",
  "args": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uuid",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "header_type",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "header_file_url",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "header_overlay_text",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content_type",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "number_of_articles",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rss_feed_url",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SmoochBotResourceEditorUpdateMutation",
  "id": null,
  "text": "mutation SmoochBotResourceEditorUpdateMutation(\n  $input: UpdateTiplineResourceInput!\n) {\n  updateTiplineResource(input: $input) {\n    tipline_resource {\n      ...SmoochBotResourceEditor_tiplineResource\n      team {\n        tipline_resources(first: 10000) {\n          edges {\n            node {\n              ...SmoochBotResourceEditor_tiplineResource\n              id\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment SmoochBotResourceEditor_tiplineResource on TiplineResource {\n  id\n  dbid\n  uuid\n  language\n  title\n  header_type\n  header_file_url\n  header_overlay_text\n  content_type\n  content\n  number_of_articles\n  rss_feed_url\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SmoochBotResourceEditorUpdateMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTiplineResource",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTiplineResourcePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tipline_resource",
            "storageKey": null,
            "args": null,
            "concreteType": "TiplineResource",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "tipline_resources",
                    "storageKey": "tipline_resources(first:10000)",
                    "args": v3,
                    "concreteType": "TiplineResourceConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TiplineResourceEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TiplineResource",
                            "plural": false,
                            "selections": [
                              v2
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SmoochBotResourceEditorUpdateMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTiplineResource",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTiplineResourcePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tipline_resource",
            "storageKey": null,
            "args": null,
            "concreteType": "TiplineResource",
            "plural": false,
            "selections": [
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              v13,
              v14,
              v15,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "tipline_resources",
                    "storageKey": "tipline_resources(first:10000)",
                    "args": v3,
                    "concreteType": "TiplineResourceConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TiplineResourceEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TiplineResource",
                            "plural": false,
                            "selections": [
                              v4,
                              v5,
                              v6,
                              v7,
                              v8,
                              v9,
                              v10,
                              v11,
                              v12,
                              v13,
                              v14,
                              v15
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  v4
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '94b663d5597d79612c6176f1350a66a5';
module.exports = node;
