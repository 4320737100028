/**
 * @flow
 * @relayHash c807ed47bc8352e7b272022e2ba33247
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StatusesQueryVariables = {|
  teamSlug: string
|};
export type StatusesQueryResponse = {|
  +team: ?{|
    +id: string,
    +verification_statuses: ?any,
    +get_language: ?string,
    +get_languages: ?string,
    +smooch_bot: ?{|
      +id: string
    |},
  |}
|};
export type StatusesQuery = {|
  variables: StatusesQueryVariables,
  response: StatusesQueryResponse,
|};
*/


/*
query StatusesQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    id
    verification_statuses
    get_language
    get_languages
    smooch_bot: team_bot_installation(bot_identifier: "smooch") {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "team",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "teamSlug",
        "type": "String"
      }
    ],
    "concreteType": "Team",
    "plural": false,
    "selections": [
      v1,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "verification_statuses",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "get_language",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "get_languages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": "smooch_bot",
        "name": "team_bot_installation",
        "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
        "args": [
          {
            "kind": "Literal",
            "name": "bot_identifier",
            "value": "smooch",
            "type": "String!"
          }
        ],
        "concreteType": "TeamBotInstallation",
        "plural": false,
        "selections": [
          v1
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "StatusesQuery",
  "id": null,
  "text": "query StatusesQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    id\n    verification_statuses\n    get_language\n    get_languages\n    smooch_bot: team_bot_installation(bot_identifier: \"smooch\") {\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "StatusesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "StatusesQuery",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50e64e75407946887c5e04bda33b0330';
module.exports = node;
