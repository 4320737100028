/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SourceInfo_about$ref: FragmentReference;
export type SourceInfo_about = {|
  +upload_max_size: ?string,
  +upload_extensions: ?$ReadOnlyArray<?string>,
  +video_max_size: ?string,
  +video_extensions: ?$ReadOnlyArray<?string>,
  +audio_max_size: ?string,
  +audio_extensions: ?$ReadOnlyArray<?string>,
  +file_max_size: ?string,
  +file_max_size_in_bytes: ?number,
  +file_extensions: ?$ReadOnlyArray<?string>,
  +upload_max_dimensions: ?string,
  +upload_min_dimensions: ?string,
  +$refType: SourceInfo_about$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SourceInfo_about",
  "type": "About",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "upload_max_size",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "upload_extensions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "video_max_size",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "video_extensions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "audio_max_size",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "audio_extensions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "file_max_size",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "file_max_size_in_bytes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "file_extensions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "upload_max_dimensions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "upload_min_dimensions",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b7227c1dbc77628469f59709dad98a1d';
module.exports = node;
