/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedTeamFactCheckDialog_claimDescription$ref: FragmentReference;
export type FeedTeamFactCheckDialog_claimDescription = {|
  +description: ?string,
  +context: ?string,
  +fact_check: ?{|
    +title: ?string,
    +summary: ?string,
    +language: ?string,
    +updated_at: ?string,
    +url: ?string,
  |},
  +$refType: FeedTeamFactCheckDialog_claimDescription$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "FeedTeamFactCheckDialog_claimDescription",
  "type": "ClaimDescription",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "context",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fact_check",
      "storageKey": "fact_check(report_status:\"published\")",
      "args": [
        {
          "kind": "Literal",
          "name": "report_status",
          "value": "published",
          "type": "String"
        }
      ],
      "concreteType": "FactCheck",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "summary",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "language",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "updated_at",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0d59b8da8da921a2ce4a586889ee9e0b';
module.exports = node;
