/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MediaMainItemPreview_projectMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaConfirmationBanner_projectMedia$ref: FragmentReference;
export type MediaConfirmationBanner_projectMedia = {|
  +is_confirmed: ?boolean,
  +confirmed_main_item: ?{|
    +$fragmentRefs: MediaMainItemPreview_projectMedia$ref
  |},
  +$refType: MediaConfirmationBanner_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MediaConfirmationBanner_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "is_confirmed",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "confirmed_main_item",
      "storageKey": null,
      "args": null,
      "concreteType": "ProjectMedia",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "MediaMainItemPreview_projectMedia",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c2e78dee090357b8bad2885bd420de62';
module.exports = node;
