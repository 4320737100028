/**
 * @flow
 * @relayHash f07ab83cb8d1e50f56138a2b77940ba9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyApiKeyInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type ApiKeyDeleteMutationVariables = {|
  input: DestroyApiKeyInput
|};
export type ApiKeyDeleteMutationResponse = {|
  +destroyApiKey: ?{|
    +team: ?{|
      +api_keys: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +dbid: ?number,
            +title: ?string,
            +description: ?string,
            +access_token: ?string,
            +created_at: ?string,
            +expire_at: ?string,
            +user: ?{|
              +name: ?string
            |},
          |}
        |}>
      |}
    |}
  |}
|};
export type ApiKeyDeleteMutation = {|
  variables: ApiKeyDeleteMutationVariables,
  response: ApiKeyDeleteMutationResponse,
|};
*/


/*
mutation ApiKeyDeleteMutation(
  $input: DestroyApiKeyInput!
) {
  destroyApiKey(input: $input) {
    team {
      api_keys(first: 10000) {
        edges {
          node {
            dbid
            title
            description
            access_token
            created_at
            expire_at
            user {
              name
              id
            }
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyApiKeyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DestroyApiKeyInput!"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "access_token",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created_at",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expire_at",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ApiKeyDeleteMutation",
  "id": null,
  "text": "mutation ApiKeyDeleteMutation(\n  $input: DestroyApiKeyInput!\n) {\n  destroyApiKey(input: $input) {\n    team {\n      api_keys(first: 10000) {\n        edges {\n          node {\n            dbid\n            title\n            description\n            access_token\n            created_at\n            expire_at\n            user {\n              name\n              id\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ApiKeyDeleteMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyApiKey",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyApiKeyPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "api_keys",
                "storageKey": "api_keys(first:10000)",
                "args": v2,
                "concreteType": "ApiKeyConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApiKeyEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApiKey",
                        "plural": false,
                        "selections": [
                          v3,
                          v4,
                          v5,
                          v6,
                          v7,
                          v8,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              v9
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ApiKeyDeleteMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyApiKey",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyApiKeyPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "api_keys",
                "storageKey": "api_keys(first:10000)",
                "args": v2,
                "concreteType": "ApiKeyConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApiKeyEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApiKey",
                        "plural": false,
                        "selections": [
                          v3,
                          v4,
                          v5,
                          v6,
                          v7,
                          v8,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              v9,
                              v10
                            ]
                          },
                          v10
                        ]
                      }
                    ]
                  }
                ]
              },
              v10
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '808eb33df7617b87e8da630e49f64643';
module.exports = node;
