/**
 * @flow
 * @relayHash 3093481a956c758e68cb1e9c7ded1255
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportMediaInput = {
  feedId: number,
  projectMediaId: number,
  parentId?: ?number,
  claimTitle?: ?string,
  claimContext?: ?string,
  clientMutationId?: ?string,
};
export type FeedImportDialogFeedImportMediaMutationVariables = {|
  input: ImportMediaInput
|};
export type FeedImportDialogFeedImportMediaMutationResponse = {|
  +feedImportMedia: ?{|
    +projectMedia: {|
      +full_url: ?string
    |}
  |}
|};
export type FeedImportDialogFeedImportMediaMutation = {|
  variables: FeedImportDialogFeedImportMediaMutationVariables,
  response: FeedImportDialogFeedImportMediaMutationResponse,
|};
*/


/*
mutation FeedImportDialogFeedImportMediaMutation(
  $input: ImportMediaInput!
) {
  feedImportMedia(input: $input) {
    projectMedia {
      full_url
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportMediaInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ImportMediaInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "full_url",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "FeedImportDialogFeedImportMediaMutation",
  "id": null,
  "text": "mutation FeedImportDialogFeedImportMediaMutation(\n  $input: ImportMediaInput!\n) {\n  feedImportMedia(input: $input) {\n    projectMedia {\n      full_url\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FeedImportDialogFeedImportMediaMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feedImportMedia",
        "storageKey": null,
        "args": v1,
        "concreteType": "ImportMediaPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "projectMedia",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FeedImportDialogFeedImportMediaMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feedImportMedia",
        "storageKey": null,
        "args": v1,
        "concreteType": "ImportMediaPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "projectMedia",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ce111eba660e8f15a0d66ac2d08d23b0';
module.exports = node;
