/**
 * @flow
 * @relayHash 26baf0b9d209c559ccb1cb8ca43fcc44
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSourceInput = {
  id?: ?string,
  avatar?: ?string,
  user_id?: ?number,
  add_to_project_media_id?: ?number,
  slogan?: ?string,
  name?: ?string,
  refresh_accounts?: ?number,
  lock_version?: ?number,
  clientMutationId?: ?string,
};
export type SourceInfoUpdateNameMutationVariables = {|
  input: UpdateSourceInput
|};
export type SourceInfoUpdateNameMutationResponse = {|
  +updateSource: ?{|
    +source: ?{|
      +name: string,
      +updated_at: ?string,
    |}
  |}
|};
export type SourceInfoUpdateNameMutation = {|
  variables: SourceInfoUpdateNameMutationVariables,
  response: SourceInfoUpdateNameMutationResponse,
|};
*/


/*
mutation SourceInfoUpdateNameMutation(
  $input: UpdateSourceInput!
) {
  updateSource(input: $input) {
    source {
      name
      updated_at
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSourceInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateSourceInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updated_at",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SourceInfoUpdateNameMutation",
  "id": null,
  "text": "mutation SourceInfoUpdateNameMutation(\n  $input: UpdateSourceInput!\n) {\n  updateSource(input: $input) {\n    source {\n      name\n      updated_at\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SourceInfoUpdateNameMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSource",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSourcePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source",
            "storageKey": null,
            "args": null,
            "concreteType": "Source",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SourceInfoUpdateNameMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSource",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSourcePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source",
            "storageKey": null,
            "args": null,
            "concreteType": "Source",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '676821b8826ce3a04a8ba11c07d3b830';
module.exports = node;
