/**
 * @flow
 * @relayHash c3b80b8fbee333f5068f6ef04b75516c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamInput = {
  id?: ?string,
  archived?: ?number,
  private?: ?boolean,
  description?: ?string,
  name?: ?string,
  add_auto_task?: ?any,
  media_verification_statuses?: ?any,
  set_team_tasks?: ?any,
  rules?: ?string,
  remove_auto_task?: ?string,
  empty_trash?: ?number,
  report?: ?any,
  slack_notifications_enabled?: ?string,
  slack_webhook?: ?string,
  slack_notifications?: ?string,
  language?: ?string,
  languages?: ?any,
  language_detection?: ?boolean,
  tipline_inbox_filters?: ?string,
  suggested_matches_filters?: ?string,
  outgoing_urls_utm_code?: ?string,
  shorten_outgoing_urls?: ?boolean,
  clientMutationId?: ?string,
};
export type TeamReportComponentUpdateTeamMutationVariables = {|
  input: UpdateTeamInput
|};
export type TeamReportComponentUpdateTeamMutationResponse = {|
  +updateTeam: ?{|
    +team: ?{|
      +get_report: ?any
    |}
  |}
|};
export type TeamReportComponentUpdateTeamMutation = {|
  variables: TeamReportComponentUpdateTeamMutationVariables,
  response: TeamReportComponentUpdateTeamMutationResponse,
|};
*/


/*
mutation TeamReportComponentUpdateTeamMutation(
  $input: UpdateTeamInput!
) {
  updateTeam(input: $input) {
    team {
      get_report
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateTeamInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_report",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TeamReportComponentUpdateTeamMutation",
  "id": null,
  "text": "mutation TeamReportComponentUpdateTeamMutation(\n  $input: UpdateTeamInput!\n) {\n  updateTeam(input: $input) {\n    team {\n      get_report\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamReportComponentUpdateTeamMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamReportComponentUpdateTeamMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9743456f66e3709ceabbd0593b7fd387';
module.exports = node;
