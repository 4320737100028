/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ArticleForm_article$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClaimFactCheckForm_article$ref: FragmentReference;
export type ClaimFactCheckForm_article = {|
  +id: string,
  +claim_description: ?{|
    +id: string
  |},
  +$fragmentRefs: ArticleForm_article$ref,
  +$refType: ClaimFactCheckForm_article$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ClaimFactCheckForm_article",
  "type": "FactCheck",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "claim_description",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimDescription",
      "plural": false,
      "selections": [
        v0
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ArticleForm_article",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '94e1c2f7cd85ee581b37003d1e6cd98a';
module.exports = node;
