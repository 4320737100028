/**
 * @flow
 * @relayHash 5c5c01e430f381abfff1437393a5c979
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MediaSearchRedirectQueryVariables = {|
  queryJson: string,
  pageSize: number,
|};
export type MediaSearchRedirectQueryResponse = {|
  +search: ?{|
    +id: string,
    +number_of_results: ?number,
    +medias: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dbid: ?number,
        |}
      |}>
    |},
  |}
|};
export type MediaSearchRedirectQuery = {|
  variables: MediaSearchRedirectQueryVariables,
  response: MediaSearchRedirectQueryResponse,
|};
*/


/*
query MediaSearchRedirectQuery(
  $queryJson: String!
  $pageSize: Int!
) {
  search(query: $queryJson) {
    id
    number_of_results
    medias(first: $pageSize) {
      edges {
        node {
          id
          dbid
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "queryJson",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "search",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "queryJson",
        "type": "String!"
      }
    ],
    "concreteType": "CheckSearch",
    "plural": false,
    "selections": [
      v1,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "number_of_results",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "medias",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "pageSize",
            "type": "Int"
          }
        ],
        "concreteType": "ProjectMediaConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMediaEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dbid",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MediaSearchRedirectQuery",
  "id": null,
  "text": "query MediaSearchRedirectQuery(\n  $queryJson: String!\n  $pageSize: Int!\n) {\n  search(query: $queryJson) {\n    id\n    number_of_results\n    medias(first: $pageSize) {\n      edges {\n        node {\n          id\n          dbid\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSearchRedirectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSearchRedirectQuery",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49b1b2972d8a7571d7539aae058a5332';
module.exports = node;
