/**
 * @flow
 * @relayHash 8124abd96e621526a6d4757143a6f9be
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TiplineInboxQueryVariables = {|
  slug: string
|};
export type TiplineInboxQueryResponse = {|
  +team: ?{|
    +verification_statuses: ?any
  |}
|};
export type TiplineInboxQuery = {|
  variables: TiplineInboxQueryVariables,
  response: TiplineInboxQueryResponse,
|};
*/


/*
query TiplineInboxQuery(
  $slug: String!
) {
  team(slug: $slug) {
    verification_statuses
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "verification_statuses",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TiplineInboxQuery",
  "id": null,
  "text": "query TiplineInboxQuery(\n  $slug: String!\n) {\n  team(slug: $slug) {\n    verification_statuses\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TiplineInboxQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TiplineInboxQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8aa1f6a99a5f43fce442111819ba3dce';
module.exports = node;
