/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type FeedImportDialog_feed$ref = any;
type FeedLastClusterizedAt_feed$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedItemHeader_feed$ref: FragmentReference;
export type FeedItemHeader_feed = {|
  +dbid: ?number,
  +name: ?string,
  +$fragmentRefs: FeedLastClusterizedAt_feed$ref & FeedImportDialog_feed$ref,
  +$refType: FeedItemHeader_feed$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "FeedItemHeader_feed",
  "type": "Feed",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dbid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "FeedLastClusterizedAt_feed",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "FeedImportDialog_feed",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2d9ff2967a8b25ba1d08589e25b6e797';
module.exports = node;
