/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaMainItemPreview_projectMedia$ref: FragmentReference;
export type MediaMainItemPreview_projectMedia = {|
  +title: ?string,
  +full_url: ?string,
  +show_warning_cover: ?boolean,
  +media: ?{|
    +picture: ?string,
    +type: ?string,
    +url: ?string,
  |},
  +$refType: MediaMainItemPreview_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MediaMainItemPreview_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "full_url",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "show_warning_cover",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media",
      "storageKey": null,
      "args": null,
      "concreteType": "Media",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "picture",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6f384170d0094f789f0a4c6aae3cacae';
module.exports = node;
