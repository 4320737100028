/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MediaConfirmationBanner_projectMedia$ref = any;
type MediaSuggestionBanner_projectMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaSecondaryBanner_projectMedia$ref: FragmentReference;
export type MediaSecondaryBanner_projectMedia = {|
  +is_suggested: ?boolean,
  +is_confirmed: ?boolean,
  +$fragmentRefs: MediaSuggestionBanner_projectMedia$ref & MediaConfirmationBanner_projectMedia$ref,
  +$refType: MediaSecondaryBanner_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MediaSecondaryBanner_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "is_suggested",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "is_confirmed",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaSuggestionBanner_projectMedia",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaConfirmationBanner_projectMedia",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ea8049af1712d56127c8e81db9da20d5';
module.exports = node;
