/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MediaLanguageSwitcher_projectMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaCardLargeActions_projectMedia$ref: FragmentReference;
export type MediaCardLargeActions_projectMedia = {|
  +id: string,
  +media: ?{|
    +url: ?string,
    +type: ?string,
    +metadata: ?any,
  |},
  +picture: ?string,
  +url: ?string,
  +transcription: ?{|
    +data: ?any
  |},
  +extracted_text: ?{|
    +data: ?any
  |},
  +$fragmentRefs: MediaLanguageSwitcher_projectMedia$ref,
  +$refType: MediaCardLargeActions_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "data",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "MediaCardLargeActions_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media",
      "storageKey": null,
      "args": null,
      "concreteType": "Media",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "metadata",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "picture",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "LinkedField",
      "alias": "transcription",
      "name": "annotation",
      "storageKey": "annotation(annotation_type:\"transcription\")",
      "args": [
        {
          "kind": "Literal",
          "name": "annotation_type",
          "value": "transcription",
          "type": "String!"
        }
      ],
      "concreteType": "Annotation",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": "extracted_text",
      "name": "annotation",
      "storageKey": "annotation(annotation_type:\"extracted_text\")",
      "args": [
        {
          "kind": "Literal",
          "name": "annotation_type",
          "value": "extracted_text",
          "type": "String!"
        }
      ],
      "concreteType": "Annotation",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaLanguageSwitcher_projectMedia",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '1b0c43c0bb89a60f006813fd11cad918';
module.exports = node;
