/**
 * @flow
 * @relayHash f55642d20169719e2acc10e8a058132b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamInput = {
  id?: ?string,
  archived?: ?number,
  private?: ?boolean,
  description?: ?string,
  name?: ?string,
  add_auto_task?: ?any,
  media_verification_statuses?: ?any,
  set_team_tasks?: ?any,
  rules?: ?string,
  remove_auto_task?: ?string,
  empty_trash?: ?number,
  report?: ?any,
  slack_notifications_enabled?: ?string,
  slack_webhook?: ?string,
  slack_notifications?: ?string,
  language?: ?string,
  languages?: ?any,
  language_detection?: ?boolean,
  tipline_inbox_filters?: ?string,
  suggested_matches_filters?: ?string,
  outgoing_urls_utm_code?: ?string,
  shorten_outgoing_urls?: ?boolean,
  clientMutationId?: ?string,
};
export type LanguageListItemSetDefaultLanguageMutationVariables = {|
  input: UpdateTeamInput
|};
export type LanguageListItemSetDefaultLanguageMutationResponse = {|
  +updateTeam: ?{|
    +team: ?{|
      +id: string,
      +get_language: ?string,
    |}
  |}
|};
export type LanguageListItemSetDefaultLanguageMutation = {|
  variables: LanguageListItemSetDefaultLanguageMutationVariables,
  response: LanguageListItemSetDefaultLanguageMutationResponse,
|};
*/


/*
mutation LanguageListItemSetDefaultLanguageMutation(
  $input: UpdateTeamInput!
) {
  updateTeam(input: $input) {
    team {
      id
      get_language
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateTeam",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateTeamInput!"
      }
    ],
    "concreteType": "UpdateTeamPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_language",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "LanguageListItemSetDefaultLanguageMutation",
  "id": null,
  "text": "mutation LanguageListItemSetDefaultLanguageMutation(\n  $input: UpdateTeamInput!\n) {\n  updateTeam(input: $input) {\n    team {\n      id\n      get_language\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LanguageListItemSetDefaultLanguageMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "LanguageListItemSetDefaultLanguageMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9be35358b31f936f16fdf95158d3a6af';
module.exports = node;
