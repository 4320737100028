/**
 * @flow
 * @relayHash 0f023867975e9515447fc4979c2a7b2b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TeamDataQueryVariables = {|
  teamSlug: string
|};
export type TeamDataQueryResponse = {|
  +team: ?{|
    +id: string,
    +slug: string,
    +get_language: ?string,
    +data_report: ?any,
  |}
|};
export type TeamDataQuery = {|
  variables: TeamDataQueryVariables,
  response: TeamDataQueryResponse,
|};
*/


/*
query TeamDataQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    id
    slug
    get_language
    data_report
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "team",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "teamSlug",
        "type": "String"
      }
    ],
    "concreteType": "Team",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "slug",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "get_language",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "data_report",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamDataQuery",
  "id": null,
  "text": "query TeamDataQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    id\n    slug\n    get_language\n    data_report\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamDataQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamDataQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '56dc178ff9056c9d9ee0b199b582824b';
module.exports = node;
