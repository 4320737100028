/**
 * @flow
 * @relayHash 4a0d0d2a8c4467b2560d9fed4fadc5e3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MediaTags_projectMedia$ref = any;
export type CreateTagInput = {
  fragment?: ?string,
  annotated_id?: ?string,
  annotated_type?: ?string,
  tag: string,
  clientMutationId?: ?string,
};
export type MediaTagsCreateTagMutationVariables = {|
  input: CreateTagInput
|};
export type MediaTagsCreateTagMutationResponse = {|
  +createTag: ?{|
    +project_media: ?{|
      +$fragmentRefs: MediaTags_projectMedia$ref
    |}
  |}
|};
export type MediaTagsCreateTagMutation = {|
  variables: MediaTagsCreateTagMutationVariables,
  response: MediaTagsCreateTagMutationResponse,
|};
*/


/*
mutation MediaTagsCreateTagMutation(
  $input: CreateTagInput!
) {
  createTag(input: $input) {
    project_media {
      ...MediaTags_projectMedia
      id
    }
  }
}

fragment MediaTags_projectMedia on ProjectMedia {
  dbid
  permissions
  team {
    slug
    id
  }
  tags(last: 100) {
    edges {
      node {
        id
        tag_text
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTagInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateTagInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaTagsCreateTagMutation",
  "id": null,
  "text": "mutation MediaTagsCreateTagMutation(\n  $input: CreateTagInput!\n) {\n  createTag(input: $input) {\n    project_media {\n      ...MediaTags_projectMedia\n      id\n    }\n  }\n}\n\nfragment MediaTags_projectMedia on ProjectMedia {\n  dbid\n  permissions\n  team {\n    slug\n    id\n  }\n  tags(last: 100) {\n    edges {\n      node {\n        id\n        tag_text\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaTagsCreateTagMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createTag",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateTagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "MediaTags_projectMedia",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaTagsCreateTagMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createTag",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateTagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dbid",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "permissions",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "slug",
                    "args": null,
                    "storageKey": null
                  },
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tags",
                "storageKey": "tags(last:100)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "last",
                    "value": 100,
                    "type": "Int"
                  }
                ],
                "concreteType": "TagConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Tag",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "tag_text",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd600131b74a5ab810211240ef107493';
module.exports = node;
