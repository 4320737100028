/**
 * @flow
 * @relayHash fbbf5242cfad1f8164f39cc10b1cdcc6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserInput = {
  id?: ?string,
  profile_image?: ?string,
  current_team_id?: ?number,
  email?: ?string,
  name?: ?string,
  current_project_id?: ?number,
  password?: ?string,
  password_confirmation?: ?string,
  send_email_notifications?: ?boolean,
  send_successful_login_notifications?: ?boolean,
  send_failed_login_notifications?: ?boolean,
  accept_terms?: ?boolean,
  completed_signup?: ?boolean,
  clientMutationId?: ?string,
};
export type PaginatedUserWorkspacesUpdateUserMutationVariables = {|
  input: UpdateUserInput
|};
export type PaginatedUserWorkspacesUpdateUserMutationResponse = {|
  +updateUser: ?{|
    +me: ?{|
      +current_team_id: ?number
    |}
  |}
|};
export type PaginatedUserWorkspacesUpdateUserMutation = {|
  variables: PaginatedUserWorkspacesUpdateUserMutationVariables,
  response: PaginatedUserWorkspacesUpdateUserMutationResponse,
|};
*/


/*
mutation PaginatedUserWorkspacesUpdateUserMutation(
  $input: UpdateUserInput!
) {
  updateUser(input: $input) {
    me {
      current_team_id
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateUserInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "current_team_id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "PaginatedUserWorkspacesUpdateUserMutation",
  "id": null,
  "text": "mutation PaginatedUserWorkspacesUpdateUserMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    me {\n      current_team_id\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PaginatedUserWorkspacesUpdateUserMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUser",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PaginatedUserWorkspacesUpdateUserMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUser",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1fbfcf2c915176f97fea4ced6d742a2';
module.exports = node;
