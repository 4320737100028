/**
 * @flow
 * @relayHash d2626f3a2f46a379e6cdae24672acff7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResendCancelInvitationInput = {
  email: string,
  action: string,
  clientMutationId?: ?string,
};
export type TeamMemberActionsResendInviteMutationVariables = {|
  input: ResendCancelInvitationInput
|};
export type TeamMemberActionsResendInviteMutationResponse = {|
  +resendCancelInvitation: ?{|
    +success: ?boolean
  |}
|};
export type TeamMemberActionsResendInviteMutation = {|
  variables: TeamMemberActionsResendInviteMutationVariables,
  response: TeamMemberActionsResendInviteMutationResponse,
|};
*/


/*
mutation TeamMemberActionsResendInviteMutation(
  $input: ResendCancelInvitationInput!
) {
  resendCancelInvitation(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ResendCancelInvitationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resendCancelInvitation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ResendCancelInvitationInput!"
      }
    ],
    "concreteType": "ResendCancelInvitationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TeamMemberActionsResendInviteMutation",
  "id": null,
  "text": "mutation TeamMemberActionsResendInviteMutation(\n  $input: ResendCancelInvitationInput!\n) {\n  resendCancelInvitation(input: $input) {\n    success\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamMemberActionsResendInviteMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamMemberActionsResendInviteMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a891f32082dd6f63a915f6294d970f54';
module.exports = node;
