/**
 * @flow
 * @relayHash 2a434132a87774a117070f8f08a43616
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ItemTitleQueryVariables = {|
  id: string
|};
export type ItemTitleQueryResponse = {|
  +projectMedia: ?{|
    +id: string,
    +permissions: ?string,
    +archived: ?number,
    +title: ?string,
    +title_field: ?string,
    +custom_title: ?string,
    +media_slug: ?string,
    +is_suggested: ?boolean,
    +claim_description: ?{|
      +description: ?string,
      +fact_check: ?{|
        +title: ?string
      |},
    |},
  |}
|};
export type ItemTitleQuery = {|
  variables: ItemTitleQueryVariables,
  response: ItemTitleQueryResponse,
|};
*/


/*
query ItemTitleQuery(
  $id: String!
) {
  projectMedia: project_media(ids: $id) {
    id
    permissions
    archived
    title
    title_field
    custom_title
    media_slug
    is_suggested
    claim_description {
      description
      fact_check {
        title
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "id",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "archived",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title_field",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "custom_title",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "media_slug",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "is_suggested",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ItemTitleQuery",
  "id": null,
  "text": "query ItemTitleQuery(\n  $id: String!\n) {\n  projectMedia: project_media(ids: $id) {\n    id\n    permissions\n    archived\n    title\n    title_field\n    custom_title\n    media_slug\n    is_suggested\n    claim_description {\n      description\n      fact_check {\n        title\n        id\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ItemTitleQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "projectMedia",
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v9,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              v10,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "fact_check",
                "storageKey": null,
                "args": null,
                "concreteType": "FactCheck",
                "plural": false,
                "selections": [
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ItemTitleQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "projectMedia",
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v9,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              v10,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "fact_check",
                "storageKey": null,
                "args": null,
                "concreteType": "FactCheck",
                "plural": false,
                "selections": [
                  v5,
                  v2
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '786a6402209df98416a0d222ba3950ed';
module.exports = node;
