/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedHeader_feedTeam$ref: FragmentReference;
export type FeedHeader_feedTeam = {|
  +team_id: ?number,
  +permissions: ?string,
  +$refType: FeedHeader_feedTeam$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "FeedHeader_feedTeam",
  "type": "FeedTeam",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "team_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'baa8c8ffe8949af23759072686abfb5b';
module.exports = node;
