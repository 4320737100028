/**
 * @flow
 * @relayHash 6a2986d0a51dcdadeb2ea33f1578aaa3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSavedSearchInput = {
  filters?: ?any,
  title: string,
  team_id: number,
  clientMutationId?: ?string,
};
export type NewProjectCreateSavedSearchMutationVariables = {|
  input: CreateSavedSearchInput
|};
export type NewProjectCreateSavedSearchMutationResponse = {|
  +createSavedSearch: ?{|
    +saved_search: ?{|
      +dbid: ?number
    |},
    +team: ?{|
      +saved_searches: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +dbid: ?number,
            +title: ?string,
            +filters: ?string,
            +medias_count: ?number,
          |}
        |}>
      |}
    |},
  |}
|};
export type NewProjectCreateSavedSearchMutation = {|
  variables: NewProjectCreateSavedSearchMutationVariables,
  response: NewProjectCreateSavedSearchMutationResponse,
|};
*/


/*
mutation NewProjectCreateSavedSearchMutation(
  $input: CreateSavedSearchInput!
) {
  createSavedSearch(input: $input) {
    saved_search {
      dbid
      id
    }
    team {
      saved_searches(first: 10000) {
        edges {
          node {
            id
            dbid
            title
            filters
            medias_count: items_count
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSavedSearchInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateSavedSearchInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "saved_searches",
  "storageKey": "saved_searches(first:10000)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000,
      "type": "Int"
    }
  ],
  "concreteType": "SavedSearchConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "SavedSearchEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "SavedSearch",
          "plural": false,
          "selections": [
            v3,
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filters",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": "medias_count",
              "name": "items_count",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "NewProjectCreateSavedSearchMutation",
  "id": null,
  "text": "mutation NewProjectCreateSavedSearchMutation(\n  $input: CreateSavedSearchInput!\n) {\n  createSavedSearch(input: $input) {\n    saved_search {\n      dbid\n      id\n    }\n    team {\n      saved_searches(first: 10000) {\n        edges {\n          node {\n            id\n            dbid\n            title\n            filters\n            medias_count: items_count\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewProjectCreateSavedSearchMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createSavedSearch",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateSavedSearchPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "saved_search",
            "storageKey": null,
            "args": null,
            "concreteType": "SavedSearch",
            "plural": false,
            "selections": [
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewProjectCreateSavedSearchMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createSavedSearch",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateSavedSearchPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "saved_search",
            "storageKey": null,
            "args": null,
            "concreteType": "SavedSearch",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4,
              v3
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd9cfbabec5335cfbc064db299f7d3536';
module.exports = node;
