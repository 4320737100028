/**
 * @flow
 * @relayHash 779a021d7117862f48208975d734180d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyFeedInvitationInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type SaveFeedDestroyFeedInvitationMutationVariables = {|
  input: DestroyFeedInvitationInput
|};
export type SaveFeedDestroyFeedInvitationMutationResponse = {|
  +destroyFeedInvitation: ?{|
    +deletedId: ?string,
    +feed: ?{|
      +teams_count: ?number,
      +feed_invitations: {|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +email: string,
            +state: string,
          |}
        |}>
      |},
    |},
  |}
|};
export type SaveFeedDestroyFeedInvitationMutation = {|
  variables: SaveFeedDestroyFeedInvitationMutationVariables,
  response: SaveFeedDestroyFeedInvitationMutationResponse,
|};
*/


/*
mutation SaveFeedDestroyFeedInvitationMutation(
  $input: DestroyFeedInvitationInput!
) {
  destroyFeedInvitation(input: $input) {
    deletedId
    feed {
      teams_count
      feed_invitations(first: 100) {
        edges {
          node {
            id
            email
            state
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyFeedInvitationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DestroyFeedInvitationInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deletedId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "teams_count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "feed_invitations",
  "storageKey": "feed_invitations(first:100)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100,
      "type": "Int"
    }
  ],
  "concreteType": "FeedInvitationConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "FeedInvitationEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "FeedInvitation",
          "plural": false,
          "selections": [
            v4,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "state",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveFeedDestroyFeedInvitationMutation",
  "id": null,
  "text": "mutation SaveFeedDestroyFeedInvitationMutation(\n  $input: DestroyFeedInvitationInput!\n) {\n  destroyFeedInvitation(input: $input) {\n    deletedId\n    feed {\n      teams_count\n      feed_invitations(first: 100) {\n        edges {\n          node {\n            id\n            email\n            state\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveFeedDestroyFeedInvitationMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyFeedInvitation",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyFeedInvitationPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveFeedDestroyFeedInvitationMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyFeedInvitation",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyFeedInvitationPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              v5,
              v4
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c8c5c4d180c4b3a68df94dbf355f2740';
module.exports = node;
