/**
 * @flow
 * @relayHash 2f20b76fd6ac23847cc3fb2eadd16155
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateRelationshipInput = {
  source_id?: ?number,
  target_id?: ?number,
  relationship_source_type?: ?string,
  relationship_target_type?: ?string,
  relationship_type?: ?any,
  clientMutationId?: ?string,
};
export type MediaSimilarityBarAddCreateRelationshipMutationVariables = {|
  input: CreateRelationshipInput
|};
export type MediaSimilarityBarAddCreateRelationshipMutationResponse = {|
  +createRelationship: ?{|
    +relationshipEdge: ?{|
      +node: ?{|
        +id: string,
        +dbid: ?number,
        +source_id: ?number,
        +target_id: ?number,
        +target: ?{|
          +id: string,
          +dbid: ?number,
          +title: ?string,
          +description: ?string,
          +picture: ?string,
          +type: ?string,
          +last_seen: ?string,
          +requests_count: ?number,
          +linked_items_count: ?number,
          +report_status: ?string,
          +url: ?string,
          +media: ?{|
            +url: ?string,
            +domain: ?string,
            +type: ?string,
          |},
        |},
      |}
    |},
    +source_project_media: ?{|
      +dbid: ?number,
      +hasMain: ?boolean,
      +suggestionsCount: ?number,
      +confirmedSimilarCount: ?number,
      +team: ?{|
        +slug: string
      |},
    |},
  |}
|};
export type MediaSimilarityBarAddCreateRelationshipMutation = {|
  variables: MediaSimilarityBarAddCreateRelationshipMutationVariables,
  response: MediaSimilarityBarAddCreateRelationshipMutationResponse,
|};
*/


/*
mutation MediaSimilarityBarAddCreateRelationshipMutation(
  $input: CreateRelationshipInput!
) {
  createRelationship(input: $input) {
    relationshipEdge {
      node {
        id
        dbid
        source_id
        target_id
        target {
          id
          dbid
          title
          description
          picture
          type
          last_seen
          requests_count
          linked_items_count
          report_status
          url
          media {
            url
            domain
            type
            id
          }
        }
      }
    }
    source_project_media {
      dbid
      hasMain: is_confirmed_similar_to_another_item
      suggestionsCount: suggested_similar_items_count
      confirmedSimilarCount: linked_items_count
      team {
        slug
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateRelationshipInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateRelationshipInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "source_id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "target_id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "picture",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "last_seen",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requests_count",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "linked_items_count",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "report_status",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "domain",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": "hasMain",
  "name": "is_confirmed_similar_to_another_item",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": "suggestionsCount",
  "name": "suggested_similar_items_count",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": "confirmedSimilarCount",
  "name": "linked_items_count",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaSimilarityBarAddCreateRelationshipMutation",
  "id": null,
  "text": "mutation MediaSimilarityBarAddCreateRelationshipMutation(\n  $input: CreateRelationshipInput!\n) {\n  createRelationship(input: $input) {\n    relationshipEdge {\n      node {\n        id\n        dbid\n        source_id\n        target_id\n        target {\n          id\n          dbid\n          title\n          description\n          picture\n          type\n          last_seen\n          requests_count\n          linked_items_count\n          report_status\n          url\n          media {\n            url\n            domain\n            type\n            id\n          }\n        }\n      }\n    }\n    source_project_media {\n      dbid\n      hasMain: is_confirmed_similar_to_another_item\n      suggestionsCount: suggested_similar_items_count\n      confirmedSimilarCount: linked_items_count\n      team {\n        slug\n        id\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSimilarityBarAddCreateRelationshipMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createRelationship",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateRelationshipPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "relationshipEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "RelationshipEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Relationship",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "target",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProjectMedia",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v6,
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      v12,
                      v13,
                      v14,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "media",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Media",
                        "plural": false,
                        "selections": [
                          v14,
                          v15,
                          v9
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v3,
              v16,
              v17,
              v18,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  v19
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSimilarityBarAddCreateRelationshipMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createRelationship",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateRelationshipPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "relationshipEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "RelationshipEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Relationship",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "target",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProjectMedia",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v6,
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      v12,
                      v13,
                      v14,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "media",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Media",
                        "plural": false,
                        "selections": [
                          v14,
                          v15,
                          v9,
                          v2
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v3,
              v16,
              v17,
              v18,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  v19,
                  v2
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '717d73a7b0be66f20e6fb9030050771f';
module.exports = node;
