/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type FeedTeamFactCheckCard_clusterTeamFactCheck$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedTeamCard_clusterTeam$ref: FragmentReference;
export type FeedTeamCard_clusterTeam = {|
  +media_count: ?number,
  +requests_count: ?number,
  +last_request_date: ?number,
  +fact_checks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +media_count: ?number,
        +requests_count: ?number,
        +$fragmentRefs: FeedTeamFactCheckCard_clusterTeamFactCheck$ref,
      |}
    |}>
  |},
  +$refType: FeedTeamCard_clusterTeam$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "media_count",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requests_count",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "FeedTeamCard_clusterTeam",
  "type": "ClusterTeam",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "last_request_date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fact_checks",
      "storageKey": "fact_checks(first:100)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100,
          "type": "Int"
        }
      ],
      "concreteType": "ClusterTeamFactCheckConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ClusterTeamFactCheckEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ClusterTeamFactCheck",
              "plural": false,
              "selections": [
                v0,
                v1,
                {
                  "kind": "FragmentSpread",
                  "name": "FeedTeamFactCheckCard_clusterTeamFactCheck",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '15ea11ab374edf151a751aa452467106';
module.exports = node;
