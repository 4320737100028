/**
 * @flow
 * @relayHash 9473b7f5c33744275607a1a3675a123c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SmoochBotResourceEditor_tiplineResource$ref = any;
export type DestroyTiplineResourceInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type SmoochBotResourceEditorDestroyMutationVariables = {|
  input: DestroyTiplineResourceInput
|};
export type SmoochBotResourceEditorDestroyMutationResponse = {|
  +destroyTiplineResource: ?{|
    +team: ?{|
      +id: string,
      +tipline_resources: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +$fragmentRefs: SmoochBotResourceEditor_tiplineResource$ref
          |}
        |}>
      |},
    |}
  |}
|};
export type SmoochBotResourceEditorDestroyMutation = {|
  variables: SmoochBotResourceEditorDestroyMutationVariables,
  response: SmoochBotResourceEditorDestroyMutationResponse,
|};
*/


/*
mutation SmoochBotResourceEditorDestroyMutation(
  $input: DestroyTiplineResourceInput!
) {
  destroyTiplineResource(input: $input) {
    team {
      id
      tipline_resources(first: 10000) {
        edges {
          node {
            ...SmoochBotResourceEditor_tiplineResource
            id
          }
        }
      }
    }
  }
}

fragment SmoochBotResourceEditor_tiplineResource on TiplineResource {
  id
  dbid
  uuid
  language
  title
  header_type
  header_file_url
  header_overlay_text
  content_type
  content
  number_of_articles
  rss_feed_url
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyTiplineResourceInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DestroyTiplineResourceInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SmoochBotResourceEditorDestroyMutation",
  "id": null,
  "text": "mutation SmoochBotResourceEditorDestroyMutation(\n  $input: DestroyTiplineResourceInput!\n) {\n  destroyTiplineResource(input: $input) {\n    team {\n      id\n      tipline_resources(first: 10000) {\n        edges {\n          node {\n            ...SmoochBotResourceEditor_tiplineResource\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment SmoochBotResourceEditor_tiplineResource on TiplineResource {\n  id\n  dbid\n  uuid\n  language\n  title\n  header_type\n  header_file_url\n  header_overlay_text\n  content_type\n  content\n  number_of_articles\n  rss_feed_url\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SmoochBotResourceEditorDestroyMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyTiplineResource",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyTiplineResourcePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tipline_resources",
                "storageKey": "tipline_resources(first:10000)",
                "args": v3,
                "concreteType": "TiplineResourceConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TiplineResourceEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TiplineResource",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "SmoochBotResourceEditor_tiplineResource",
                            "args": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SmoochBotResourceEditorDestroyMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyTiplineResource",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyTiplineResourcePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tipline_resources",
                "storageKey": "tipline_resources(first:10000)",
                "args": v3,
                "concreteType": "TiplineResourceConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TiplineResourceEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TiplineResource",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "dbid",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "uuid",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "language",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "header_type",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "header_file_url",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "header_overlay_text",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "content_type",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "content",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "number_of_articles",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "rss_feed_url",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '69b15ecc408bb4bdc6281a6f81726eb7';
module.exports = node;
