/**
 * @flow
 * @relayHash 8d47c456bbcf98ec67fb5c5aa51378e5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamBotInstallationInput = {
  id?: ?string,
  json_settings?: ?string,
  lock_version?: ?number,
  clientMutationId?: ?string,
};
export type SimilarityComponentUpdateTeamBotInstallationMutationVariables = {|
  input: UpdateTeamBotInstallationInput
|};
export type SimilarityComponentUpdateTeamBotInstallationMutationResponse = {|
  +updateTeamBotInstallation: ?{|
    +team_bot_installation: ?{|
      +id: string,
      +json_settings: ?string,
      +lock_version: ?number,
      +team: ?{|
        +id: string,
        +alegre_bot: ?{|
          +id: string,
          +alegre_settings: ?any,
          +lock_version: ?number,
        |},
      |},
    |}
  |}
|};
export type SimilarityComponentUpdateTeamBotInstallationMutation = {|
  variables: SimilarityComponentUpdateTeamBotInstallationMutationVariables,
  response: SimilarityComponentUpdateTeamBotInstallationMutationResponse,
|};
*/


/*
mutation SimilarityComponentUpdateTeamBotInstallationMutation(
  $input: UpdateTeamBotInstallationInput!
) {
  updateTeamBotInstallation(input: $input) {
    team_bot_installation {
      id
      json_settings
      lock_version
      team {
        id
        alegre_bot: team_bot_installation(bot_identifier: "alegre") {
          id
          alegre_settings
          lock_version
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamBotInstallationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lock_version",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateTeamBotInstallation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateTeamBotInstallationInput!"
      }
    ],
    "concreteType": "UpdateTeamBotInstallationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team_bot_installation",
        "storageKey": null,
        "args": null,
        "concreteType": "TeamBotInstallation",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "json_settings",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "LinkedField",
                "alias": "alegre_bot",
                "name": "team_bot_installation",
                "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "bot_identifier",
                    "value": "alegre",
                    "type": "String!"
                  }
                ],
                "concreteType": "TeamBotInstallation",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "alegre_settings",
                    "args": null,
                    "storageKey": null
                  },
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SimilarityComponentUpdateTeamBotInstallationMutation",
  "id": null,
  "text": "mutation SimilarityComponentUpdateTeamBotInstallationMutation(\n  $input: UpdateTeamBotInstallationInput!\n) {\n  updateTeamBotInstallation(input: $input) {\n    team_bot_installation {\n      id\n      json_settings\n      lock_version\n      team {\n        id\n        alegre_bot: team_bot_installation(bot_identifier: \"alegre\") {\n          id\n          alegre_settings\n          lock_version\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SimilarityComponentUpdateTeamBotInstallationMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v3
  },
  "operation": {
    "kind": "Operation",
    "name": "SimilarityComponentUpdateTeamBotInstallationMutation",
    "argumentDefinitions": v0,
    "selections": v3
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9a5e52ab8bb8e3833d66f9d39ea4fd02';
module.exports = node;
