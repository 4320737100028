/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ChangeUserRole_teamUser$ref = any;
type TeamMemberActions_team$ref = any;
type TeamMemberActions_teamUser$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeamMembersComponent_team$ref: FragmentReference;
export type TeamMembersComponent_team = {|
  +id: string,
  +permissions: ?string,
  +team_users: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +status: ?string,
        +role: ?string,
        +user: ?{|
          +id: string,
          +dbid: ?number,
          +email: ?string,
          +is_bot: ?boolean,
          +last_active_at: ?number,
          +name: ?string,
          +profile_image: ?string,
        |},
        +$fragmentRefs: TeamMemberActions_teamUser$ref & ChangeUserRole_teamUser$ref,
      |}
    |}>
  |},
  +$fragmentRefs: TeamMemberActions_team$ref,
  +$refType: TeamMembersComponent_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TeamMembersComponent_team",
  "type": "Team",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TeamMemberActions_team",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team_users",
      "storageKey": "team_users(first:10000,status:[\"invited\",\"member\",\"banned\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000,
          "type": "Int"
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": [
            "invited",
            "member",
            "banned"
          ],
          "type": "[String]"
        }
      ],
      "concreteType": "TeamUserConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TeamUserEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TeamUser",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "role",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "dbid",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "email",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "is_bot",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "last_active_at",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "profile_image",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "TeamMemberActions_teamUser",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ChangeUserRole_teamUser",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f13777f4cde09d86a68ffe9fdb88d96';
module.exports = node;
