/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type FeedTeamCard_feed$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedItemTeams_feed$ref: FragmentReference;
export type FeedItemTeams_feed = {|
  +$fragmentRefs: FeedTeamCard_feed$ref,
  +$refType: FeedItemTeams_feed$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "FeedItemTeams_feed",
  "type": "Feed",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FeedTeamCard_feed",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '75beb453d735bbcd52a7d54814780783';
module.exports = node;
