/**
 * @flow
 * @relayHash 9d5272d205bcb95e450a64d7d2fcc2a8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDynamicAnnotationFlagInput = {
  id?: ?string,
  fragment?: ?string,
  annotated_id?: ?string,
  annotated_type?: ?string,
  action?: ?string,
  set_attribution?: ?string,
  action_data?: ?string,
  set_fields?: ?string,
  lock_version?: ?number,
  assigned_to_ids?: ?string,
  locked?: ?boolean,
  clientMutationId?: ?string,
};
export type SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutationVariables = {|
  input: UpdateDynamicAnnotationFlagInput
|};
export type SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutationResponse = {|
  +updateDynamicAnnotationFlag: ?{|
    +project_media: ?{|
      +id: string,
      +show_warning_cover: ?boolean,
      +dynamic_annotation_flag: ?{|
        +id: string,
        +dbid: ?string,
        +content: ?string,
        +data: ?any,
        +annotator: ?{|
          +name: ?string
        |},
      |},
    |}
  |}
|};
export type SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutation = {|
  variables: SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutationVariables,
  response: SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutationResponse,
|};
*/


/*
mutation SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutation(
  $input: UpdateDynamicAnnotationFlagInput!
) {
  updateDynamicAnnotationFlag(input: $input) {
    project_media {
      id
      show_warning_cover
      dynamic_annotation_flag {
        id
        dbid
        content
        data
        annotator {
          name
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDynamicAnnotationFlagInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateDynamicAnnotationFlagInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "show_warning_cover",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "data",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutation",
  "id": null,
  "text": "mutation SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutation(\n  $input: UpdateDynamicAnnotationFlagInput!\n) {\n  updateDynamicAnnotationFlag(input: $input) {\n    project_media {\n      id\n      show_warning_cover\n      dynamic_annotation_flag {\n        id\n        dbid\n        content\n        data\n        annotator {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDynamicAnnotationFlag",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateDynamicAnnotationFlagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "dynamic_annotation_flag",
                "storageKey": null,
                "args": null,
                "concreteType": "Dynamic",
                "plural": false,
                "selections": [
                  v2,
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "annotator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Annotator",
                    "plural": false,
                    "selections": [
                      v7
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SensitiveContentMenuButtonUpdateDynamicAnnotationFlagMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDynamicAnnotationFlag",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateDynamicAnnotationFlagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "dynamic_annotation_flag",
                "storageKey": null,
                "args": null,
                "concreteType": "Dynamic",
                "plural": false,
                "selections": [
                  v2,
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "annotator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Annotator",
                    "plural": false,
                    "selections": [
                      v7,
                      v2
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc182af3752e0eb572bfd26e8a1b6f75';
module.exports = node;
