/**
 * @flow
 * @relayHash bdf7fc443abd5c8f851aba3391634996
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyTeamUserInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type TeamMemberActionsRemoveUserMutationVariables = {|
  input: DestroyTeamUserInput
|};
export type TeamMemberActionsRemoveUserMutationResponse = {|
  +destroyTeamUser: ?{|
    +deletedId: ?string,
    +team: ?{|
      +team_users: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +status: ?string,
            +role: ?string,
          |}
        |}>
      |}
    |},
  |}
|};
export type TeamMemberActionsRemoveUserMutation = {|
  variables: TeamMemberActionsRemoveUserMutationVariables,
  response: TeamMemberActionsRemoveUserMutationResponse,
|};
*/


/*
mutation TeamMemberActionsRemoveUserMutation(
  $input: DestroyTeamUserInput!
) {
  destroyTeamUser(input: $input) {
    deletedId
    team {
      team_users(first: 10000, status: ["invited", "member", "banned"]) {
        edges {
          node {
            id
            status
            role
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyTeamUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DestroyTeamUserInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deletedId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team_users",
  "storageKey": "team_users(first:10000,status:[\"invited\",\"member\",\"banned\"])",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000,
      "type": "Int"
    },
    {
      "kind": "Literal",
      "name": "status",
      "value": [
        "invited",
        "member",
        "banned"
      ],
      "type": "[String]"
    }
  ],
  "concreteType": "TeamUserConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TeamUserEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "TeamUser",
          "plural": false,
          "selections": [
            v3,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "status",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "role",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TeamMemberActionsRemoveUserMutation",
  "id": null,
  "text": "mutation TeamMemberActionsRemoveUserMutation(\n  $input: DestroyTeamUserInput!\n) {\n  destroyTeamUser(input: $input) {\n    deletedId\n    team {\n      team_users(first: 10000, status: [\"invited\", \"member\", \"banned\"]) {\n        edges {\n          node {\n            id\n            status\n            role\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamMemberActionsRemoveUserMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyTeamUser",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyTeamUserPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamMemberActionsRemoveUserMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyTeamUser",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyTeamUserPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4,
              v3
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0484290cce4d75f13ab8cff67b99af7d';
module.exports = node;
