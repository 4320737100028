/**
 * @flow
 * @relayHash a26973622cbc564e7bad9d3dfa484f4f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MediaSimilarityBarQueryVariables = {|
  ids: string
|};
export type MediaSimilarityBarQueryResponse = {|
  +project_media: ?{|
    +id: string,
    +dbid: ?number,
    +type: ?string,
    +permissions: ?string,
    +report_status: ?string,
    +hasMain: ?boolean,
    +confirmedMainItem: ?{|
      +id: string,
      +dbid: ?number,
    |},
    +suggestionsCount: ?number,
    +confirmedSimilarCount: ?number,
    +suggested_similar_relationships: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +target_id: ?number,
          +target: ?{|
            +id: string,
            +dbid: ?number,
            +project_id: ?number,
            +created_at: ?string,
            +last_seen: ?string,
            +title: ?string,
            +description: ?string,
            +picture: ?string,
            +type: ?string,
            +requests_count: ?number,
            +report_status: ?string,
            +domain: ?string,
            +url: ?string,
            +media: ?{|
              +url: ?string
            |},
          |},
        |}
      |}>
    |},
    +team: ?{|
      +slug: string,
      +smooch_bot: ?{|
        +id: string
      |},
      +permissions: ?string,
    |},
  |}
|};
export type MediaSimilarityBarQuery = {|
  variables: MediaSimilarityBarQueryVariables,
  response: MediaSimilarityBarQueryResponse,
|};
*/


/*
query MediaSimilarityBarQuery(
  $ids: String!
) {
  project_media(ids: $ids) {
    id
    dbid
    type
    permissions
    report_status
    hasMain: is_confirmed_similar_to_another_item
    confirmedMainItem: confirmed_main_item {
      id
      dbid
    }
    suggestionsCount: suggested_similar_items_count
    confirmedSimilarCount: linked_items_count
    suggested_similar_relationships(first: 10000) {
      edges {
        node {
          id
          target_id
          target {
            id
            dbid
            project_id
            created_at
            last_seen
            title
            description
            picture
            type
            requests_count
            report_status
            domain
            url
            media {
              url
              id
            }
          }
        }
      }
    }
    team {
      slug
      smooch_bot: team_bot_installation(bot_identifier: "smooch") {
        id
      }
      permissions
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ids",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "report_status",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": "hasMain",
  "name": "is_confirmed_similar_to_another_item",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": "confirmedMainItem",
  "name": "confirmed_main_item",
  "storageKey": null,
  "args": null,
  "concreteType": "ProjectMedia",
  "plural": false,
  "selections": [
    v2,
    v3
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": "suggestionsCount",
  "name": "suggested_similar_items_count",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": "confirmedSimilarCount",
  "name": "linked_items_count",
  "args": null,
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "target_id",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "project_id",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created_at",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "last_seen",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "picture",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requests_count",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "domain",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "LinkedField",
  "alias": "smooch_bot",
  "name": "team_bot_installation",
  "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
  "args": [
    {
      "kind": "Literal",
      "name": "bot_identifier",
      "value": "smooch",
      "type": "String!"
    }
  ],
  "concreteType": "TeamBotInstallation",
  "plural": false,
  "selections": [
    v2
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MediaSimilarityBarQuery",
  "id": null,
  "text": "query MediaSimilarityBarQuery(\n  $ids: String!\n) {\n  project_media(ids: $ids) {\n    id\n    dbid\n    type\n    permissions\n    report_status\n    hasMain: is_confirmed_similar_to_another_item\n    confirmedMainItem: confirmed_main_item {\n      id\n      dbid\n    }\n    suggestionsCount: suggested_similar_items_count\n    confirmedSimilarCount: linked_items_count\n    suggested_similar_relationships(first: 10000) {\n      edges {\n        node {\n          id\n          target_id\n          target {\n            id\n            dbid\n            project_id\n            created_at\n            last_seen\n            title\n            description\n            picture\n            type\n            requests_count\n            report_status\n            domain\n            url\n            media {\n              url\n              id\n            }\n          }\n        }\n      }\n    }\n    team {\n      slug\n      smooch_bot: team_bot_installation(bot_identifier: \"smooch\") {\n        id\n      }\n      permissions\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSimilarityBarQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v9,
          v10,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "suggested_similar_relationships",
            "storageKey": "suggested_similar_relationships(first:10000)",
            "args": v11,
            "concreteType": "RelationshipConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "RelationshipEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Relationship",
                    "plural": false,
                    "selections": [
                      v2,
                      v12,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "target",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProjectMedia",
                        "plural": false,
                        "selections": [
                          v2,
                          v3,
                          v13,
                          v14,
                          v15,
                          v16,
                          v17,
                          v18,
                          v4,
                          v19,
                          v6,
                          v20,
                          v21,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "media",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Media",
                            "plural": false,
                            "selections": [
                              v21
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v22,
              v23,
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSimilarityBarQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v9,
          v10,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "suggested_similar_relationships",
            "storageKey": "suggested_similar_relationships(first:10000)",
            "args": v11,
            "concreteType": "RelationshipConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "RelationshipEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Relationship",
                    "plural": false,
                    "selections": [
                      v2,
                      v12,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "target",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProjectMedia",
                        "plural": false,
                        "selections": [
                          v2,
                          v3,
                          v13,
                          v14,
                          v15,
                          v16,
                          v17,
                          v18,
                          v4,
                          v19,
                          v6,
                          v20,
                          v21,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "media",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Media",
                            "plural": false,
                            "selections": [
                              v21,
                              v2
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v22,
              v23,
              v5,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '09ee7d7b77f9349d9db65e681ed57689';
module.exports = node;
