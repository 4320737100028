/**
 * @flow
 * @relayHash d6e192b54b56ac0140b7ea7a534fdb11
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamInput = {
  id?: ?string,
  archived?: ?number,
  private?: ?boolean,
  description?: ?string,
  name?: ?string,
  add_auto_task?: ?any,
  media_verification_statuses?: ?any,
  set_team_tasks?: ?any,
  rules?: ?string,
  remove_auto_task?: ?string,
  empty_trash?: ?number,
  report?: ?any,
  slack_notifications_enabled?: ?string,
  slack_webhook?: ?string,
  slack_notifications?: ?string,
  language?: ?string,
  languages?: ?any,
  language_detection?: ?boolean,
  tipline_inbox_filters?: ?string,
  suggested_matches_filters?: ?string,
  outgoing_urls_utm_code?: ?string,
  shorten_outgoing_urls?: ?boolean,
  clientMutationId?: ?string,
};
export type StatusesComponentUpdateTeamMutationVariables = {|
  input: UpdateTeamInput
|};
export type StatusesComponentUpdateTeamMutationResponse = {|
  +updateTeam: ?{|
    +team: ?{|
      +id: string,
      +verification_statuses: ?any,
    |}
  |}
|};
export type StatusesComponentUpdateTeamMutation = {|
  variables: StatusesComponentUpdateTeamMutationVariables,
  response: StatusesComponentUpdateTeamMutationResponse,
|};
*/


/*
mutation StatusesComponentUpdateTeamMutation(
  $input: UpdateTeamInput!
) {
  updateTeam(input: $input) {
    team {
      id
      verification_statuses
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateTeam",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateTeamInput!"
      }
    ],
    "concreteType": "UpdateTeamPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verification_statuses",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "StatusesComponentUpdateTeamMutation",
  "id": null,
  "text": "mutation StatusesComponentUpdateTeamMutation(\n  $input: UpdateTeamInput!\n) {\n  updateTeam(input: $input) {\n    team {\n      id\n      verification_statuses\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "StatusesComponentUpdateTeamMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "StatusesComponentUpdateTeamMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dfd2b57fa3d824d7088815ce8befc256';
module.exports = node;
