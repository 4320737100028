/**
 * @flow
 * @relayHash 43c86d9b8d50f19242d0ee1658ccd952
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RejectInput = {
  id: number,
  clientMutationId?: ?string,
};
export type FeedInvitationRespondRejectFeedInvitationMutationVariables = {|
  input: RejectInput
|};
export type FeedInvitationRespondRejectFeedInvitationMutationResponse = {|
  +rejectFeedInvitation: ?{|
    +success: ?boolean
  |}
|};
export type FeedInvitationRespondRejectFeedInvitationMutation = {|
  variables: FeedInvitationRespondRejectFeedInvitationMutationVariables,
  response: FeedInvitationRespondRejectFeedInvitationMutationResponse,
|};
*/


/*
mutation FeedInvitationRespondRejectFeedInvitationMutation(
  $input: RejectInput!
) {
  rejectFeedInvitation(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RejectInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "rejectFeedInvitation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RejectInput!"
      }
    ],
    "concreteType": "RejectPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "FeedInvitationRespondRejectFeedInvitationMutation",
  "id": null,
  "text": "mutation FeedInvitationRespondRejectFeedInvitationMutation(\n  $input: RejectInput!\n) {\n  rejectFeedInvitation(input: $input) {\n    success\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FeedInvitationRespondRejectFeedInvitationMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "FeedInvitationRespondRejectFeedInvitationMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '185fa8913752e35ba790e1be3f0a6157';
module.exports = node;
