/**
 * @flow
 * @relayHash 3c86f579bbd6082bfca24e93c082c785
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDynamicAnnotationLanguageInput = {
  id?: ?string,
  fragment?: ?string,
  annotated_id?: ?string,
  annotated_type?: ?string,
  action?: ?string,
  set_attribution?: ?string,
  action_data?: ?string,
  set_fields?: ?string,
  lock_version?: ?number,
  assigned_to_ids?: ?string,
  locked?: ?boolean,
  clientMutationId?: ?string,
};
export type MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutationVariables = {|
  input: UpdateDynamicAnnotationLanguageInput
|};
export type MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutationResponse = {|
  +updateDynamicAnnotationLanguage: ?{|
    +project_media: ?{|
      +id: string,
      +language_code: ?string,
    |}
  |}
|};
export type MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutation = {|
  variables: MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutationVariables,
  response: MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutationResponse,
|};
*/


/*
mutation MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutation(
  $input: UpdateDynamicAnnotationLanguageInput!
) {
  updateDynamicAnnotationLanguage(input: $input) {
    project_media {
      id
      language_code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDynamicAnnotationLanguageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateDynamicAnnotationLanguage",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateDynamicAnnotationLanguageInput!"
      }
    ],
    "concreteType": "UpdateDynamicAnnotationLanguagePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": null,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "language_code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutation",
  "id": null,
  "text": "mutation MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutation(\n  $input: UpdateDynamicAnnotationLanguageInput!\n) {\n  updateDynamicAnnotationLanguage(input: $input) {\n    project_media {\n      id\n      language_code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaLanguageSwitcherUpdateDynamicAnnotationLanguageMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd9592dd749a718769495d0fb21784f95';
module.exports = node;
