/**
 * @flow
 * @relayHash 6b76f7a8d230a60ca9d57c7ce1ae2eb4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAccountSourceInput = {
  account_id?: ?number,
  source_id: number,
  url?: ?string,
  clientMutationId?: ?string,
};
export type SourceInfoCreateAccountSourceMutationVariables = {|
  input: CreateAccountSourceInput
|};
export type SourceInfoCreateAccountSourceMutationResponse = {|
  +createAccountSource: ?{|
    +source: ?{|
      +id: string,
      +name: string,
      +updated_at: ?string,
      +account_sources: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +permissions: ?string,
            +account: ?{|
              +id: string,
              +url: string,
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type SourceInfoCreateAccountSourceMutation = {|
  variables: SourceInfoCreateAccountSourceMutationVariables,
  response: SourceInfoCreateAccountSourceMutationResponse,
|};
*/


/*
mutation SourceInfoCreateAccountSourceMutation(
  $input: CreateAccountSourceInput!
) {
  createAccountSource(input: $input) {
    source {
      id
      name
      updated_at
      account_sources(first: 10000) {
        edges {
          node {
            id
            permissions
            account {
              id
              url
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateAccountSourceInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createAccountSource",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateAccountSourceInput!"
      }
    ],
    "concreteType": "CreateAccountSourcePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "source",
        "storageKey": null,
        "args": null,
        "concreteType": "Source",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updated_at",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account_sources",
            "storageKey": "account_sources(first:10000)",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000,
                "type": "Int"
              }
            ],
            "concreteType": "AccountSourceConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountSourceEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AccountSource",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "permissions",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Account",
                        "plural": false,
                        "selections": [
                          v1,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "url",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SourceInfoCreateAccountSourceMutation",
  "id": null,
  "text": "mutation SourceInfoCreateAccountSourceMutation(\n  $input: CreateAccountSourceInput!\n) {\n  createAccountSource(input: $input) {\n    source {\n      id\n      name\n      updated_at\n      account_sources(first: 10000) {\n        edges {\n          node {\n            id\n            permissions\n            account {\n              id\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SourceInfoCreateAccountSourceMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "SourceInfoCreateAccountSourceMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ef2b29b01e3ed21bc1f3c68b927de43';
module.exports = node;
