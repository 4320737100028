/**
 * @flow
 * @relayHash 8f5cf589b364cb1c7d36fb16594db9cb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamInput = {
  id?: ?string,
  archived?: ?number,
  private?: ?boolean,
  description?: ?string,
  name?: ?string,
  add_auto_task?: ?any,
  media_verification_statuses?: ?any,
  set_team_tasks?: ?any,
  rules?: ?string,
  remove_auto_task?: ?string,
  empty_trash?: ?number,
  report?: ?any,
  slack_notifications_enabled?: ?string,
  slack_webhook?: ?string,
  slack_notifications?: ?string,
  language?: ?string,
  languages?: ?any,
  language_detection?: ?boolean,
  tipline_inbox_filters?: ?string,
  suggested_matches_filters?: ?string,
  outgoing_urls_utm_code?: ?string,
  shorten_outgoing_urls?: ?boolean,
  clientMutationId?: ?string,
};
export type SaveTagUpdateTeamMutationVariables = {|
  input: UpdateTeamInput
|};
export type SaveTagUpdateTeamMutationResponse = {|
  +updateTeam: ?{|
    +team: ?{|
      +get_rules: ?any
    |}
  |}
|};
export type SaveTagUpdateTeamMutation = {|
  variables: SaveTagUpdateTeamMutationVariables,
  response: SaveTagUpdateTeamMutationResponse,
|};
*/


/*
mutation SaveTagUpdateTeamMutation(
  $input: UpdateTeamInput!
) {
  updateTeam(input: $input) {
    team {
      get_rules
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateTeamInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_rules",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveTagUpdateTeamMutation",
  "id": null,
  "text": "mutation SaveTagUpdateTeamMutation(\n  $input: UpdateTeamInput!\n) {\n  updateTeam(input: $input) {\n    team {\n      get_rules\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveTagUpdateTeamMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveTagUpdateTeamMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '269424c9150fce0eaa781f486a60ce5c';
module.exports = node;
