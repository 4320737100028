/**
 * @flow
 * @relayHash 40ed7d48aeedaa2ab8df54c0c9f30592
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyFeedTeamInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type SaveFeedRemoveCollaboratorMutationVariables = {|
  input: DestroyFeedTeamInput
|};
export type SaveFeedRemoveCollaboratorMutationResponse = {|
  +destroyFeedTeam: ?{|
    +deletedId: ?string,
    +feed: ?{|
      +teams_count: ?number,
      +feed_teams: {|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +team: ?{|
              +name: string
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type SaveFeedRemoveCollaboratorMutation = {|
  variables: SaveFeedRemoveCollaboratorMutationVariables,
  response: SaveFeedRemoveCollaboratorMutationResponse,
|};
*/


/*
mutation SaveFeedRemoveCollaboratorMutation(
  $input: DestroyFeedTeamInput!
) {
  destroyFeedTeam(input: $input) {
    deletedId
    feed {
      teams_count
      feed_teams(first: 100) {
        edges {
          node {
            id
            team {
              name
              id
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyFeedTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DestroyFeedTeamInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deletedId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "teams_count",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100,
    "type": "Int"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveFeedRemoveCollaboratorMutation",
  "id": null,
  "text": "mutation SaveFeedRemoveCollaboratorMutation(\n  $input: DestroyFeedTeamInput!\n) {\n  destroyFeedTeam(input: $input) {\n    deletedId\n    feed {\n      teams_count\n      feed_teams(first: 100) {\n        edges {\n          node {\n            id\n            team {\n              name\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveFeedRemoveCollaboratorMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyFeedTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyFeedTeamPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:100)",
                "args": v4,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          v5,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "team",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PublicTeam",
                            "plural": false,
                            "selections": [
                              v6
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveFeedRemoveCollaboratorMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyFeedTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyFeedTeamPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:100)",
                "args": v4,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          v5,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "team",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PublicTeam",
                            "plural": false,
                            "selections": [
                              v6,
                              v5
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              v5
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '694b8b09bb869a7bccad157e1e678caf';
module.exports = node;
