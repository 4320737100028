/**
 * @flow
 * @relayHash ab4bab7267093fbe65fd508beede645a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ExplainerForm_article$ref = any;
type ExplainerForm_team$ref = any;
export type ExplainerFormQueryVariables = {|
  teamSlug: string,
  explainerId: string,
|};
export type ExplainerFormQueryResponse = {|
  +team: ?{|
    +$fragmentRefs: ExplainerForm_team$ref
  |},
  +explainer: ?{|
    +$fragmentRefs: ExplainerForm_article$ref
  |},
|};
export type ExplainerFormQuery = {|
  variables: ExplainerFormQueryVariables,
  response: ExplainerFormQueryResponse,
|};
*/


/*
query ExplainerFormQuery(
  $teamSlug: String!
  $explainerId: ID!
) {
  team(slug: $teamSlug) {
    ...ExplainerForm_team
    id
  }
  explainer(id: $explainerId) {
    ...ExplainerForm_article
    id
  }
}

fragment ExplainerForm_team on Team {
  ...ArticleForm_team
}

fragment ExplainerForm_article on Explainer {
  id
  ...ArticleForm_article
}

fragment ArticleForm_article on Node {
  ... on FactCheck {
    title
    summary
    url
    language
    tags
    rating
    report_status
    updated_at
    user {
      name
      id
    }
    claim_description {
      description
      context
      project_media {
        dbid
        title
        type
        full_url
        last_status_obj {
          locked
          id
        }
        id
      }
      id
    }
  }
  ... on Explainer {
    title
    description
    url
    language
    tags
    updated_at
    user {
      name
      id
    }
  }
  ...ArticleTrash_article
}

fragment ArticleTrash_article on Node {
  ... on FactCheck {
    id
    trashed
    claim_description {
      project_media {
        title
        full_url
        type
        id
      }
      id
    }
  }
  ... on Explainer {
    id
    trashed
  }
}

fragment ArticleForm_team on Team {
  verification_statuses
  get_language
  get_languages
  slug
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "explainerId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "explainerId",
    "type": "ID!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ExplainerFormQuery",
  "id": null,
  "text": "query ExplainerFormQuery(\n  $teamSlug: String!\n  $explainerId: ID!\n) {\n  team(slug: $teamSlug) {\n    ...ExplainerForm_team\n    id\n  }\n  explainer(id: $explainerId) {\n    ...ExplainerForm_article\n    id\n  }\n}\n\nfragment ExplainerForm_team on Team {\n  ...ArticleForm_team\n}\n\nfragment ExplainerForm_article on Explainer {\n  id\n  ...ArticleForm_article\n}\n\nfragment ArticleForm_article on Node {\n  ... on FactCheck {\n    title\n    summary\n    url\n    language\n    tags\n    rating\n    report_status\n    updated_at\n    user {\n      name\n      id\n    }\n    claim_description {\n      description\n      context\n      project_media {\n        dbid\n        title\n        type\n        full_url\n        last_status_obj {\n          locked\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n  ... on Explainer {\n    title\n    description\n    url\n    language\n    tags\n    updated_at\n    user {\n      name\n      id\n    }\n  }\n  ...ArticleTrash_article\n}\n\nfragment ArticleTrash_article on Node {\n  ... on FactCheck {\n    id\n    trashed\n    claim_description {\n      project_media {\n        title\n        full_url\n        type\n        id\n      }\n      id\n    }\n  }\n  ... on Explainer {\n    id\n    trashed\n  }\n}\n\nfragment ArticleForm_team on Team {\n  verification_statuses\n  get_language\n  get_languages\n  slug\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ExplainerFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ExplainerForm_team",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "explainer",
        "storageKey": null,
        "args": v2,
        "concreteType": "Explainer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ExplainerForm_article",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ExplainerFormQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verification_statuses",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_language",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_languages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          v3
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "explainer",
        "storageKey": null,
        "args": v2,
        "concreteType": "Explainer",
        "plural": false,
        "selections": [
          v3,
          v4,
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "language",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tags",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updated_at",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              v3
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "trashed",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "type": "FactCheck",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "summary",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "rating",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "report_status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "claim_description",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimDescription",
                "plural": false,
                "selections": [
                  v5,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "context",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "project_media",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProjectMedia",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dbid",
                        "args": null,
                        "storageKey": null
                      },
                      v4,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "full_url",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "last_status_obj",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Dynamic",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "locked",
                            "args": null,
                            "storageKey": null
                          },
                          v3
                        ]
                      },
                      v3
                    ]
                  },
                  v3
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca58a96eb6952fa000791f3de5d63cf2';
module.exports = node;
