/**
 * @flow
 * @relayHash 91cdccae08636f5dd37b8f4ca92759c2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateTagsInput = {
  inputs?: ?$ReadOnlyArray<?CreateTagsBulkInput>,
  clientMutationId?: ?string,
};
export type CreateTagsBulkInput = {
  fragment?: ?string,
  annotated_id?: ?string,
  annotated_type?: ?string,
  tag: string,
};
export type BulkActionsTagCreateTagsMutationVariables = {|
  input: CreateTagsInput
|};
export type BulkActionsTagCreateTagsMutationResponse = {|
  +createTags: ?{|
    +check_search_team: ?{|
      +medias: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |}
    |},
    +team: ?{|
      +dbid: ?number,
      +tag_texts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +dbid: ?number,
            +text: ?string,
          |}
        |}>
      |},
    |},
  |}
|};
export type BulkActionsTagCreateTagsMutation = {|
  variables: BulkActionsTagCreateTagsMutationVariables,
  response: BulkActionsTagCreateTagsMutationResponse,
|};
*/


/*
mutation BulkActionsTagCreateTagsMutation(
  $input: CreateTagsInput!
) {
  createTags(input: $input) {
    check_search_team {
      medias(first: 50) {
        edges {
          node {
            id
          }
        }
      }
      id
    }
    team {
      dbid
      tag_texts(first: 100) {
        edges {
          node {
            id
            dbid
            text
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTagsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateTagsInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "medias",
  "storageKey": "medias(first:50)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 50,
      "type": "Int"
    }
  ],
  "concreteType": "ProjectMediaConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ProjectMediaEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ProjectMedia",
          "plural": false,
          "selections": [
            v2
          ]
        }
      ]
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tag_texts",
  "storageKey": "tag_texts(first:100)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100,
      "type": "Int"
    }
  ],
  "concreteType": "TagTextConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TagTextEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "TagText",
          "plural": false,
          "selections": [
            v2,
            v4,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "text",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "BulkActionsTagCreateTagsMutation",
  "id": null,
  "text": "mutation BulkActionsTagCreateTagsMutation(\n  $input: CreateTagsInput!\n) {\n  createTags(input: $input) {\n    check_search_team {\n      medias(first: 50) {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      id\n    }\n    team {\n      dbid\n      tag_texts(first: 100) {\n        edges {\n          node {\n            id\n            dbid\n            text\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BulkActionsTagCreateTagsMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createTags",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateTagsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "check_search_team",
            "storageKey": null,
            "args": null,
            "concreteType": "CheckSearch",
            "plural": false,
            "selections": [
              v3
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4,
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BulkActionsTagCreateTagsMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createTags",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateTagsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "check_search_team",
            "storageKey": null,
            "args": null,
            "concreteType": "CheckSearch",
            "plural": false,
            "selections": [
              v3,
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4,
              v5,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bf40b99db3b0406af1b2761be2dad276';
module.exports = node;
