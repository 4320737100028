/**
 * @flow
 * @relayHash af3e40a68453360318cba132c62dd647
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NewsletterRssFeedQueryVariables = {|
  rssFeedUrl: string,
  numberOfArticles: number,
|};
export type NewsletterRssFeedQueryResponse = {|
  +root: ?{|
    +current_team: ?{|
      +smooch_bot: ?{|
        +smooch_bot_preview_rss_feed: ?string
      |}
    |}
  |}
|};
export type NewsletterRssFeedQuery = {|
  variables: NewsletterRssFeedQueryVariables,
  response: NewsletterRssFeedQueryResponse,
|};
*/


/*
query NewsletterRssFeedQuery(
  $rssFeedUrl: String!
  $numberOfArticles: Int!
) {
  root {
    current_team {
      smooch_bot: team_bot_installation(bot_identifier: "smooch") {
        smooch_bot_preview_rss_feed(rss_feed_url: $rssFeedUrl, number_of_articles: $numberOfArticles)
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rssFeedUrl",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "numberOfArticles",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "bot_identifier",
    "value": "smooch",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "smooch_bot_preview_rss_feed",
  "args": [
    {
      "kind": "Variable",
      "name": "number_of_articles",
      "variableName": "numberOfArticles",
      "type": "Int!"
    },
    {
      "kind": "Variable",
      "name": "rss_feed_url",
      "variableName": "rssFeedUrl",
      "type": "String!"
    }
  ],
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NewsletterRssFeedQuery",
  "id": null,
  "text": "query NewsletterRssFeedQuery(\n  $rssFeedUrl: String!\n  $numberOfArticles: Int!\n) {\n  root {\n    current_team {\n      smooch_bot: team_bot_installation(bot_identifier: \"smooch\") {\n        smooch_bot_preview_rss_feed(rss_feed_url: $rssFeedUrl, number_of_articles: $numberOfArticles)\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewsletterRssFeedQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "root",
        "storageKey": null,
        "args": null,
        "concreteType": "RootLevel",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "smooch_bot",
                "name": "team_bot_installation",
                "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
                "args": v1,
                "concreteType": "TeamBotInstallation",
                "plural": false,
                "selections": [
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewsletterRssFeedQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "root",
        "storageKey": null,
        "args": null,
        "concreteType": "RootLevel",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "smooch_bot",
                "name": "team_bot_installation",
                "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
                "args": v1,
                "concreteType": "TeamBotInstallation",
                "plural": false,
                "selections": [
                  v2,
                  v3
                ]
              },
              v3
            ]
          },
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67628af4a8dd8faf69fe06ecf058c8bd';
module.exports = node;
