/**
 * @flow
 * @relayHash 65ef4771de22501c2136dd42c0ba9228
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SmallMediaCard_media$ref = any;
export type FeedItemMediaListQueryVariables = {|
  currentTeamSlug: string,
  feedDbid: number,
  projectMediaDbid: number,
  itemTeamDbid: number,
|};
export type FeedItemMediaListQueryResponse = {|
  +team: ?{|
    +feed: ?{|
      +cluster: ?{|
        +project_medias: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +dbid: ?number,
              +title: ?string,
              +last_seen: ?string,
              +requests_count: ?number,
              +description: ?string,
              +type: ?string,
              +media: ?{|
                +$fragmentRefs: SmallMediaCard_media$ref
              |},
            |}
          |}>
        |}
      |}
    |}
  |}
|};
export type FeedItemMediaListQuery = {|
  variables: FeedItemMediaListQueryVariables,
  response: FeedItemMediaListQueryResponse,
|};
*/


/*
query FeedItemMediaListQuery(
  $currentTeamSlug: String!
  $feedDbid: Int!
  $projectMediaDbid: Int!
  $itemTeamDbid: Int!
) {
  team(slug: $currentTeamSlug) {
    feed(dbid: $feedDbid) {
      cluster(project_media_id: $projectMediaDbid) {
        project_medias(first: 100, teamId: $itemTeamDbid) {
          edges {
            node {
              dbid
              title
              last_seen
              requests_count
              description
              type
              media {
                ...SmallMediaCard_media
                id
              }
              id
            }
          }
        }
        id
      }
      id
    }
    id
  }
}

fragment SmallMediaCard_media on Media {
  type
  url
  domain
  quote
  picture
  metadata
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "currentTeamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "feedDbid",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "projectMediaDbid",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "itemTeamDbid",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "currentTeamSlug",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "dbid",
    "variableName": "feedDbid",
    "type": "Int!"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "project_media_id",
    "variableName": "projectMediaDbid",
    "type": "Int!"
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "teamId",
    "variableName": "itemTeamDbid",
    "type": "Int"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "last_seen",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requests_count",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "FeedItemMediaListQuery",
  "id": null,
  "text": "query FeedItemMediaListQuery(\n  $currentTeamSlug: String!\n  $feedDbid: Int!\n  $projectMediaDbid: Int!\n  $itemTeamDbid: Int!\n) {\n  team(slug: $currentTeamSlug) {\n    feed(dbid: $feedDbid) {\n      cluster(project_media_id: $projectMediaDbid) {\n        project_medias(first: 100, teamId: $itemTeamDbid) {\n          edges {\n            node {\n              dbid\n              title\n              last_seen\n              requests_count\n              description\n              type\n              media {\n                ...SmallMediaCard_media\n                id\n              }\n              id\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment SmallMediaCard_media on Media {\n  type\n  url\n  domain\n  quote\n  picture\n  metadata\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FeedItemMediaListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": v2,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "cluster",
                "storageKey": null,
                "args": v3,
                "concreteType": "Cluster",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "project_medias",
                    "storageKey": null,
                    "args": v4,
                    "concreteType": "ProjectMediaConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProjectMediaEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProjectMedia",
                            "plural": false,
                            "selections": [
                              v5,
                              v6,
                              v7,
                              v8,
                              v9,
                              v10,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "media",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Media",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "FragmentSpread",
                                    "name": "SmallMediaCard_media",
                                    "args": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FeedItemMediaListQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": v2,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "cluster",
                "storageKey": null,
                "args": v3,
                "concreteType": "Cluster",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "project_medias",
                    "storageKey": null,
                    "args": v4,
                    "concreteType": "ProjectMediaConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProjectMediaEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProjectMedia",
                            "plural": false,
                            "selections": [
                              v5,
                              v6,
                              v7,
                              v8,
                              v9,
                              v10,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "media",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Media",
                                "plural": false,
                                "selections": [
                                  v10,
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "url",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "domain",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "quote",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "picture",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "metadata",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  v11
                                ]
                              },
                              v11
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  v11
                ]
              },
              v11
            ]
          },
          v11
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40da179b28d42fe2c53482c18f980327';
module.exports = node;
