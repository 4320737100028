/**
 * @flow
 * @relayHash 5672d5c9f94f24209026d9de289af3f7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectsCoreListCounterQueryVariables = {|
  query: string
|};
export type ProjectsCoreListCounterQueryResponse = {|
  +search: ?{|
    +number_of_results: ?number
  |}
|};
export type ProjectsCoreListCounterQuery = {|
  variables: ProjectsCoreListCounterQueryVariables,
  response: ProjectsCoreListCounterQueryResponse,
|};
*/


/*
query ProjectsCoreListCounterQuery(
  $query: String!
) {
  search(query: $query) {
    number_of_results
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "number_of_results",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ProjectsCoreListCounterQuery",
  "id": null,
  "text": "query ProjectsCoreListCounterQuery(\n  $query: String!\n) {\n  search(query: $query) {\n    number_of_results\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProjectsCoreListCounterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "search",
        "storageKey": null,
        "args": v1,
        "concreteType": "CheckSearch",
        "plural": false,
        "selections": [
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProjectsCoreListCounterQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "search",
        "storageKey": null,
        "args": v1,
        "concreteType": "CheckSearch",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c1ee2b7b700c668eb2bfff59ea5a6356';
module.exports = node;
