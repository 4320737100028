/**
 * @flow
 * @relayHash fcaaaea6b64aee9470e79a2c91853fc9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRelationshipInput = {
  id?: ?string,
  source_id?: ?number,
  target_id?: ?number,
  relationship_source_type?: ?string,
  relationship_target_type?: ?string,
  clientMutationId?: ?string,
};
export type MediaSuggestionsComponentUpdateRelationshipMutationVariables = {|
  input: UpdateRelationshipInput,
  totalLoaded?: ?number,
|};
export type MediaSuggestionsComponentUpdateRelationshipMutationResponse = {|
  +updateRelationship: ?{|
    +relationship: ?{|
      +relationship_type: ?string
    |},
    +source_project_media: ?{|
      +demand: ?number,
      +requests_count: ?number,
      +hasMain: ?boolean,
      +suggestionsCount: ?number,
      +confirmedSimilarCount: ?number,
      +suggested_similar_relationships: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +target_id: ?number,
          |}
        |}>,
        +totalCount: ?number,
      |},
      +confirmed_similar_relationships: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +dbid: ?number,
            +source_id: ?number,
            +target_id: ?number,
            +target: ?{|
              +id: string,
              +dbid: ?number,
              +title: ?string,
              +picture: ?string,
              +created_at: ?string,
              +type: ?string,
              +requests_count: ?number,
            |},
          |}
        |}>
      |},
    |},
    +target_project_media: ?{|
      +demand: ?number,
      +requests_count: ?number,
      +added_as_similar_by_name: ?string,
      +confirmed_as_similar_by_name: ?string,
      +hasMain: ?boolean,
      +suggestionsCount: ?number,
      +confirmedSimilarCount: ?number,
      +suggested_similar_relationships: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +target_id: ?number,
          |}
        |}>,
        +totalCount: ?number,
      |},
    |},
  |}
|};
export type MediaSuggestionsComponentUpdateRelationshipMutation = {|
  variables: MediaSuggestionsComponentUpdateRelationshipMutationVariables,
  response: MediaSuggestionsComponentUpdateRelationshipMutationResponse,
|};
*/


/*
mutation MediaSuggestionsComponentUpdateRelationshipMutation(
  $input: UpdateRelationshipInput!
  $totalLoaded: Int
) {
  updateRelationship(input: $input) {
    relationship {
      relationship_type
      id
    }
    source_project_media {
      demand
      requests_count
      hasMain: is_confirmed_similar_to_another_item
      suggestionsCount: suggested_similar_items_count
      confirmedSimilarCount: confirmed_similar_items_count
      suggested_similar_relationships(first: $totalLoaded) {
        edges {
          node {
            id
            target_id
          }
        }
        totalCount
      }
      confirmed_similar_relationships(first: 10000) {
        edges {
          node {
            id
            dbid
            source_id
            target_id
            target {
              id
              dbid
              title
              picture
              created_at
              type
              requests_count
            }
          }
        }
      }
      id
    }
    target_project_media {
      demand
      requests_count
      added_as_similar_by_name
      confirmed_as_similar_by_name
      hasMain: is_confirmed_similar_to_another_item
      suggestionsCount: suggested_similar_items_count
      confirmedSimilarCount: confirmed_similar_items_count
      suggested_similar_relationships(first: $totalLoaded) {
        edges {
          node {
            id
            target_id
          }
        }
        totalCount
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateRelationshipInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "totalLoaded",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateRelationshipInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "relationship_type",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "demand",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requests_count",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": "hasMain",
  "name": "is_confirmed_similar_to_another_item",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": "suggestionsCount",
  "name": "suggested_similar_items_count",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": "confirmedSimilarCount",
  "name": "confirmed_similar_items_count",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "target_id",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "suggested_similar_relationships",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "totalLoaded",
      "type": "Int"
    }
  ],
  "concreteType": "RelationshipConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "RelationshipEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Relationship",
          "plural": false,
          "selections": [
            v8,
            v9
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "confirmed_similar_relationships",
  "storageKey": "confirmed_similar_relationships(first:10000)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000,
      "type": "Int"
    }
  ],
  "concreteType": "RelationshipConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "RelationshipEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Relationship",
          "plural": false,
          "selections": [
            v8,
            v11,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "source_id",
              "args": null,
              "storageKey": null
            },
            v9,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "target",
              "storageKey": null,
              "args": null,
              "concreteType": "ProjectMedia",
              "plural": false,
              "selections": [
                v8,
                v11,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "picture",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "created_at",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                v4
              ]
            }
          ]
        }
      ]
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "added_as_similar_by_name",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "confirmed_as_similar_by_name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaSuggestionsComponentUpdateRelationshipMutation",
  "id": null,
  "text": "mutation MediaSuggestionsComponentUpdateRelationshipMutation(\n  $input: UpdateRelationshipInput!\n  $totalLoaded: Int\n) {\n  updateRelationship(input: $input) {\n    relationship {\n      relationship_type\n      id\n    }\n    source_project_media {\n      demand\n      requests_count\n      hasMain: is_confirmed_similar_to_another_item\n      suggestionsCount: suggested_similar_items_count\n      confirmedSimilarCount: confirmed_similar_items_count\n      suggested_similar_relationships(first: $totalLoaded) {\n        edges {\n          node {\n            id\n            target_id\n          }\n        }\n        totalCount\n      }\n      confirmed_similar_relationships(first: 10000) {\n        edges {\n          node {\n            id\n            dbid\n            source_id\n            target_id\n            target {\n              id\n              dbid\n              title\n              picture\n              created_at\n              type\n              requests_count\n            }\n          }\n        }\n      }\n      id\n    }\n    target_project_media {\n      demand\n      requests_count\n      added_as_similar_by_name\n      confirmed_as_similar_by_name\n      hasMain: is_confirmed_similar_to_another_item\n      suggestionsCount: suggested_similar_items_count\n      confirmedSimilarCount: confirmed_similar_items_count\n      suggested_similar_relationships(first: $totalLoaded) {\n        edges {\n          node {\n            id\n            target_id\n          }\n        }\n        totalCount\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSuggestionsComponentUpdateRelationshipMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRelationship",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateRelationshipPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "relationship",
            "storageKey": null,
            "args": null,
            "concreteType": "Relationship",
            "plural": false,
            "selections": [
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              v10,
              v12
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "target_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v3,
              v4,
              v13,
              v14,
              v5,
              v6,
              v7,
              v10
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSuggestionsComponentUpdateRelationshipMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRelationship",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateRelationshipPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "relationship",
            "storageKey": null,
            "args": null,
            "concreteType": "Relationship",
            "plural": false,
            "selections": [
              v2,
              v8
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              v10,
              v12,
              v8
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "target_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v3,
              v4,
              v13,
              v14,
              v5,
              v6,
              v7,
              v10,
              v8
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5331e00a1e40c880071e98f60e02e338';
module.exports = node;
