/**
 * @flow
 * @relayHash 132c515ad6a1a388cc16eb330807f9c3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateExplainerInput = {
  id?: ?string,
  title?: ?string,
  description?: ?string,
  url?: ?string,
  language?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  trashed?: ?boolean,
  clientMutationId?: ?string,
};
export type ImportedArticlesUpdateExplainerMutationVariables = {|
  input: UpdateExplainerInput
|};
export type ImportedArticlesUpdateExplainerMutationResponse = {|
  +updateExplainer: ?{|
    +explainer: ?{|
      +id: string,
      +tags: ?$ReadOnlyArray<?string>,
    |}
  |}
|};
export type ImportedArticlesUpdateExplainerMutation = {|
  variables: ImportedArticlesUpdateExplainerMutationVariables,
  response: ImportedArticlesUpdateExplainerMutationResponse,
|};
*/


/*
mutation ImportedArticlesUpdateExplainerMutation(
  $input: UpdateExplainerInput!
) {
  updateExplainer(input: $input) {
    explainer {
      id
      tags
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateExplainerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateExplainer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateExplainerInput!"
      }
    ],
    "concreteType": "UpdateExplainerPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "explainer",
        "storageKey": null,
        "args": null,
        "concreteType": "Explainer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tags",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ImportedArticlesUpdateExplainerMutation",
  "id": null,
  "text": "mutation ImportedArticlesUpdateExplainerMutation(\n  $input: UpdateExplainerInput!\n) {\n  updateExplainer(input: $input) {\n    explainer {\n      id\n      tags\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ImportedArticlesUpdateExplainerMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ImportedArticlesUpdateExplainerMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e22e9b856f0eed4caa259f007697d024';
module.exports = node;
