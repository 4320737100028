/**
 * @flow
 * @relayHash 7ac36423d215b7da749c10f110aa0661
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyExplainerItemInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type RemoveArticleButtonDeleteExplainerItemMutationVariables = {|
  input: DestroyExplainerItemInput
|};
export type RemoveArticleButtonDeleteExplainerItemMutationResponse = {|
  +destroyExplainerItem: ?{|
    +deletedId: ?string,
    +project_media: ?{|
      +id: string,
      +articles_count: ?number,
    |},
  |}
|};
export type RemoveArticleButtonDeleteExplainerItemMutation = {|
  variables: RemoveArticleButtonDeleteExplainerItemMutationVariables,
  response: RemoveArticleButtonDeleteExplainerItemMutationResponse,
|};
*/


/*
mutation RemoveArticleButtonDeleteExplainerItemMutation(
  $input: DestroyExplainerItemInput!
) {
  destroyExplainerItem(input: $input) {
    deletedId
    project_media {
      id
      articles_count
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyExplainerItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "destroyExplainerItem",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DestroyExplainerItemInput!"
      }
    ],
    "concreteType": "DestroyExplainerItemPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": null,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "articles_count",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "RemoveArticleButtonDeleteExplainerItemMutation",
  "id": null,
  "text": "mutation RemoveArticleButtonDeleteExplainerItemMutation(\n  $input: DestroyExplainerItemInput!\n) {\n  destroyExplainerItem(input: $input) {\n    deletedId\n    project_media {\n      id\n      articles_count\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RemoveArticleButtonDeleteExplainerItemMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "RemoveArticleButtonDeleteExplainerItemMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3233bb70a54db7422095144c3dea3535';
module.exports = node;
