/**
 * @flow
 * @relayHash a249e771683f073976b80aab5bdc70d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateTiplineNewsletterInput = {
  enabled?: ?boolean,
  introduction?: ?string,
  language?: ?string,
  header_type?: ?string,
  header_overlay_text?: ?string,
  content_type?: ?string,
  rss_feed_url?: ?string,
  number_of_articles?: ?number,
  first_article?: ?string,
  second_article?: ?string,
  third_article?: ?string,
  footer?: ?string,
  send_every?: ?any,
  send_on?: ?string,
  timezone?: ?string,
  time?: ?string,
  clientMutationId?: ?string,
};
export type NewsletterComponentCreateMutationVariables = {|
  input: CreateTiplineNewsletterInput
|};
export type NewsletterComponentCreateMutationResponse = {|
  +createTiplineNewsletter: ?{|
    +team: ?{|
      +id: string
    |}
  |}
|};
export type NewsletterComponentCreateMutation = {|
  variables: NewsletterComponentCreateMutationVariables,
  response: NewsletterComponentCreateMutationResponse,
|};
*/


/*
mutation NewsletterComponentCreateMutation(
  $input: CreateTiplineNewsletterInput!
) {
  createTiplineNewsletter(input: $input) {
    team {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTiplineNewsletterInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createTiplineNewsletter",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateTiplineNewsletterInput!"
      }
    ],
    "concreteType": "CreateTiplineNewsletterPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "NewsletterComponentCreateMutation",
  "id": null,
  "text": "mutation NewsletterComponentCreateMutation(\n  $input: CreateTiplineNewsletterInput!\n) {\n  createTiplineNewsletter(input: $input) {\n    team {\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewsletterComponentCreateMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "NewsletterComponentCreateMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0dc0668b8fa6af8d7b6195fafc98ff7f';
module.exports = node;
