/**
 * @flow
 * @relayHash 3b311332ea634a427484060aafd2ed95
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DrawerUserSettingsQueryVariables = {||};
export type DrawerUserSettingsQueryResponse = {|
  +me: ?{|
    +id: string
  |}
|};
export type DrawerUserSettingsQuery = {|
  variables: DrawerUserSettingsQueryVariables,
  response: DrawerUserSettingsQueryResponse,
|};
*/


/*
query DrawerUserSettingsQuery {
  me {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "me",
    "storageKey": null,
    "args": null,
    "concreteType": "Me",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DrawerUserSettingsQuery",
  "id": null,
  "text": "query DrawerUserSettingsQuery {\n  me {\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DrawerUserSettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "DrawerUserSettingsQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8bead9724ddbd9cbbf847908c0f3f993';
module.exports = node;
