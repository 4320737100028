/**
 * @flow
 * @relayHash c1f811a141386f1c8ac80b758d92f996
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRelationshipsInput = {
  ids: $ReadOnlyArray<?string>,
  action: string,
  source_id: number,
  clientMutationId?: ?string,
};
export type MediaSuggestionsComponentUpdateRelationshipsMutationVariables = {|
  input: UpdateRelationshipsInput,
  totalLoaded?: ?number,
|};
export type MediaSuggestionsComponentUpdateRelationshipsMutationResponse = {|
  +updateRelationships: ?{|
    +source_project_media: ?{|
      +demand: ?number,
      +requests_count: ?number,
      +hasMain: ?boolean,
      +suggestionsCount: ?number,
      +confirmedSimilarCount: ?number,
      +suggested_similar_relationships: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +target_id: ?number,
          |}
        |}>,
        +totalCount: ?number,
      |},
      +confirmed_similar_relationships: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +dbid: ?number,
            +source_id: ?number,
            +target_id: ?number,
            +target: ?{|
              +id: string,
              +dbid: ?number,
              +title: ?string,
              +picture: ?string,
              +created_at: ?string,
              +type: ?string,
              +requests_count: ?number,
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type MediaSuggestionsComponentUpdateRelationshipsMutation = {|
  variables: MediaSuggestionsComponentUpdateRelationshipsMutationVariables,
  response: MediaSuggestionsComponentUpdateRelationshipsMutationResponse,
|};
*/


/*
mutation MediaSuggestionsComponentUpdateRelationshipsMutation(
  $input: UpdateRelationshipsInput!
  $totalLoaded: Int
) {
  updateRelationships(input: $input) {
    source_project_media {
      demand
      requests_count
      hasMain: is_confirmed_similar_to_another_item
      suggestionsCount: suggested_similar_items_count
      confirmedSimilarCount: confirmed_similar_items_count
      suggested_similar_relationships(first: $totalLoaded) {
        edges {
          node {
            id
            target_id
          }
        }
        totalCount
      }
      confirmed_similar_relationships(first: 10000) {
        edges {
          node {
            id
            dbid
            source_id
            target_id
            target {
              id
              dbid
              title
              picture
              created_at
              type
              requests_count
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateRelationshipsInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "totalLoaded",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateRelationshipsInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "demand",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requests_count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": "hasMain",
  "name": "is_confirmed_similar_to_another_item",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": "suggestionsCount",
  "name": "suggested_similar_items_count",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": "confirmedSimilarCount",
  "name": "confirmed_similar_items_count",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "target_id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "suggested_similar_relationships",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "totalLoaded",
      "type": "Int"
    }
  ],
  "concreteType": "RelationshipConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "RelationshipEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Relationship",
          "plural": false,
          "selections": [
            v7,
            v8
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "confirmed_similar_relationships",
  "storageKey": "confirmed_similar_relationships(first:10000)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000,
      "type": "Int"
    }
  ],
  "concreteType": "RelationshipConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "RelationshipEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Relationship",
          "plural": false,
          "selections": [
            v7,
            v10,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "source_id",
              "args": null,
              "storageKey": null
            },
            v8,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "target",
              "storageKey": null,
              "args": null,
              "concreteType": "ProjectMedia",
              "plural": false,
              "selections": [
                v7,
                v10,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "picture",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "created_at",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                v3
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaSuggestionsComponentUpdateRelationshipsMutation",
  "id": null,
  "text": "mutation MediaSuggestionsComponentUpdateRelationshipsMutation(\n  $input: UpdateRelationshipsInput!\n  $totalLoaded: Int\n) {\n  updateRelationships(input: $input) {\n    source_project_media {\n      demand\n      requests_count\n      hasMain: is_confirmed_similar_to_another_item\n      suggestionsCount: suggested_similar_items_count\n      confirmedSimilarCount: confirmed_similar_items_count\n      suggested_similar_relationships(first: $totalLoaded) {\n        edges {\n          node {\n            id\n            target_id\n          }\n        }\n        totalCount\n      }\n      confirmed_similar_relationships(first: 10000) {\n        edges {\n          node {\n            id\n            dbid\n            source_id\n            target_id\n            target {\n              id\n              dbid\n              title\n              picture\n              created_at\n              type\n              requests_count\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSuggestionsComponentUpdateRelationshipsMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRelationships",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateRelationshipsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v9,
              v11
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSuggestionsComponentUpdateRelationshipsMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRelationships",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateRelationshipsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v9,
              v11,
              v7
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4205f36b41c610a696a9ca527d1a8bc4';
module.exports = node;
