/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaActionsMenuButton_projectMedia$ref: FragmentReference;
export type MediaActionsMenuButton_projectMedia = {|
  +id: string,
  +permissions: ?string,
  +archived: ?number,
  +media: ?{|
    +url: ?string
  |},
  +last_status_obj: ?{|
    +locked: ?boolean
  |},
  +$refType: MediaActionsMenuButton_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MediaActionsMenuButton_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "archived",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media",
      "storageKey": null,
      "args": null,
      "concreteType": "Media",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "last_status_obj",
      "storageKey": null,
      "args": null,
      "concreteType": "Dynamic",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "locked",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '57ad05a0b945a12c57b0afcd185ad063';
module.exports = node;
