/**
 * @flow
 * @relayHash 786c5d095faf1dacfa86073b73e0ed10
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFeedInput = {
  id?: ?string,
  description?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  saved_search_id?: ?number,
  published?: ?boolean,
  discoverable?: ?boolean,
  name?: ?string,
  clientMutationId?: ?string,
};
export type SaveFeedUpdateFeedMutationVariables = {|
  input: UpdateFeedInput
|};
export type SaveFeedUpdateFeedMutationResponse = {|
  +updateFeed: ?{|
    +feed: ?{|
      +dbid: ?number,
      +saved_search: ?{|
        +is_part_of_feeds: ?boolean
      |},
      +saved_search_was: ?{|
        +is_part_of_feeds: ?boolean
      |},
    |},
    +team: ?{|
      +feeds: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +name: ?string
          |}
        |}>
      |}
    |},
  |}
|};
export type SaveFeedUpdateFeedMutation = {|
  variables: SaveFeedUpdateFeedMutationVariables,
  response: SaveFeedUpdateFeedMutationResponse,
|};
*/


/*
mutation SaveFeedUpdateFeedMutation(
  $input: UpdateFeedInput!
) {
  updateFeed(input: $input) {
    feed {
      dbid
      saved_search {
        is_part_of_feeds
        id
      }
      saved_search_was {
        is_part_of_feeds
        id
      }
      id
    }
    team {
      feeds(first: 10000) {
        edges {
          node {
            name
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFeedInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateFeedInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "is_part_of_feeds",
  "args": null,
  "storageKey": null
},
v4 = [
  v3
],
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = [
  v3,
  v7
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveFeedUpdateFeedMutation",
  "id": null,
  "text": "mutation SaveFeedUpdateFeedMutation(\n  $input: UpdateFeedInput!\n) {\n  updateFeed(input: $input) {\n    feed {\n      dbid\n      saved_search {\n        is_part_of_feeds\n        id\n      }\n      saved_search_was {\n        is_part_of_feeds\n        id\n      }\n      id\n    }\n    team {\n      feeds(first: 10000) {\n        edges {\n          node {\n            name\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveFeedUpdateFeedMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFeed",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateFeedPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": v4
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search_was",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": v4
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feeds",
                "storageKey": "feeds(first:10000)",
                "args": v5,
                "concreteType": "FeedConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Feed",
                        "plural": false,
                        "selections": [
                          v6
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveFeedUpdateFeedMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFeed",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateFeedPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": v8
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search_was",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": v8
              },
              v7
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feeds",
                "storageKey": "feeds(first:10000)",
                "args": v5,
                "concreteType": "FeedConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Feed",
                        "plural": false,
                        "selections": [
                          v6,
                          v7
                        ]
                      }
                    ]
                  }
                ]
              },
              v7
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0a9256f8ad48a9c88e478ae2ea6ea56c';
module.exports = node;
