/**
 * @flow
 * @relayHash f84736f34183f029d5b116d5fbe50891
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ApiKeys_team$ref = any;
export type TeamIntegrationsQueryVariables = {||};
export type TeamIntegrationsQueryResponse = {|
  +root: ?{|
    +current_team: ?{|
      +id: string,
      +dbid: ?number,
      +team_bot_installations: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +json_settings: ?string,
            +lock_version: ?number,
            +bot_user: ?{|
              +id: string
            |},
            +team: ?{|
              +id: string,
              +dbid: ?number,
            |},
            +team_bot: ?{|
              +id: string,
              +dbid: ?number,
            |},
          |}
        |}>
      |},
      +$fragmentRefs: ApiKeys_team$ref,
    |},
    +team_bots_listed: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dbid: ?number,
          +avatar: ?string,
          +name: ?string,
          +default: ?boolean,
          +identifier: ?string,
          +description: ?string,
          +team_author: ?{|
            +name: string,
            +slug: string,
          |},
          +installation: ?{|
            +id: string,
            +json_settings: ?string,
            +team: ?{|
              +id: string,
              +dbid: ?number,
            |},
            +team_bot: ?{|
              +id: string,
              +dbid: ?number,
            |},
          |},
        |}
      |}>
    |},
  |}
|};
export type TeamIntegrationsQuery = {|
  variables: TeamIntegrationsQueryVariables,
  response: TeamIntegrationsQueryResponse,
|};
*/


/*
query TeamIntegrationsQuery {
  root {
    current_team {
      id
      dbid
      ...ApiKeys_team
      team_bot_installations(first: 10000) {
        edges {
          node {
            id
            json_settings
            lock_version
            bot_user {
              id
            }
            team {
              id
              dbid
            }
            team_bot: bot_user {
              id
              dbid
            }
          }
        }
      }
    }
    team_bots_listed(first: 10000) {
      edges {
        node {
          id
          dbid
          avatar
          name
          default
          identifier
          description: get_description
          team_author {
            name
            slug
            id
          }
          installation {
            id
            json_settings
            team {
              id
              dbid
            }
            team_bot: bot_user {
              id
              dbid
            }
          }
        }
      }
    }
    id
  }
}

fragment ApiKeys_team on Team {
  api_keys(first: 10000) {
    edges {
      node {
        dbid
        ...ApiKeyEntry_apiKey
        id
      }
    }
  }
}

fragment ApiKeyEntry_apiKey on ApiKey {
  id
  title
  description
  access_token
  created_at
  expire_at
  user {
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "json_settings",
  "args": null,
  "storageKey": null
},
v4 = [
  v0,
  v1
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team",
  "storageKey": null,
  "args": null,
  "concreteType": "Team",
  "plural": false,
  "selections": v4
},
v6 = {
  "kind": "LinkedField",
  "alias": "team_bot",
  "name": "bot_user",
  "storageKey": null,
  "args": null,
  "concreteType": "BotUser",
  "plural": false,
  "selections": v4
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team_bot_installations",
  "storageKey": "team_bot_installations(first:10000)",
  "args": v2,
  "concreteType": "TeamBotInstallationConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TeamBotInstallationEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "TeamBotInstallation",
          "plural": false,
          "selections": [
            v0,
            v3,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lock_version",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "bot_user",
              "storageKey": null,
              "args": null,
              "concreteType": "BotUser",
              "plural": false,
              "selections": [
                v0
              ]
            },
            v5,
            v6
          ]
        }
      ]
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "default",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "identifier",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": "description",
  "name": "get_description",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "installation",
  "storageKey": null,
  "args": null,
  "concreteType": "TeamBotInstallation",
  "plural": false,
  "selections": [
    v0,
    v3,
    v5,
    v6
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamIntegrationsQuery",
  "id": null,
  "text": "query TeamIntegrationsQuery {\n  root {\n    current_team {\n      id\n      dbid\n      ...ApiKeys_team\n      team_bot_installations(first: 10000) {\n        edges {\n          node {\n            id\n            json_settings\n            lock_version\n            bot_user {\n              id\n            }\n            team {\n              id\n              dbid\n            }\n            team_bot: bot_user {\n              id\n              dbid\n            }\n          }\n        }\n      }\n    }\n    team_bots_listed(first: 10000) {\n      edges {\n        node {\n          id\n          dbid\n          avatar\n          name\n          default\n          identifier\n          description: get_description\n          team_author {\n            name\n            slug\n            id\n          }\n          installation {\n            id\n            json_settings\n            team {\n              id\n              dbid\n            }\n            team_bot: bot_user {\n              id\n              dbid\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ApiKeys_team on Team {\n  api_keys(first: 10000) {\n    edges {\n      node {\n        dbid\n        ...ApiKeyEntry_apiKey\n        id\n      }\n    }\n  }\n}\n\nfragment ApiKeyEntry_apiKey on ApiKey {\n  id\n  title\n  description\n  access_token\n  created_at\n  expire_at\n  user {\n    name\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamIntegrationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "root",
        "storageKey": null,
        "args": null,
        "concreteType": "RootLevel",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v0,
              v1,
              {
                "kind": "FragmentSpread",
                "name": "ApiKeys_team",
                "args": null
              },
              v7
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team_bots_listed",
            "storageKey": "team_bots_listed(first:10000)",
            "args": v2,
            "concreteType": "BotUserConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BotUserEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BotUser",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      v8,
                      v9,
                      v10,
                      v11,
                      v12,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "team_author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PublicTeam",
                        "plural": false,
                        "selections": [
                          v9,
                          v13
                        ]
                      },
                      v14
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamIntegrationsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "root",
        "storageKey": null,
        "args": null,
        "concreteType": "RootLevel",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v0,
              v1,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "api_keys",
                "storageKey": "api_keys(first:10000)",
                "args": v2,
                "concreteType": "ApiKeyConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApiKeyEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApiKey",
                        "plural": false,
                        "selections": [
                          v1,
                          v0,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "description",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "access_token",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "expire_at",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              v9,
                              v0
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              v7
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team_bots_listed",
            "storageKey": "team_bots_listed(first:10000)",
            "args": v2,
            "concreteType": "BotUserConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BotUserEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BotUser",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      v8,
                      v9,
                      v10,
                      v11,
                      v12,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "team_author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PublicTeam",
                        "plural": false,
                        "selections": [
                          v9,
                          v13,
                          v0
                        ]
                      },
                      v14
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '085143c9cc4311de8c3394df61cba433';
module.exports = node;
