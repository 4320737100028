/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchFields_team$ref: FragmentReference;
export type SearchFields_team = {|
  +id: string,
  +dbid: ?number,
  +slug: string,
  +permissions: ?string,
  +verification_statuses: ?any,
  +get_tipline_inbox_filters: ?any,
  +smooch_bot: ?{|
    +id: string
  |},
  +alegre_bot: ?{|
    +id: string,
    +alegre_settings: ?any,
  |},
  +$refType: SearchFields_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SearchFields_team",
  "type": "Team",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dbid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "verification_statuses",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_tipline_inbox_filters",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "smooch_bot",
      "name": "team_bot_installation",
      "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
      "args": [
        {
          "kind": "Literal",
          "name": "bot_identifier",
          "value": "smooch",
          "type": "String!"
        }
      ],
      "concreteType": "TeamBotInstallation",
      "plural": false,
      "selections": [
        v0
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "alegre_bot",
      "name": "team_bot_installation",
      "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
      "args": [
        {
          "kind": "Literal",
          "name": "bot_identifier",
          "value": "alegre",
          "type": "String!"
        }
      ],
      "concreteType": "TeamBotInstallation",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "alegre_settings",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '4bc14287bd7c322d7966eed1bd4ae612';
module.exports = node;
