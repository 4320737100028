/**
 * @flow
 * @relayHash c4bbf714cb7f530c9dbce9726de12a70
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateFeedInvitationInput = {
  email: string,
  feed_id: number,
  clientMutationId?: ?string,
};
export type SaveFeedCreateFeedInvitationMutationVariables = {|
  input: CreateFeedInvitationInput
|};
export type SaveFeedCreateFeedInvitationMutationResponse = {|
  +createFeedInvitation: ?{|
    +feed_invitation: ?{|
      +id: string
    |}
  |}
|};
export type SaveFeedCreateFeedInvitationMutation = {|
  variables: SaveFeedCreateFeedInvitationMutationVariables,
  response: SaveFeedCreateFeedInvitationMutationResponse,
|};
*/


/*
mutation SaveFeedCreateFeedInvitationMutation(
  $input: CreateFeedInvitationInput!
) {
  createFeedInvitation(input: $input) {
    feed_invitation {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateFeedInvitationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createFeedInvitation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateFeedInvitationInput!"
      }
    ],
    "concreteType": "CreateFeedInvitationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feed_invitation",
        "storageKey": null,
        "args": null,
        "concreteType": "FeedInvitation",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveFeedCreateFeedInvitationMutation",
  "id": null,
  "text": "mutation SaveFeedCreateFeedInvitationMutation(\n  $input: CreateFeedInvitationInput!\n) {\n  createFeedInvitation(input: $input) {\n    feed_invitation {\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveFeedCreateFeedInvitationMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveFeedCreateFeedInvitationMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67004a23234c32517b0cf7d8010d197d';
module.exports = node;
