/**
 * @flow
 * @relayHash 60f8e9ed84fa037466195b7bda63ecf7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRelationshipInput = {
  id?: ?string,
  source_id?: ?number,
  target_id?: ?number,
  relationship_source_type?: ?string,
  relationship_target_type?: ?string,
  clientMutationId?: ?string,
};
export type MediaSuggestionBannerUpdateRelationshipMutationVariables = {|
  input: UpdateRelationshipInput
|};
export type MediaSuggestionBannerUpdateRelationshipMutationResponse = {|
  +updateRelationship: ?{|
    +relationship: ?{|
      +id: string
    |}
  |}
|};
export type MediaSuggestionBannerUpdateRelationshipMutation = {|
  variables: MediaSuggestionBannerUpdateRelationshipMutationVariables,
  response: MediaSuggestionBannerUpdateRelationshipMutationResponse,
|};
*/


/*
mutation MediaSuggestionBannerUpdateRelationshipMutation(
  $input: UpdateRelationshipInput!
) {
  updateRelationship(input: $input) {
    relationship {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateRelationshipInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateRelationship",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateRelationshipInput!"
      }
    ],
    "concreteType": "UpdateRelationshipPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "relationship",
        "storageKey": null,
        "args": null,
        "concreteType": "Relationship",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaSuggestionBannerUpdateRelationshipMutation",
  "id": null,
  "text": "mutation MediaSuggestionBannerUpdateRelationshipMutation(\n  $input: UpdateRelationshipInput!\n) {\n  updateRelationship(input: $input) {\n    relationship {\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSuggestionBannerUpdateRelationshipMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSuggestionBannerUpdateRelationshipMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0cf2e12ae8f17d815ccc0338de5a0721';
module.exports = node;
