/**
 * @flow
 * @relayHash 211dd7c102ec0cf84451fc785ebc8ae3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExtractTextInput = {
  id: string,
  clientMutationId?: ?string,
};
export type OcrButtonExtractTextMutationVariables = {|
  input: ExtractTextInput
|};
export type OcrButtonExtractTextMutationResponse = {|
  +extractText: ?{|
    +project_media: ?{|
      +id: string,
      +extracted_text: ?{|
        +data: ?any
      |},
    |}
  |}
|};
export type OcrButtonExtractTextMutation = {|
  variables: OcrButtonExtractTextMutationVariables,
  response: OcrButtonExtractTextMutationResponse,
|};
*/


/*
mutation OcrButtonExtractTextMutation(
  $input: ExtractTextInput!
) {
  extractText(input: $input) {
    project_media {
      id
      extracted_text: annotation(annotation_type: "extracted_text") {
        data
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ExtractTextInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ExtractTextInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "annotation_type",
    "value": "extracted_text",
    "type": "String!"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "data",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "OcrButtonExtractTextMutation",
  "id": null,
  "text": "mutation OcrButtonExtractTextMutation(\n  $input: ExtractTextInput!\n) {\n  extractText(input: $input) {\n    project_media {\n      id\n      extracted_text: annotation(annotation_type: \"extracted_text\") {\n        data\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OcrButtonExtractTextMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "extractText",
        "storageKey": null,
        "args": v1,
        "concreteType": "ExtractTextPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": "extracted_text",
                "name": "annotation",
                "storageKey": "annotation(annotation_type:\"extracted_text\")",
                "args": v3,
                "concreteType": "Annotation",
                "plural": false,
                "selections": [
                  v4
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OcrButtonExtractTextMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "extractText",
        "storageKey": null,
        "args": v1,
        "concreteType": "ExtractTextPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": "extracted_text",
                "name": "annotation",
                "storageKey": "annotation(annotation_type:\"extracted_text\")",
                "args": v3,
                "concreteType": "Annotation",
                "plural": false,
                "selections": [
                  v4,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c935c6f5dc58ccfd3d083c2784dfe93f';
module.exports = node;
