/**
 * @flow
 * @relayHash a7cd15d4798ce99a2846fbec432e9f40
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UploadFileQueryVariables = {||};
export type UploadFileQueryResponse = {|
  +about: ?{|
    +upload_max_size: ?string,
    +upload_extensions: ?$ReadOnlyArray<?string>,
    +video_max_size: ?string,
    +video_extensions: ?$ReadOnlyArray<?string>,
    +audio_max_size: ?string,
    +audio_extensions: ?$ReadOnlyArray<?string>,
    +file_max_size: ?string,
    +file_extensions: ?$ReadOnlyArray<?string>,
    +upload_max_dimensions: ?string,
    +upload_min_dimensions: ?string,
  |}
|};
export type UploadFileQuery = {|
  variables: UploadFileQueryVariables,
  response: UploadFileQueryResponse,
|};
*/


/*
query UploadFileQuery {
  about {
    upload_max_size
    upload_extensions
    video_max_size
    video_extensions
    audio_max_size
    audio_extensions
    file_max_size
    file_extensions
    upload_max_dimensions
    upload_min_dimensions
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upload_max_size",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upload_extensions",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "video_max_size",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "video_extensions",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "audio_max_size",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "audio_extensions",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "file_max_size",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "file_extensions",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upload_max_dimensions",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upload_min_dimensions",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UploadFileQuery",
  "id": null,
  "text": "query UploadFileQuery {\n  about {\n    upload_max_size\n    upload_extensions\n    video_max_size\n    video_extensions\n    audio_max_size\n    audio_extensions\n    file_max_size\n    file_extensions\n    upload_max_dimensions\n    upload_min_dimensions\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UploadFileQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "about",
        "storageKey": null,
        "args": null,
        "concreteType": "About",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v9
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UploadFileQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "about",
        "storageKey": null,
        "args": null,
        "concreteType": "About",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v9,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '017176351eb15ecb05bf64d611d5418a';
module.exports = node;
