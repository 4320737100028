/**
 * @flow
 * @relayHash 2affdc0a04f44268d0deee17a349f8b2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type LanguagesComponent_team$ref = any;
export type LanguagesQueryVariables = {|
  teamSlug: string
|};
export type LanguagesQueryResponse = {|
  +team: ?{|
    +id: string,
    +$fragmentRefs: LanguagesComponent_team$ref,
  |}
|};
export type LanguagesQuery = {|
  variables: LanguagesQueryVariables,
  response: LanguagesQueryResponse,
|};
*/


/*
query LanguagesQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    id
    ...LanguagesComponent_team
  }
}

fragment LanguagesComponent_team on Team {
  id
  get_language
  get_languages
  get_language_detection
  ...LanguageListItem_team
  ...AddLanguageAction_team
}

fragment LanguageListItem_team on Team {
  id
  get_language
  get_languages
  verification_statuses
}

fragment AddLanguageAction_team on Team {
  id
  get_languages
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "LanguagesQuery",
  "id": null,
  "text": "query LanguagesQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    id\n    ...LanguagesComponent_team\n  }\n}\n\nfragment LanguagesComponent_team on Team {\n  id\n  get_language\n  get_languages\n  get_language_detection\n  ...LanguageListItem_team\n  ...AddLanguageAction_team\n}\n\nfragment LanguageListItem_team on Team {\n  id\n  get_language\n  get_languages\n  verification_statuses\n}\n\nfragment AddLanguageAction_team on Team {\n  id\n  get_languages\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LanguagesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "FragmentSpread",
            "name": "LanguagesComponent_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LanguagesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_language",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_languages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_language_detection",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verification_statuses",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '23cb3fa39c25886fc52a5a46d80eba4a';
module.exports = node;
