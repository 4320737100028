/**
 * @flow
 * @relayHash a1c6b9f4c1ed25f055590630a8a275b1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProjectMediaInput = {
  id?: ?string,
  media_id?: ?number,
  related_to_id?: ?number,
  refresh_media?: ?number,
  archived?: ?number,
  previous_project_id?: ?number,
  project_id?: ?number,
  source_id?: ?number,
  read?: ?boolean,
  custom_title?: ?string,
  title_field?: ?string,
  clientMutationId?: ?string,
};
export type ItemTitleUpdateProjectMediaMutationVariables = {|
  input: UpdateProjectMediaInput
|};
export type ItemTitleUpdateProjectMediaMutationResponse = {|
  +updateProjectMedia: ?{|
    +project_media: ?{|
      +id: string,
      +title: ?string,
      +title_field: ?string,
      +custom_title: ?string,
    |}
  |}
|};
export type ItemTitleUpdateProjectMediaMutation = {|
  variables: ItemTitleUpdateProjectMediaMutationVariables,
  response: ItemTitleUpdateProjectMediaMutationResponse,
|};
*/


/*
mutation ItemTitleUpdateProjectMediaMutation(
  $input: UpdateProjectMediaInput!
) {
  updateProjectMedia(input: $input) {
    project_media {
      id
      title
      title_field
      custom_title
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProjectMediaInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateProjectMedia",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateProjectMediaInput!"
      }
    ],
    "concreteType": "UpdateProjectMediaPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": null,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title_field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "custom_title",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ItemTitleUpdateProjectMediaMutation",
  "id": null,
  "text": "mutation ItemTitleUpdateProjectMediaMutation(\n  $input: UpdateProjectMediaInput!\n) {\n  updateProjectMedia(input: $input) {\n    project_media {\n      id\n      title\n      title_field\n      custom_title\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ItemTitleUpdateProjectMediaMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ItemTitleUpdateProjectMediaMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e79ec832f55841042a4d6860ee0645ed';
module.exports = node;
