/**
 * @flow
 * @relayHash 15e1538db1a10501126ac7340df469a5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyRelationshipsInput = {
  ids: $ReadOnlyArray<?string>,
  source_id: number,
  clientMutationId?: ?string,
};
export type MediaSuggestionsComponentDestroyRelationshipsMutationVariables = {|
  input: DestroyRelationshipsInput,
  rejection: boolean,
  totalLoaded?: ?number,
|};
export type MediaSuggestionsComponentDestroyRelationshipsMutationResponse = {|
  +destroyRelationships: ?{|
    +ids: ?$ReadOnlyArray<?string>,
    +source_project_media?: ?{|
      +hasMain: ?boolean,
      +suggestionsCount: ?number,
      +confirmedSimilarCount: ?number,
      +suggested_similar_relationships: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +target_id: ?number,
          |}
        |}>,
        +totalCount: ?number,
      |},
    |},
  |}
|};
export type MediaSuggestionsComponentDestroyRelationshipsMutation = {|
  variables: MediaSuggestionsComponentDestroyRelationshipsMutationVariables,
  response: MediaSuggestionsComponentDestroyRelationshipsMutationResponse,
|};
*/


/*
mutation MediaSuggestionsComponentDestroyRelationshipsMutation(
  $input: DestroyRelationshipsInput!
  $rejection: Boolean!
  $totalLoaded: Int
) {
  destroyRelationships(input: $input) {
    ids
    source_project_media @include(if: $rejection) {
      hasMain: is_confirmed_similar_to_another_item
      suggestionsCount: suggested_similar_items_count
      confirmedSimilarCount: confirmed_similar_items_count
      suggested_similar_relationships(first: $totalLoaded) {
        edges {
          node {
            id
            target_id
          }
        }
        totalCount
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyRelationshipsInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "rejection",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "totalLoaded",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DestroyRelationshipsInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ids",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "hasMain",
  "name": "is_confirmed_similar_to_another_item",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": "suggestionsCount",
  "name": "suggested_similar_items_count",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": "confirmedSimilarCount",
  "name": "confirmed_similar_items_count",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "suggested_similar_relationships",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "totalLoaded",
      "type": "Int"
    }
  ],
  "concreteType": "RelationshipConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "RelationshipEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Relationship",
          "plural": false,
          "selections": [
            v6,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "target_id",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaSuggestionsComponentDestroyRelationshipsMutation",
  "id": null,
  "text": "mutation MediaSuggestionsComponentDestroyRelationshipsMutation(\n  $input: DestroyRelationshipsInput!\n  $rejection: Boolean!\n  $totalLoaded: Int\n) {\n  destroyRelationships(input: $input) {\n    ids\n    source_project_media @include(if: $rejection) {\n      hasMain: is_confirmed_similar_to_another_item\n      suggestionsCount: suggested_similar_items_count\n      confirmedSimilarCount: confirmed_similar_items_count\n      suggested_similar_relationships(first: $totalLoaded) {\n        edges {\n          node {\n            id\n            target_id\n          }\n        }\n        totalCount\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSuggestionsComponentDestroyRelationshipsMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyRelationships",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyRelationshipsPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "rejection",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "source_project_media",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v7
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSuggestionsComponentDestroyRelationshipsMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyRelationships",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyRelationshipsPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "rejection",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "source_project_media",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v7,
                  v6
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '527378c292cf9fc93c0237706054c343';
module.exports = node;
