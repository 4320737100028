/**
 * @flow
 * @relayHash 30afdf6c2f06759ce56cec0d6f84a80e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyRelationshipInput = {
  id?: ?string,
  archive_target?: ?number,
  clientMutationId?: ?string,
};
export type MediaSuggestionBannerDestroyRelationshipMutationVariables = {|
  input: DestroyRelationshipInput
|};
export type MediaSuggestionBannerDestroyRelationshipMutationResponse = {|
  +destroyRelationship: ?{|
    +deletedId: ?string
  |}
|};
export type MediaSuggestionBannerDestroyRelationshipMutation = {|
  variables: MediaSuggestionBannerDestroyRelationshipMutationVariables,
  response: MediaSuggestionBannerDestroyRelationshipMutationResponse,
|};
*/


/*
mutation MediaSuggestionBannerDestroyRelationshipMutation(
  $input: DestroyRelationshipInput!
) {
  destroyRelationship(input: $input) {
    deletedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyRelationshipInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "destroyRelationship",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DestroyRelationshipInput!"
      }
    ],
    "concreteType": "DestroyRelationshipPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaSuggestionBannerDestroyRelationshipMutation",
  "id": null,
  "text": "mutation MediaSuggestionBannerDestroyRelationshipMutation(\n  $input: DestroyRelationshipInput!\n) {\n  destroyRelationship(input: $input) {\n    deletedId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSuggestionBannerDestroyRelationshipMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSuggestionBannerDestroyRelationshipMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4638cfe072da038fb02645ede1509ea6';
module.exports = node;
