/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AspectRatio_projectMedia$ref = any;
type MediaCardLargeFooter_projectMedia$ref = any;
type WebPageMediaCard_projectMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaCardLarge_projectMedia$ref: FragmentReference;
export type MediaCardLarge_projectMedia = {|
  +id: string,
  +team: ?{|
    +id: string,
    +dbid: ?number,
    +slug: string,
    +verification_statuses: ?any,
  |},
  +media: ?{|
    +type: ?string,
    +url: ?string,
    +quote: ?string,
    +domain: ?string,
    +metadata: ?any,
    +embed_path: ?string,
    +file_path: ?string,
    +thumbnail_path: ?string,
  |},
  +$fragmentRefs: AspectRatio_projectMedia$ref & WebPageMediaCard_projectMedia$ref & MediaCardLargeFooter_projectMedia$ref,
  +$refType: MediaCardLarge_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MediaCardLarge_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "dbid",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "slug",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "verification_statuses",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media",
      "storageKey": null,
      "args": null,
      "concreteType": "Media",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quote",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "domain",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "metadata",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "embed_path",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "file_path",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "thumbnail_path",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "AspectRatio_projectMedia",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "WebPageMediaCard_projectMedia",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaCardLargeFooter_projectMedia",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6fc28b39e7f7c4ee1447cd7e197ab56e';
module.exports = node;
