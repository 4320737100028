/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type FeedActions_feedTeam$ref = any;
type FeedCollaboration_feed$ref = any;
type FeedMetadata_feed$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SaveFeed_feedTeam$ref: FragmentReference;
export type SaveFeed_feedTeam = {|
  +id: string,
  +saved_search_id: ?number,
  +team_id: ?number,
  +permissions: ?string,
  +team: ?{|
    +slug: string,
    +name: string,
  |},
  +feed: ?{|
    +id: string,
    +dbid: ?number,
    +name: ?string,
    +description: ?string,
    +licenses: ?$ReadOnlyArray<?number>,
    +team: ?{|
      +dbid: ?number,
      +name: string,
      +slug: string,
    |},
    +saved_search_id: ?number,
    +data_points: ?$ReadOnlyArray<?number>,
    +$fragmentRefs: FeedCollaboration_feed$ref & FeedMetadata_feed$ref,
  |},
  +$fragmentRefs: FeedActions_feedTeam$ref,
  +$refType: SaveFeed_feedTeam$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "saved_search_id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SaveFeed_feedTeam",
  "type": "FeedTeam",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "team_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "PublicTeam",
      "plural": false,
      "selections": [
        v2,
        v3
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "feed",
      "storageKey": null,
      "args": null,
      "concreteType": "Feed",
      "plural": false,
      "selections": [
        v0,
        v4,
        v3,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "licenses",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "team",
          "storageKey": null,
          "args": null,
          "concreteType": "PublicTeam",
          "plural": false,
          "selections": [
            v4,
            v3,
            v2
          ]
        },
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "data_points",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "FeedCollaboration_feed",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "FeedMetadata_feed",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "FeedActions_feedTeam",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d935622630ae8c15ac0bdc9c8ab37ad';
module.exports = node;
