/**
 * @flow
 * @relayHash da7e5ce7bc6394ec72ed35d4ba000238
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomFiltersManagerQueryVariables = {|
  teamSlug: string,
  random: string,
|};
export type CustomFiltersManagerQueryResponse = {|
  +team: ?{|
    +id: string,
    +team_tasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dbid: ?number,
          +label: ?string,
          +options: ?any,
          +type: ?string,
        |}
      |}>
    |},
  |}
|};
export type CustomFiltersManagerQuery = {|
  variables: CustomFiltersManagerQueryVariables,
  response: CustomFiltersManagerQueryResponse,
|};
*/


/*
query CustomFiltersManagerQuery(
  $teamSlug: String!
  $random: String!
) {
  team(slug: $teamSlug, random: $random) {
    id
    team_tasks(first: 10000) {
      edges {
        node {
          id
          dbid
          label
          options
          type
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "random",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "team",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "random",
        "variableName": "random",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "teamSlug",
        "type": "String"
      }
    ],
    "concreteType": "Team",
    "plural": false,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team_tasks",
        "storageKey": "team_tasks(first:10000)",
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 10000,
            "type": "Int"
          }
        ],
        "concreteType": "TeamTaskConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamTaskEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamTask",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dbid",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "label",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "options",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CustomFiltersManagerQuery",
  "id": null,
  "text": "query CustomFiltersManagerQuery(\n  $teamSlug: String!\n  $random: String!\n) {\n  team(slug: $teamSlug, random: $random) {\n    id\n    team_tasks(first: 10000) {\n      edges {\n        node {\n          id\n          dbid\n          label\n          options\n          type\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CustomFiltersManagerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "CustomFiltersManagerQuery",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd532f801c42f9f38e22f0b5e409954cd';
module.exports = node;
