/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TeamDetails_team$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeamComponent_team$ref: FragmentReference;
export type TeamComponent_team = {|
  +id: string,
  +dbid: ?number,
  +name: string,
  +slug: string,
  +permissions: ?string,
  +alegre_bot: ?{|
    +id: string
  |},
  +smooch_bot: ?{|
    +id: string
  |},
  +$fragmentRefs: TeamDetails_team$ref,
  +$refType: TeamComponent_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
];
return {
  "kind": "Fragment",
  "name": "TeamComponent_team",
  "type": "Team",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dbid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TeamDetails_team",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": "alegre_bot",
      "name": "team_bot_installation",
      "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
      "args": [
        {
          "kind": "Literal",
          "name": "bot_identifier",
          "value": "alegre",
          "type": "String!"
        }
      ],
      "concreteType": "TeamBotInstallation",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": "smooch_bot",
      "name": "team_bot_installation",
      "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
      "args": [
        {
          "kind": "Literal",
          "name": "bot_identifier",
          "value": "smooch",
          "type": "String!"
        }
      ],
      "concreteType": "TeamBotInstallation",
      "plural": false,
      "selections": v1
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa2a3faa86207a8bc5bc24db68a4764a';
module.exports = node;
