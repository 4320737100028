/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ArticleTrash_article$ref: FragmentReference;
export type ArticleTrash_article = {|
  +id?: string,
  +trashed?: ?boolean,
  +claim_description?: ?{|
    +project_media: ?{|
      +title: ?string,
      +full_url: ?string,
      +type: ?string,
    |}
  |},
  +$refType: ArticleTrash_article$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "trashed",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ArticleTrash_article",
  "type": "Node",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "InlineFragment",
      "type": "FactCheck",
      "selections": [
        v0,
        v1,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "claim_description",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimDescription",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "project_media",
              "storageKey": null,
              "args": null,
              "concreteType": "ProjectMedia",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "full_url",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "Explainer",
      "selections": [
        v0,
        v1
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3020fa3a5ec6d83f36e09c6790242b3c';
module.exports = node;
