/**
 * @flow
 * @relayHash ae868c7ff8d2b4ce7d8a11dcc6b5c974
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteStatusDialogQueryVariables = {|
  teamSlug: string,
  status: string,
|};
export type DeleteStatusDialogQueryResponse = {|
  +team: ?{|
    +id: string,
    +verification_statuses_with_counters: ?any,
  |}
|};
export type DeleteStatusDialogQuery = {|
  variables: DeleteStatusDialogQueryVariables,
  response: DeleteStatusDialogQueryResponse,
|};
*/


/*
query DeleteStatusDialogQuery(
  $teamSlug: String!
  $status: String!
) {
  team(slug: $teamSlug) {
    id
    verification_statuses_with_counters: verification_statuses(items_count_for_status: $status, published_reports_count_for_status: $status)
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "team",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "teamSlug",
        "type": "String"
      }
    ],
    "concreteType": "Team",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": "verification_statuses_with_counters",
        "name": "verification_statuses",
        "args": [
          {
            "kind": "Variable",
            "name": "items_count_for_status",
            "variableName": "status",
            "type": "String"
          },
          {
            "kind": "Variable",
            "name": "published_reports_count_for_status",
            "variableName": "status",
            "type": "String"
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DeleteStatusDialogQuery",
  "id": null,
  "text": "query DeleteStatusDialogQuery(\n  $teamSlug: String!\n  $status: String!\n) {\n  team(slug: $teamSlug) {\n    id\n    verification_statuses_with_counters: verification_statuses(items_count_for_status: $status, published_reports_count_for_status: $status)\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteStatusDialogQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteStatusDialogQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '02b96f1ffe8589eecf7a64fc297dc8fb';
module.exports = node;
