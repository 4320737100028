/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaLanguageSwitcher_projectMedia$ref: FragmentReference;
export type MediaLanguageSwitcher_projectMedia = {|
  +language_code: ?string,
  +team: ?{|
    +get_languages: ?string
  |},
  +language: ?{|
    +id: string
  |},
  +$refType: MediaLanguageSwitcher_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MediaLanguageSwitcher_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "language_code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "get_languages",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "language",
      "name": "annotation",
      "storageKey": "annotation(annotation_type:\"language\")",
      "args": [
        {
          "kind": "Literal",
          "name": "annotation_type",
          "value": "language",
          "type": "String!"
        }
      ],
      "concreteType": "Annotation",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '63059b2801088cf29b9b9ee6ca8db3b3';
module.exports = node;
