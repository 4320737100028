/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ClaimFactCheckForm_projectMedia$ref = any;
type ExplainerForm_projectMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type NewArticleButton_projectMedia$ref: FragmentReference;
export type NewArticleButton_projectMedia = {|
  +claim_description: ?{|
    +id: string
  |},
  +$fragmentRefs: ClaimFactCheckForm_projectMedia$ref & ExplainerForm_projectMedia$ref,
  +$refType: NewArticleButton_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "NewArticleButton_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "claim_description",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimDescription",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ClaimFactCheckForm_projectMedia",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExplainerForm_projectMedia",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '86b6412d7e18ae15c45808e265943b8b';
module.exports = node;
