/**
 * @flow
 * @relayHash 316380fbf3370741620cfb36be22b10a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DrawerTeamSettingsQueryVariables = {|
  teamSlug: string
|};
export type DrawerTeamSettingsQueryResponse = {|
  +team: ?{|
    +slug: string,
    +permissions: ?string,
    +alegre_bot: ?{|
      +id: string
    |},
    +smooch_bot: ?{|
      +id: string
    |},
  |}
|};
export type DrawerTeamSettingsQuery = {|
  variables: DrawerTeamSettingsQueryVariables,
  response: DrawerTeamSettingsQueryResponse,
|};
*/


/*
query DrawerTeamSettingsQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    slug
    permissions
    alegre_bot: team_bot_installation(bot_identifier: "alegre") {
      id
    }
    smooch_bot: team_bot_installation(bot_identifier: "smooch") {
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  v4
],
v6 = {
  "kind": "LinkedField",
  "alias": "alegre_bot",
  "name": "team_bot_installation",
  "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
  "args": [
    {
      "kind": "Literal",
      "name": "bot_identifier",
      "value": "alegre",
      "type": "String!"
    }
  ],
  "concreteType": "TeamBotInstallation",
  "plural": false,
  "selections": v5
},
v7 = {
  "kind": "LinkedField",
  "alias": "smooch_bot",
  "name": "team_bot_installation",
  "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
  "args": [
    {
      "kind": "Literal",
      "name": "bot_identifier",
      "value": "smooch",
      "type": "String!"
    }
  ],
  "concreteType": "TeamBotInstallation",
  "plural": false,
  "selections": v5
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DrawerTeamSettingsQuery",
  "id": null,
  "text": "query DrawerTeamSettingsQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    slug\n    permissions\n    alegre_bot: team_bot_installation(bot_identifier: \"alegre\") {\n      id\n    }\n    smooch_bot: team_bot_installation(bot_identifier: \"smooch\") {\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DrawerTeamSettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v6,
          v7
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DrawerTeamSettingsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v6,
          v7,
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c2d18dd2e8a66e67cae5f5d028a7d9a4';
module.exports = node;
