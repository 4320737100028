/**
 * @flow
 * @relayHash 42ee1f6e50b59f39b26431925fb8db82
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InviteNewAccountQueryVariables = {|
  teamSlug: string
|};
export type InviteNewAccountQueryResponse = {|
  +me: ?{|
    +id: string,
    +dbid: ?number,
    +name: ?string,
    +email: ?string,
    +accepted_terms: ?boolean,
    +team_user: ?{|
      +team: ?{|
        +dbid: ?number,
        +name: string,
      |},
      +invited_by: ?{|
        +name: ?string
      |},
    |},
  |}
|};
export type InviteNewAccountQuery = {|
  variables: InviteNewAccountQueryVariables,
  response: InviteNewAccountQueryResponse,
|};
*/


/*
query InviteNewAccountQuery(
  $teamSlug: String!
) {
  me {
    id
    dbid
    name
    email
    accepted_terms
    team_user(team_slug: $teamSlug) {
      team {
        dbid
        name
        id
      }
      invited_by {
        name
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accepted_terms",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "team_slug",
    "variableName": "teamSlug",
    "type": "String!"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "InviteNewAccountQuery",
  "id": null,
  "text": "query InviteNewAccountQuery(\n  $teamSlug: String!\n) {\n  me {\n    id\n    dbid\n    name\n    email\n    accepted_terms\n    team_user(team_slug: $teamSlug) {\n      team {\n        dbid\n        name\n        id\n      }\n      invited_by {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "InviteNewAccountQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team_user",
            "storageKey": null,
            "args": v6,
            "concreteType": "TeamUser",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  v2,
                  v3
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "invited_by",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v3
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "InviteNewAccountQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team_user",
            "storageKey": null,
            "args": v6,
            "concreteType": "TeamUser",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v1
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "invited_by",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v3,
                  v1
                ]
              },
              v1
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0cd452665e1e51686d09718b447e249d';
module.exports = node;
