/**
 * @flow
 * @relayHash e2f8581a1e97d9c8221e2d5c93edc114
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserTwoFactorAuthenticationInput = {
  id: number,
  password: string,
  qrcode?: ?string,
  otp_required?: ?boolean,
  clientMutationId?: ?string,
};
export type UserSecurityUserTwoFactorAuthenticationMutationVariables = {|
  input: UserTwoFactorAuthenticationInput
|};
export type UserSecurityUserTwoFactorAuthenticationMutationResponse = {|
  +userTwoFactorAuthentication: ?{|
    +success: ?boolean,
    +user: ?{|
      +two_factor: ?any
    |},
  |}
|};
export type UserSecurityUserTwoFactorAuthenticationMutation = {|
  variables: UserSecurityUserTwoFactorAuthenticationMutationVariables,
  response: UserSecurityUserTwoFactorAuthenticationMutationResponse,
|};
*/


/*
mutation UserSecurityUserTwoFactorAuthenticationMutation(
  $input: UserTwoFactorAuthenticationInput!
) {
  userTwoFactorAuthentication(input: $input) {
    success
    user {
      two_factor
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserTwoFactorAuthenticationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UserTwoFactorAuthenticationInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "two_factor",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UserSecurityUserTwoFactorAuthenticationMutation",
  "id": null,
  "text": "mutation UserSecurityUserTwoFactorAuthenticationMutation(\n  $input: UserTwoFactorAuthenticationInput!\n) {\n  userTwoFactorAuthentication(input: $input) {\n    success\n    user {\n      two_factor\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserSecurityUserTwoFactorAuthenticationMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userTwoFactorAuthentication",
        "storageKey": null,
        "args": v1,
        "concreteType": "UserTwoFactorAuthenticationPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSecurityUserTwoFactorAuthenticationMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userTwoFactorAuthentication",
        "storageKey": null,
        "args": v1,
        "concreteType": "UserTwoFactorAuthenticationPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '35c63c5df32467a1a3a7f0b998af33d9';
module.exports = node;
