/**
 * @flow
 * @relayHash 0be49370f2c373faf5f90d12ee0e3953
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamComponent_team$ref = any;
export type TeamQueryVariables = {|
  teamSlug: string
|};
export type TeamQueryResponse = {|
  +team: ?{|
    +$fragmentRefs: TeamComponent_team$ref
  |}
|};
export type TeamQuery = {|
  variables: TeamQueryVariables,
  response: TeamQueryResponse,
|};
*/


/*
query TeamQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    ...TeamComponent_team
    id
  }
}

fragment TeamComponent_team on Team {
  id
  dbid
  name
  slug
  permissions
  ...TeamDetails_team
  alegre_bot: team_bot_installation(bot_identifier: "alegre") {
    id
  }
  smooch_bot: team_bot_installation(bot_identifier: "smooch") {
    id
  }
}

fragment TeamDetails_team on Team {
  id
  public_team_id
  name
  avatar
  permissions
  get_shorten_outgoing_urls
  get_outgoing_urls_utm_code
  tipline_newsletters(first: 10000) {
    edges {
      node {
        content_type
        enabled
        id
      }
    }
  }
  ...CreateTeamDialog_team
}

fragment CreateTeamDialog_team on Team {
  id
  name
  slug
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamQuery",
  "id": null,
  "text": "query TeamQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    ...TeamComponent_team\n    id\n  }\n}\n\nfragment TeamComponent_team on Team {\n  id\n  dbid\n  name\n  slug\n  permissions\n  ...TeamDetails_team\n  alegre_bot: team_bot_installation(bot_identifier: \"alegre\") {\n    id\n  }\n  smooch_bot: team_bot_installation(bot_identifier: \"smooch\") {\n    id\n  }\n}\n\nfragment TeamDetails_team on Team {\n  id\n  public_team_id\n  name\n  avatar\n  permissions\n  get_shorten_outgoing_urls\n  get_outgoing_urls_utm_code\n  tipline_newsletters(first: 10000) {\n    edges {\n      node {\n        content_type\n        enabled\n        id\n      }\n    }\n  }\n  ...CreateTeamDialog_team\n}\n\nfragment CreateTeamDialog_team on Team {\n  id\n  name\n  slug\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TeamComponent_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dbid",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "permissions",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "public_team_id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avatar",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_shorten_outgoing_urls",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_outgoing_urls_utm_code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tipline_newsletters",
            "storageKey": "tipline_newsletters(first:10000)",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000,
                "type": "Int"
              }
            ],
            "concreteType": "TiplineNewsletterConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TiplineNewsletterEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TiplineNewsletter",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "content_type",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "enabled",
                        "args": null,
                        "storageKey": null
                      },
                      v2
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "alegre_bot",
            "name": "team_bot_installation",
            "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
            "args": [
              {
                "kind": "Literal",
                "name": "bot_identifier",
                "value": "alegre",
                "type": "String!"
              }
            ],
            "concreteType": "TeamBotInstallation",
            "plural": false,
            "selections": v3
          },
          {
            "kind": "LinkedField",
            "alias": "smooch_bot",
            "name": "team_bot_installation",
            "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
            "args": [
              {
                "kind": "Literal",
                "name": "bot_identifier",
                "value": "smooch",
                "type": "String!"
              }
            ],
            "concreteType": "TeamBotInstallation",
            "plural": false,
            "selections": v3
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf77509037e5da4f8522e8296331d426';
module.exports = node;
