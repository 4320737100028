/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SourceInfo_source$ref: FragmentReference;
export type SourceInfo_source = {|
  +id: string,
  +dbid: ?number,
  +image: ?string,
  +name: string,
  +pusher_channel: ?string,
  +medias_count: ?number,
  +permissions: ?string,
  +updated_at: ?string,
  +archived: ?number,
  +account_sources: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +permissions: ?string,
        +account: ?{|
          +id: string,
          +url: string,
        |},
      |}
    |}>
  |},
  +source_metadata: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +dbid: ?string,
        +show_in_browser_extension: ?boolean,
        +label: ?string,
        +type: ?string,
        +annotated_type: ?string,
        +description: ?string,
        +fieldset: ?string,
        +permissions: ?string,
        +jsonoptions: ?string,
        +json_schema: ?string,
        +options: ?any,
        +team_task: ?{|
          +conditional_info: ?string
        |},
        +team_task_id: ?number,
        +responses: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +dbid: ?string,
              +permissions: ?string,
              +content: ?string,
              +file_data: ?any,
              +attribution: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +dbid: ?number,
                    +name: ?string,
                    +source: ?{|
                      +id: string,
                      +dbid: ?number,
                      +image: ?string,
                    |},
                  |}
                |}>
              |},
              +annotator: ?{|
                +name: ?string,
                +profile_image: ?string,
                +user: ?{|
                  +id: string,
                  +dbid: ?number,
                  +name: ?string,
                  +is_active: ?boolean,
                  +source: ?{|
                    +id: string,
                    +dbid: ?number,
                    +image: ?string,
                  |},
                |},
              |},
            |}
          |}>
        |},
        +assignments: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +name: ?string,
              +id: string,
              +dbid: ?number,
              +source: ?{|
                +id: string,
                +dbid: ?number,
                +image: ?string,
              |},
            |}
          |}>
        |},
        +first_response_value: ?string,
        +first_response: ?{|
          +id: string,
          +dbid: ?string,
          +permissions: ?string,
          +content: ?string,
          +file_data: ?any,
          +attribution: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +dbid: ?number,
                +name: ?string,
                +source: ?{|
                  +id: string,
                  +dbid: ?number,
                  +image: ?string,
                |},
              |}
            |}>
          |},
          +annotator: ?{|
            +name: ?string,
            +profile_image: ?string,
            +user: ?{|
              +id: string,
              +dbid: ?number,
              +name: ?string,
              +is_active: ?boolean,
              +source: ?{|
                +id: string,
                +dbid: ?number,
                +image: ?string,
              |},
            |},
          |},
        |},
      |}
    |}>
  |},
  +$refType: SourceInfo_source$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "image",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000,
  "type": "Int"
},
v6 = [
  v5
],
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "source",
  "storageKey": null,
  "args": null,
  "concreteType": "Source",
  "plural": false,
  "selections": [
    v0,
    v1,
    v2
  ]
},
v8 = [
  v0,
  v1,
  v4,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "content",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "file_data",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "attribution",
    "storageKey": "attribution(first:10000)",
    "args": v6,
    "concreteType": "UserConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "UserEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v0,
              v1,
              v3,
              v7
            ]
          }
        ]
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "annotator",
    "storageKey": null,
    "args": null,
    "concreteType": "Annotator",
    "plural": false,
    "selections": [
      v3,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "profile_image",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          v1,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "is_active",
            "args": null,
            "storageKey": null
          },
          v7
        ]
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "SourceInfo_source",
  "type": "Source",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "teamSlug",
      "type": "String!",
      "defaultValue": null
    }
  ],
  "selections": [
    v0,
    v1,
    v2,
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pusher_channel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "medias_count",
      "args": null,
      "storageKey": null
    },
    v4,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updated_at",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "archived",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "account_sources",
      "storageKey": "account_sources(first:10000)",
      "args": v6,
      "concreteType": "AccountSourceConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AccountSourceEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "AccountSource",
              "plural": false,
              "selections": [
                v0,
                v4,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "account",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Account",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "url",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "source_metadata",
      "name": "tasks",
      "storageKey": "tasks(fieldset:\"metadata\",first:10000)",
      "args": [
        {
          "kind": "Literal",
          "name": "fieldset",
          "value": "metadata",
          "type": "String"
        },
        v5
      ],
      "concreteType": "TaskConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TaskEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Task",
              "plural": false,
              "selections": [
                v0,
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "show_in_browser_extension",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "label",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "annotated_type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "description",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fieldset",
                  "args": null,
                  "storageKey": null
                },
                v4,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "jsonoptions",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "json_schema",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "options",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "team_task",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TeamTask",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "conditional_info",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "team_task_id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "responses",
                  "storageKey": "responses(first:10000)",
                  "args": v6,
                  "concreteType": "AnnotationConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AnnotationEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Annotation",
                          "plural": false,
                          "selections": v8
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "assignments",
                  "storageKey": "assignments(first:10000)",
                  "args": v6,
                  "concreteType": "UserConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "User",
                          "plural": false,
                          "selections": [
                            v3,
                            v0,
                            v1,
                            v7
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "first_response_value",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "first_response",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Annotation",
                  "plural": false,
                  "selections": v8
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '237e54271c23975ed0c4b093fc6dd708';
module.exports = node;
