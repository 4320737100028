/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedTeamCard_feed$ref: FragmentReference;
export type FeedTeamCard_feed = {|
  +data_points: ?$ReadOnlyArray<?number>,
  +$refType: FeedTeamCard_feed$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "FeedTeamCard_feed",
  "type": "Feed",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "data_points",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1e3a54fc77fa72932d25334a8beee7fb';
module.exports = node;
