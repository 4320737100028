/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ArticleForm_article$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExplainerForm_article$ref: FragmentReference;
export type ExplainerForm_article = {|
  +id: string,
  +$fragmentRefs: ArticleForm_article$ref,
  +$refType: ExplainerForm_article$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExplainerForm_article",
  "type": "Explainer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ArticleForm_article",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2e56a6e8911786f2bbe96f1a4240db50';
module.exports = node;
