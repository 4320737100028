/**
 * @flow
 * @relayHash d25cb836bcbe4d5a46ad6164667d0e6c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReplaceProjectMediaInput = {
  project_media_to_be_replaced_id: string,
  new_project_media_id: string,
  skip_send_report?: ?boolean,
  clientMutationId?: ?string,
};
export type MediaSuggestionsComponentReplaceProjectMediaMutationVariables = {|
  input: ReplaceProjectMediaInput
|};
export type MediaSuggestionsComponentReplaceProjectMediaMutationResponse = {|
  +replaceProjectMedia: ?{|
    +new_project_media: ?{|
      +dbid: ?number
    |}
  |}
|};
export type MediaSuggestionsComponentReplaceProjectMediaMutation = {|
  variables: MediaSuggestionsComponentReplaceProjectMediaMutationVariables,
  response: MediaSuggestionsComponentReplaceProjectMediaMutationResponse,
|};
*/


/*
mutation MediaSuggestionsComponentReplaceProjectMediaMutation(
  $input: ReplaceProjectMediaInput!
) {
  replaceProjectMedia(input: $input) {
    new_project_media {
      dbid
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReplaceProjectMediaInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ReplaceProjectMediaInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaSuggestionsComponentReplaceProjectMediaMutation",
  "id": null,
  "text": "mutation MediaSuggestionsComponentReplaceProjectMediaMutation(\n  $input: ReplaceProjectMediaInput!\n) {\n  replaceProjectMedia(input: $input) {\n    new_project_media {\n      dbid\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSuggestionsComponentReplaceProjectMediaMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "replaceProjectMedia",
        "storageKey": null,
        "args": v1,
        "concreteType": "ReplaceProjectMediaPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "new_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSuggestionsComponentReplaceProjectMediaMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "replaceProjectMedia",
        "storageKey": null,
        "args": v1,
        "concreteType": "ReplaceProjectMediaPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "new_project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f59c8ac983d40c2236044043f432993b';
module.exports = node;
