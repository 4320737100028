/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedActions_feedTeam$ref: FragmentReference;
export type FeedActions_feedTeam = {|
  +team_id: ?number,
  +permissions: ?string,
  +feed: ?{|
    +permissions: ?string,
    +saved_search_id: ?number,
    +saved_search: ?{|
      +title: ?string
    |},
    +teams_count: ?number,
    +feed_invitations: {|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +state: string
        |}
      |}>
    |},
    +team: ?{|
      +name: string,
      +dbid: ?number,
    |},
  |},
  +$refType: FeedActions_feedTeam$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "FeedActions_feedTeam",
  "type": "FeedTeam",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "team_id",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "feed",
      "storageKey": null,
      "args": null,
      "concreteType": "Feed",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "saved_search_id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "saved_search",
          "storageKey": null,
          "args": null,
          "concreteType": "SavedSearch",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "teams_count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "feed_invitations",
          "storageKey": "feed_invitations(first:100)",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 100,
              "type": "Int"
            }
          ],
          "concreteType": "FeedInvitationConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "FeedInvitationEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "FeedInvitation",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "state",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "team",
          "storageKey": null,
          "args": null,
          "concreteType": "PublicTeam",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "dbid",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f5c16d19eb2fbc9be93955a5d8e9a1ac';
module.exports = node;
