/**
 * @flow
 * @relayHash 2986028106422b9efb9dc1848e37f0c2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DrawerFeedsQueryVariables = {|
  teamSlug: string
|};
export type DrawerFeedsQueryResponse = {|
  +me: ?{|
    +id: string,
    +dbid: ?number,
    +feed_invitations: {|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dbid: number,
          +state: string,
          +feed_id: number,
          +feed: {|
            +name: ?string
          |},
          +type: string,
        |}
      |}>
    |},
  |},
  +team: ?{|
    +dbid: ?number,
    +slug: string,
    +permissions: ?string,
    +feeds: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dbid: ?number,
          +name: ?string,
          +team_id: ?number,
          +type: string,
        |}
      |}>
    |},
    +feed_teams: {|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dbid: ?number,
          +feed_id: ?number,
          +feed: ?{|
            +name: ?string
          |},
          +type: string,
        |}
      |}>
    |},
  |},
|};
export type DrawerFeedsQuery = {|
  variables: DrawerFeedsQueryVariables,
  response: DrawerFeedsQueryResponse,
|};
*/


/*
query DrawerFeedsQuery(
  $teamSlug: String!
) {
  me {
    id
    dbid
    feed_invitations(first: 10000) {
      edges {
        node {
          id
          dbid
          state
          feed_id
          feed {
            name
            id
          }
          type: __typename
        }
      }
    }
  }
  team(slug: $teamSlug) {
    dbid
    slug
    permissions
    feeds(first: 10000) {
      edges {
        node {
          id
          dbid
          name
          team_id
          type: __typename
        }
      }
    }
    feed_teams(first: 10000) {
      edges {
        node {
          id
          dbid
          feed_id
          feed {
            name
            id
          }
          type: __typename
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "feed_id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "feed",
  "storageKey": null,
  "args": null,
  "concreteType": "Feed",
  "plural": false,
  "selections": [
    v6
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": "type",
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "feeds",
  "storageKey": "feeds(first:10000)",
  "args": v3,
  "concreteType": "FeedConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "FeedEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Feed",
          "plural": false,
          "selections": [
            v1,
            v2,
            v6,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "team_id",
              "args": null,
              "storageKey": null
            },
            v8
          ]
        }
      ]
    }
  ]
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "feed",
  "storageKey": null,
  "args": null,
  "concreteType": "Feed",
  "plural": false,
  "selections": [
    v6,
    v1
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DrawerFeedsQuery",
  "id": null,
  "text": "query DrawerFeedsQuery(\n  $teamSlug: String!\n) {\n  me {\n    id\n    dbid\n    feed_invitations(first: 10000) {\n      edges {\n        node {\n          id\n          dbid\n          state\n          feed_id\n          feed {\n            name\n            id\n          }\n          type: __typename\n        }\n      }\n    }\n  }\n  team(slug: $teamSlug) {\n    dbid\n    slug\n    permissions\n    feeds(first: 10000) {\n      edges {\n        node {\n          id\n          dbid\n          name\n          team_id\n          type: __typename\n        }\n      }\n    }\n    feed_teams(first: 10000) {\n      edges {\n        node {\n          id\n          dbid\n          feed_id\n          feed {\n            name\n            id\n          }\n          type: __typename\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DrawerFeedsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed_invitations",
            "storageKey": "feed_invitations(first:10000)",
            "args": v3,
            "concreteType": "FeedInvitationConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FeedInvitationEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedInvitation",
                    "plural": false,
                    "selections": [
                      v1,
                      v2,
                      v4,
                      v5,
                      v7,
                      v8
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v9,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v10,
          v11,
          v12,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed_teams",
            "storageKey": "feed_teams(first:10000)",
            "args": v3,
            "concreteType": "FeedTeamConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FeedTeamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeam",
                    "plural": false,
                    "selections": [
                      v1,
                      v2,
                      v5,
                      v7,
                      v8
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DrawerFeedsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed_invitations",
            "storageKey": "feed_invitations(first:10000)",
            "args": v3,
            "concreteType": "FeedInvitationConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FeedInvitationEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedInvitation",
                    "plural": false,
                    "selections": [
                      v1,
                      v2,
                      v4,
                      v5,
                      v13,
                      v8
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v9,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v10,
          v11,
          v12,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed_teams",
            "storageKey": "feed_teams(first:10000)",
            "args": v3,
            "concreteType": "FeedTeamConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FeedTeamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeam",
                    "plural": false,
                    "selections": [
                      v1,
                      v2,
                      v5,
                      v13,
                      v8
                    ]
                  }
                ]
              }
            ]
          },
          v1
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4e263059206a79ccfaba84f110571e0d';
module.exports = node;
