/**
 * @flow
 * @relayHash 74667a6df9c34351191c5916bc1f843e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChangePasswordInput = {
  password: string,
  password_confirmation: string,
  reset_password_token?: ?string,
  current_password?: ?string,
  id?: ?number,
  clientMutationId?: ?string,
};
export type ChangePasswordComponentChangePasswordMutationVariables = {|
  input: ChangePasswordInput
|};
export type ChangePasswordComponentChangePasswordMutationResponse = {|
  +changePassword: ?{|
    +success: ?boolean
  |}
|};
export type ChangePasswordComponentChangePasswordMutation = {|
  variables: ChangePasswordComponentChangePasswordMutationVariables,
  response: ChangePasswordComponentChangePasswordMutationResponse,
|};
*/


/*
mutation ChangePasswordComponentChangePasswordMutation(
  $input: ChangePasswordInput!
) {
  changePassword(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ChangePasswordInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "changePassword",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ChangePasswordInput!"
      }
    ],
    "concreteType": "ChangePasswordPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ChangePasswordComponentChangePasswordMutation",
  "id": null,
  "text": "mutation ChangePasswordComponentChangePasswordMutation(\n  $input: ChangePasswordInput!\n) {\n  changePassword(input: $input) {\n    success\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ChangePasswordComponentChangePasswordMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ChangePasswordComponentChangePasswordMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '663649a269a7c7954b12c59f8bc0103e';
module.exports = node;
