/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CreateTeamDialog_team$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeamDetails_team$ref: FragmentReference;
export type TeamDetails_team = {|
  +id: string,
  +public_team_id: ?string,
  +name: string,
  +avatar: ?string,
  +permissions: ?string,
  +get_shorten_outgoing_urls: ?boolean,
  +get_outgoing_urls_utm_code: ?string,
  +tipline_newsletters: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +content_type: ?string,
        +enabled: ?boolean,
      |}
    |}>
  |},
  +$fragmentRefs: CreateTeamDialog_team$ref,
  +$refType: TeamDetails_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TeamDetails_team",
  "type": "Team",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "public_team_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avatar",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_shorten_outgoing_urls",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_outgoing_urls_utm_code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tipline_newsletters",
      "storageKey": "tipline_newsletters(first:10000)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000,
          "type": "Int"
        }
      ],
      "concreteType": "TiplineNewsletterConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TiplineNewsletterEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TiplineNewsletter",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "content_type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "enabled",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CreateTeamDialog_team",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '83d3092ad6e1de4441eee91f831267c0';
module.exports = node;
