/**
 * @flow
 * @relayHash 72bd89729698faef58eb47789f27c373
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeedInvitationQueryVariables = {|
  feedId?: ?number
|};
export type FeedInvitationQueryResponse = {|
  +feed_invitation: ?{|
    +state: string,
    +feed: {|
      +name: ?string,
      +feed_teams: {|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +team: ?{|
              +dbid: ?number
            |}
          |}
        |}>
      |},
    |},
    +user: {|
      +name: ?string,
      +email: ?string,
    |},
  |},
  +me: ?{|
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +dbid: ?number,
          +name: string,
          +avatar: ?string,
          +slug: string,
          +permissions: ?string,
        |}
      |}>
    |}
  |},
|};
export type FeedInvitationQuery = {|
  variables: FeedInvitationQueryVariables,
  response: FeedInvitationQueryResponse,
|};
*/


/*
query FeedInvitationQuery(
  $feedId: Int
) {
  feed_invitation(feedId: $feedId) {
    state
    feed {
      name
      feed_teams(first: 100) {
        edges {
          node {
            team {
              dbid
              id
            }
            id
          }
        }
      }
      id
    }
    user {
      name
      email
      id
    }
    id
  }
  me {
    teams(first: 1000) {
      edges {
        node {
          dbid
          name
          avatar
          slug
          permissions
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "feedId",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "feedId",
    "variableName": "feedId",
    "type": "Int"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100,
    "type": "Int"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000,
    "type": "Int"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "FeedInvitationQuery",
  "id": null,
  "text": "query FeedInvitationQuery(\n  $feedId: Int\n) {\n  feed_invitation(feedId: $feedId) {\n    state\n    feed {\n      name\n      feed_teams(first: 100) {\n        edges {\n          node {\n            team {\n              dbid\n              id\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n    user {\n      name\n      email\n      id\n    }\n    id\n  }\n  me {\n    teams(first: 1000) {\n      edges {\n        node {\n          dbid\n          name\n          avatar\n          slug\n          permissions\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FeedInvitationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feed_invitation",
        "storageKey": null,
        "args": v1,
        "concreteType": "FeedInvitation",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:100)",
                "args": v4,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "team",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PublicTeam",
                            "plural": false,
                            "selections": [
                              v5
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v3,
              v6
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teams",
            "storageKey": "teams(first:1000)",
            "args": v7,
            "concreteType": "TeamConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v5,
                      v3,
                      v8,
                      v9,
                      v10
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FeedInvitationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feed_invitation",
        "storageKey": null,
        "args": v1,
        "concreteType": "FeedInvitation",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:100)",
                "args": v4,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "team",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PublicTeam",
                            "plural": false,
                            "selections": [
                              v5,
                              v11
                            ]
                          },
                          v11
                        ]
                      }
                    ]
                  }
                ]
              },
              v11
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v3,
              v6,
              v11
            ]
          },
          v11
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teams",
            "storageKey": "teams(first:1000)",
            "args": v7,
            "concreteType": "TeamConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v5,
                      v3,
                      v8,
                      v9,
                      v10,
                      v11
                    ]
                  }
                ]
              }
            ]
          },
          v11
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '07b95a85c66a23db83698ea036236769';
module.exports = node;
