/**
 * @flow
 * @relayHash 6052d37e020178eda5df5c40ddd8d4fd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamMembersComponent_team$ref = any;
export type TeamMembersQueryVariables = {|
  teamSlug: string
|};
export type TeamMembersQueryResponse = {|
  +team: ?{|
    +$fragmentRefs: TeamMembersComponent_team$ref
  |}
|};
export type TeamMembersQuery = {|
  variables: TeamMembersQueryVariables,
  response: TeamMembersQueryResponse,
|};
*/


/*
query TeamMembersQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    ...TeamMembersComponent_team
    id
  }
}

fragment TeamMembersComponent_team on Team {
  id
  permissions
  ...TeamMemberActions_team
  team_users(first: 10000, status: ["invited", "member", "banned"]) {
    edges {
      node {
        id
        status
        role
        user {
          id
          dbid
          email
          is_bot
          last_active_at
          name
          profile_image
        }
        ...TeamMemberActions_teamUser
        ...ChangeUserRole_teamUser
      }
    }
  }
}

fragment TeamMemberActions_team on Team {
  id
  name
}

fragment TeamMemberActions_teamUser on TeamUser {
  id
  status
  permissions
  user {
    name
    email
    id
  }
}

fragment ChangeUserRole_teamUser on TeamUser {
  id
  status
  role
  permissions
  user {
    name
    email
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamMembersQuery",
  "id": null,
  "text": "query TeamMembersQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    ...TeamMembersComponent_team\n    id\n  }\n}\n\nfragment TeamMembersComponent_team on Team {\n  id\n  permissions\n  ...TeamMemberActions_team\n  team_users(first: 10000, status: [\"invited\", \"member\", \"banned\"]) {\n    edges {\n      node {\n        id\n        status\n        role\n        user {\n          id\n          dbid\n          email\n          is_bot\n          last_active_at\n          name\n          profile_image\n        }\n        ...TeamMemberActions_teamUser\n        ...ChangeUserRole_teamUser\n      }\n    }\n  }\n}\n\nfragment TeamMemberActions_team on Team {\n  id\n  name\n}\n\nfragment TeamMemberActions_teamUser on TeamUser {\n  id\n  status\n  permissions\n  user {\n    name\n    email\n    id\n  }\n}\n\nfragment ChangeUserRole_teamUser on TeamUser {\n  id\n  status\n  role\n  permissions\n  user {\n    name\n    email\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamMembersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TeamMembersComponent_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamMembersQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team_users",
            "storageKey": "team_users(first:10000,status:[\"invited\",\"member\",\"banned\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000,
                "type": "Int"
              },
              {
                "kind": "Literal",
                "name": "status",
                "value": [
                  "invited",
                  "member",
                  "banned"
                ],
                "type": "[String]"
              }
            ],
            "concreteType": "TeamUserConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamUserEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TeamUser",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "role",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "dbid",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "is_bot",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "last_active_at",
                            "args": null,
                            "storageKey": null
                          },
                          v4,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "profile_image",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      v3
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54718083ea443c6ed1e914f465d3ffe0';
module.exports = node;
