/**
 * @flow
 * @relayHash 94804977cd0ff5735eb2a565e7c1838f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteCheckUserInput = {
  id: number,
  clientMutationId?: ?string,
};
export type UserPrivacyDeleteCheckUserMutationVariables = {|
  input: DeleteCheckUserInput
|};
export type UserPrivacyDeleteCheckUserMutationResponse = {|
  +deleteCheckUser: ?{|
    +success: ?boolean
  |}
|};
export type UserPrivacyDeleteCheckUserMutation = {|
  variables: UserPrivacyDeleteCheckUserMutationVariables,
  response: UserPrivacyDeleteCheckUserMutationResponse,
|};
*/


/*
mutation UserPrivacyDeleteCheckUserMutation(
  $input: DeleteCheckUserInput!
) {
  deleteCheckUser(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteCheckUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteCheckUser",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteCheckUserInput!"
      }
    ],
    "concreteType": "DeleteCheckUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UserPrivacyDeleteCheckUserMutation",
  "id": null,
  "text": "mutation UserPrivacyDeleteCheckUserMutation(\n  $input: DeleteCheckUserInput!\n) {\n  deleteCheckUser(input: $input) {\n    success\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserPrivacyDeleteCheckUserMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UserPrivacyDeleteCheckUserMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6840fd40fcc08c1635c922291e4640a1';
module.exports = node;
