/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MediaCardLarge_projectMedia$ref = any;
type MediaFeedInformation_projectMedia$ref = any;
type MediaSecondaryBanner_projectMedia$ref = any;
type MediaSimilaritiesComponent_projectMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaComponent_projectMedia$ref: FragmentReference;
export type MediaComponent_projectMedia = {|
  +id: string,
  +dbid: ?number,
  +title: ?string,
  +type: ?string,
  +is_read: ?boolean,
  +permissions: ?string,
  +pusher_channel: ?string,
  +project_id: ?number,
  +last_seen: ?string,
  +demand: ?number,
  +articles_count: ?number,
  +requests_count: ?number,
  +picture: ?string,
  +show_warning_cover: ?boolean,
  +creator_name: ?string,
  +user_id: ?number,
  +channel: ?any,
  +notes_count: ?number,
  +report_status: ?string,
  +suggested_similar_items_count: ?number,
  +imported_from_feed_id: ?number,
  +imported_from_project_media_id: ?number,
  +suggested_similar_relationships: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +relationship_type: ?string,
        +dbid: ?number,
        +source_id: ?number,
        +target_id: ?number,
      |}
    |}>
  |},
  +suggested_main_item: ?{|
    +dbid: ?number,
    +team: ?{|
      +slug: string
    |},
    +claim_description: ?{|
      +id: string,
      +dbid: ?number,
      +context: ?string,
      +description: ?string,
      +user: ?{|
        +name: ?string
      |},
      +updated_at: ?string,
      +fact_check: ?{|
        +id: string,
        +summary: ?string,
        +title: ?string,
        +url: ?string,
        +user: ?{|
          +name: ?string
        |},
        +updated_at: ?string,
      |},
    |},
  |},
  +confirmed_main_item: ?{|
    +dbid: ?number,
    +team: ?{|
      +slug: string
    |},
  |},
  +is_confirmed_similar_to_another_item: ?boolean,
  +is_suggested: ?boolean,
  +is_secondary: ?boolean,
  +claim_description: ?{|
    +id: string,
    +dbid: ?number,
    +description: ?string,
    +context: ?string,
    +updated_at: ?string,
    +user: ?{|
      +name: ?string
    |},
    +fact_check: ?{|
      +id: string,
      +title: ?string,
      +summary: ?string,
      +url: ?string,
      +language: ?string,
      +updated_at: ?string,
      +user: ?{|
        +name: ?string
      |},
    |},
  |},
  +media: ?{|
    +url: ?string,
    +quote: ?string,
    +embed_path: ?string,
    +file_path: ?string,
    +metadata: ?any,
    +type: ?string,
    +picture: ?string,
  |},
  +last_status: ?string,
  +last_status_obj: ?{|
    +id: string,
    +data: ?any,
    +updated_at: ?string,
  |},
  +report: ?{|
    +id: string,
    +data: ?any,
  |},
  +tags: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +dbid: ?string,
        +fragment: ?string,
        +parsed_fragment: ?any,
        +annotated_id: ?string,
        +annotated_type: ?string,
        +tag_text_object: ?{|
          +id: string,
          +text: ?string,
        |},
      |}
    |}>
  |},
  +geolocations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id?: string,
        +parsed_fragment?: ?any,
        +content?: ?string,
      |}
    |}>
  |},
  +team: ?{|
    +id: string,
    +dbid: ?number,
    +slug: string,
    +name: string,
    +get_language: ?string,
    +get_languages: ?string,
    +get_report: ?any,
    +verification_statuses: ?any,
    +permissions: ?string,
    +team_bots: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +login: ?string
        |}
      |}>
    |},
    +smooch_bot: ?{|
      +id: string
    |},
  |},
  +$fragmentRefs: MediaSimilaritiesComponent_projectMedia$ref & MediaCardLarge_projectMedia$ref & MediaFeedInformation_projectMedia$ref & MediaSecondaryBanner_projectMedia$ref,
  +$refType: MediaComponent_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "picture",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000,
  "type": "Int"
},
v7 = [
  v6
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team",
  "storageKey": null,
  "args": null,
  "concreteType": "Team",
  "plural": false,
  "selections": [
    v8
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "context",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v12
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updated_at",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "data",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "parsed_fragment",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MediaComponent_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "MediaSimilaritiesComponent_projectMedia",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaCardLarge_projectMedia",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaFeedInformation_projectMedia",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaSecondaryBanner_projectMedia",
      "args": null
    },
    v0,
    v1,
    v2,
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "is_read",
      "args": null,
      "storageKey": null
    },
    v4,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pusher_channel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "project_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "last_seen",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "demand",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "articles_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requests_count",
      "args": null,
      "storageKey": null
    },
    v5,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "show_warning_cover",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "creator_name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "user_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "channel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": "notes_count",
      "name": "annotations_count",
      "args": [
        {
          "kind": "Literal",
          "name": "annotation_type",
          "value": "comment",
          "type": "String!"
        }
      ],
      "storageKey": "annotations_count(annotation_type:\"comment\")"
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "report_status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "suggested_similar_items_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "imported_from_feed_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "imported_from_project_media_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "suggested_similar_relationships",
      "storageKey": "suggested_similar_relationships(first:10000)",
      "args": v7,
      "concreteType": "RelationshipConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RelationshipEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Relationship",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "relationship_type",
                  "args": null,
                  "storageKey": null
                },
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "source_id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "target_id",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "suggested_main_item",
      "storageKey": null,
      "args": null,
      "concreteType": "ProjectMedia",
      "plural": false,
      "selections": [
        v1,
        v9,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "claim_description",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimDescription",
          "plural": false,
          "selections": [
            v0,
            v1,
            v10,
            v11,
            v13,
            v14,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "fact_check",
              "storageKey": null,
              "args": null,
              "concreteType": "FactCheck",
              "plural": false,
              "selections": [
                v0,
                v15,
                v2,
                v16,
                v13,
                v14
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "confirmed_main_item",
      "storageKey": null,
      "args": null,
      "concreteType": "ProjectMedia",
      "plural": false,
      "selections": [
        v1,
        v9
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "is_confirmed_similar_to_another_item",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "is_suggested",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "is_secondary",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "claim_description",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimDescription",
      "plural": false,
      "selections": [
        v0,
        v1,
        v11,
        v10,
        v14,
        v13,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "fact_check",
          "storageKey": null,
          "args": null,
          "concreteType": "FactCheck",
          "plural": false,
          "selections": [
            v0,
            v2,
            v15,
            v16,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "language",
              "args": null,
              "storageKey": null
            },
            v14,
            v13
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media",
      "storageKey": null,
      "args": null,
      "concreteType": "Media",
      "plural": false,
      "selections": [
        v16,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quote",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "embed_path",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "file_path",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "metadata",
          "args": null,
          "storageKey": null
        },
        v3,
        v5
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "last_status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "last_status_obj",
      "storageKey": null,
      "args": null,
      "concreteType": "Dynamic",
      "plural": false,
      "selections": [
        v0,
        v17,
        v14
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "report",
      "name": "dynamic_annotation_report_design",
      "storageKey": null,
      "args": null,
      "concreteType": "Dynamic",
      "plural": false,
      "selections": [
        v0,
        v17
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tags",
      "storageKey": "tags(first:10000)",
      "args": v7,
      "concreteType": "TagConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TagEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Tag",
              "plural": false,
              "selections": [
                v0,
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fragment",
                  "args": null,
                  "storageKey": null
                },
                v18,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "annotated_id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "annotated_type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tag_text_object",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TagText",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "text",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "geolocations",
      "name": "annotations",
      "storageKey": "annotations(annotation_type:\"geolocation\",first:10000)",
      "args": [
        {
          "kind": "Literal",
          "name": "annotation_type",
          "value": "geolocation",
          "type": "String!"
        },
        v6
      ],
      "concreteType": "AnnotationUnionConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AnnotationUnionEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": null,
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "type": "Dynamic",
                  "selections": [
                    v0,
                    v18,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "content",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        v0,
        v1,
        v8,
        v12,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "get_language",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "get_languages",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "get_report",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "verification_statuses",
          "args": null,
          "storageKey": null
        },
        v4,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "team_bots",
          "storageKey": "team_bots(first:10000)",
          "args": v7,
          "concreteType": "BotUserConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "BotUserEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BotUser",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "login",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": "smooch_bot",
          "name": "team_bot_installation",
          "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
          "args": [
            {
              "kind": "Literal",
              "name": "bot_identifier",
              "value": "smooch",
              "type": "String!"
            }
          ],
          "concreteType": "TeamBotInstallation",
          "plural": false,
          "selections": [
            v0
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5614c644ca98981cb82347c1a9502320';
module.exports = node;
