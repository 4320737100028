/**
 * @flow
 * @relayHash fa8471b821dc2661b418fd50b8e3d751
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProjectMediaInput = {
  id?: ?string,
  media_id?: ?number,
  related_to_id?: ?number,
  refresh_media?: ?number,
  archived?: ?number,
  previous_project_id?: ?number,
  project_id?: ?number,
  source_id?: ?number,
  read?: ?boolean,
  custom_title?: ?string,
  title_field?: ?string,
  clientMutationId?: ?string,
};
export type RefreshButtonUpdateProjectMediaMutationVariables = {|
  input: UpdateProjectMediaInput
|};
export type RefreshButtonUpdateProjectMediaMutationResponse = {|
  +updateProjectMedia: ?{|
    +project_media: ?{|
      +media: ?{|
        +metadata: ?any,
        +url: ?string,
        +embed_path: ?string,
        +thumbnail_path: ?string,
      |}
    |}
  |}
|};
export type RefreshButtonUpdateProjectMediaMutation = {|
  variables: RefreshButtonUpdateProjectMediaMutationVariables,
  response: RefreshButtonUpdateProjectMediaMutationResponse,
|};
*/


/*
mutation RefreshButtonUpdateProjectMediaMutation(
  $input: UpdateProjectMediaInput!
) {
  updateProjectMedia(input: $input) {
    project_media {
      media {
        metadata
        url
        embed_path
        thumbnail_path
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProjectMediaInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateProjectMediaInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "metadata",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "embed_path",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "thumbnail_path",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "RefreshButtonUpdateProjectMediaMutation",
  "id": null,
  "text": "mutation RefreshButtonUpdateProjectMediaMutation(\n  $input: UpdateProjectMediaInput!\n) {\n  updateProjectMedia(input: $input) {\n    project_media {\n      media {\n        metadata\n        url\n        embed_path\n        thumbnail_path\n        id\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RefreshButtonUpdateProjectMediaMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProjectMedia",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateProjectMediaPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "media",
                "storageKey": null,
                "args": null,
                "concreteType": "Media",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RefreshButtonUpdateProjectMediaMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProjectMedia",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateProjectMediaPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "media",
                "storageKey": null,
                "args": null,
                "concreteType": "Media",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v6
                ]
              },
              v6
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c56eceeb00fe87e29190df91a097a6e3';
module.exports = node;
