/**
 * @flow
 * @relayHash 2d663a61d140d40f70dddfe5e44a2b8d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LanguageFilterQueryVariables = {|
  teamSlug: string,
  random: string,
|};
export type LanguageFilterQueryResponse = {|
  +team: ?{|
    +get_languages: ?string
  |}
|};
export type LanguageFilterQuery = {|
  variables: LanguageFilterQueryVariables,
  response: LanguageFilterQueryResponse,
|};
*/


/*
query LanguageFilterQuery(
  $teamSlug: String!
  $random: String!
) {
  team(slug: $teamSlug, random: $random) {
    get_languages
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "random",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "random",
    "variableName": "random",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_languages",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "LanguageFilterQuery",
  "id": null,
  "text": "query LanguageFilterQuery(\n  $teamSlug: String!\n  $random: String!\n) {\n  team(slug: $teamSlug, random: $random) {\n    get_languages\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LanguageFilterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LanguageFilterQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4ea13183157cbfa0bead00fd44918083';
module.exports = node;
