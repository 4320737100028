/**
 * @flow
 * @relayHash a595be5e0021dd792dabc4a24e893343
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SmoochBotRemoveIntegrationInput = {
  team_bot_installation_id: string,
  integration_type: string,
  clientMutationId?: ?string,
};
export type SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutationVariables = {|
  input: SmoochBotRemoveIntegrationInput
|};
export type SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutationResponse = {|
  +smoochBotRemoveIntegration: ?{|
    +team_bot_installation: ?{|
      +id: string,
      +smooch_enabled_integrations: ?any,
    |}
  |}
|};
export type SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutation = {|
  variables: SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutationVariables,
  response: SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutationResponse,
|};
*/


/*
mutation SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutation(
  $input: SmoochBotRemoveIntegrationInput!
) {
  smoochBotRemoveIntegration(input: $input) {
    team_bot_installation {
      id
      smooch_enabled_integrations(force: true)
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SmoochBotRemoveIntegrationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "smoochBotRemoveIntegration",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SmoochBotRemoveIntegrationInput!"
      }
    ],
    "concreteType": "SmoochBotRemoveIntegrationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team_bot_installation",
        "storageKey": null,
        "args": null,
        "concreteType": "TeamBotInstallation",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "smooch_enabled_integrations",
            "args": [
              {
                "kind": "Literal",
                "name": "force",
                "value": true,
                "type": "Boolean"
              }
            ],
            "storageKey": "smooch_enabled_integrations(force:true)"
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutation",
  "id": null,
  "text": "mutation SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutation(\n  $input: SmoochBotRemoveIntegrationInput!\n) {\n  smoochBotRemoveIntegration(input: $input) {\n    team_bot_installation {\n      id\n      smooch_enabled_integrations(force: true)\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SmoochBotIntegrationButtonSmoochBotRemoveIntegrationMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '89d0a29bfc6ff45f6d5d2c5bdb95909b';
module.exports = node;
