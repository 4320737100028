/**
 * @flow
 * @relayHash e6bea20d0cb39b777d1fd9ca472e3de6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssignedToMeQueryVariables = {||};
export type AssignedToMeQueryResponse = {|
  +me: ?{|
    +dbid: ?number
  |}
|};
export type AssignedToMeQuery = {|
  variables: AssignedToMeQueryVariables,
  response: AssignedToMeQueryResponse,
|};
*/


/*
query AssignedToMeQuery {
  me {
    dbid
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AssignedToMeQuery",
  "id": null,
  "text": "query AssignedToMeQuery {\n  me {\n    dbid\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AssignedToMeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v0
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AssignedToMeQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6fcd2d10ac2f3a903e6ef5766cf6c6e5';
module.exports = node;
