/**
 * @flow
 * @relayHash 0e4fc05fefb93b4c1c4137c6c2bfb7b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFeedTeamInput = {
  id?: ?string,
  saved_search_id?: ?number,
  shared?: ?boolean,
  requests_filters?: ?any,
  clientMutationId?: ?string,
};
export type SaveFeedUpdateFeedTeamMutationVariables = {|
  input: UpdateFeedTeamInput
|};
export type SaveFeedUpdateFeedTeamMutationResponse = {|
  +updateFeedTeam: ?{|
    +feed_team: ?{|
      +dbid: ?number,
      +saved_search_id: ?number,
      +saved_search: ?{|
        +is_part_of_feeds: ?boolean
      |},
      +saved_search_was: ?{|
        +is_part_of_feeds: ?boolean
      |},
    |}
  |}
|};
export type SaveFeedUpdateFeedTeamMutation = {|
  variables: SaveFeedUpdateFeedTeamMutationVariables,
  response: SaveFeedUpdateFeedTeamMutationResponse,
|};
*/


/*
mutation SaveFeedUpdateFeedTeamMutation(
  $input: UpdateFeedTeamInput!
) {
  updateFeedTeam(input: $input) {
    feed_team {
      dbid
      saved_search_id
      saved_search {
        is_part_of_feeds
        id
      }
      saved_search_was {
        is_part_of_feeds
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFeedTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateFeedTeamInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "saved_search_id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "is_part_of_feeds",
  "args": null,
  "storageKey": null
},
v5 = [
  v4
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = [
  v4,
  v6
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveFeedUpdateFeedTeamMutation",
  "id": null,
  "text": "mutation SaveFeedUpdateFeedTeamMutation(\n  $input: UpdateFeedTeamInput!\n) {\n  updateFeedTeam(input: $input) {\n    feed_team {\n      dbid\n      saved_search_id\n      saved_search {\n        is_part_of_feeds\n        id\n      }\n      saved_search_was {\n        is_part_of_feeds\n        id\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveFeedUpdateFeedTeamMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFeedTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateFeedTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed_team",
            "storageKey": null,
            "args": null,
            "concreteType": "FeedTeam",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": v5
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search_was",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": v5
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveFeedUpdateFeedTeamMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFeedTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateFeedTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed_team",
            "storageKey": null,
            "args": null,
            "concreteType": "FeedTeam",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": v7
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "saved_search_was",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedSearch",
                "plural": false,
                "selections": v7
              },
              v6
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8bab946a3be9663bba04640055f4bca3';
module.exports = node;
