/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ClaimFactCheckForm_team$ref = any;
type ExplainerForm_team$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type NewArticleButton_team$ref: FragmentReference;
export type NewArticleButton_team = {|
  +$fragmentRefs: ClaimFactCheckForm_team$ref & ExplainerForm_team$ref,
  +$refType: NewArticleButton_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "NewArticleButton_team",
  "type": "Team",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ClaimFactCheckForm_team",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExplainerForm_team",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f0e2de53b173621e3874268b8b7f66e3';
module.exports = node;
