/**
 * @flow
 * @relayHash 6d87a5463a36e855d6c11b8c832cb1d7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FeedCollaboration_feed$ref = any;
type FeedMetadata_feed$ref = any;
export type FeedInvitationRespondQueryVariables = {|
  feedId?: ?number
|};
export type FeedInvitationRespondQueryResponse = {|
  +feed_invitation: ?{|
    +state: string,
    +dbid: number,
    +feed: {|
      +dbid: ?number,
      +name: ?string,
      +description: ?string,
      +feed_teams: {|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +team: ?{|
              +name: string,
              +dbid: ?number,
            |}
          |}
        |}>
      |},
    |},
    +feed_metadata: {|
      +$fragmentRefs: FeedMetadata_feed$ref
    |},
    +feed_collaboration: {|
      +$fragmentRefs: FeedCollaboration_feed$ref
    |},
    +user: {|
      +name: ?string,
      +email: ?string,
    |},
  |},
  +me: ?{|
    +current_team: ?{|
      +dbid: ?number,
      +slug: string,
      +permissions: ?string,
    |}
  |},
|};
export type FeedInvitationRespondQuery = {|
  variables: FeedInvitationRespondQueryVariables,
  response: FeedInvitationRespondQueryResponse,
|};
*/


/*
query FeedInvitationRespondQuery(
  $feedId: Int
) {
  feed_invitation(feedId: $feedId) {
    state
    dbid
    feed {
      dbid
      name
      description
      feed_teams(first: 100) {
        edges {
          node {
            team {
              name
              dbid
              id
            }
            id
          }
        }
      }
      id
    }
    feed_metadata: feed {
      ...FeedMetadata_feed
      id
    }
    feed_collaboration: feed {
      ...FeedCollaboration_feed
      id
    }
    user {
      name
      email
      id
    }
    id
  }
  me {
    current_team {
      dbid
      slug
      permissions
      id
    }
    id
  }
}

fragment FeedMetadata_feed on Feed {
  dbid
  created_at
  updated_at
  team {
    name
    id
  }
  user {
    email
    id
  }
}

fragment FeedCollaboration_feed on Feed {
  dbid
  feed_teams(first: 100) {
    edges {
      node {
        id
        team {
          dbid
          avatar
          name
          id
        }
      }
    }
  }
  feed_invitations(first: 100) {
    edges {
      node {
        id
        email
        state
      }
    }
  }
  team {
    dbid
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "feedId",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "feedId",
    "variableName": "feedId",
    "type": "Int"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100,
    "type": "Int"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "FeedInvitationRespondQuery",
  "id": null,
  "text": "query FeedInvitationRespondQuery(\n  $feedId: Int\n) {\n  feed_invitation(feedId: $feedId) {\n    state\n    dbid\n    feed {\n      dbid\n      name\n      description\n      feed_teams(first: 100) {\n        edges {\n          node {\n            team {\n              name\n              dbid\n              id\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n    feed_metadata: feed {\n      ...FeedMetadata_feed\n      id\n    }\n    feed_collaboration: feed {\n      ...FeedCollaboration_feed\n      id\n    }\n    user {\n      name\n      email\n      id\n    }\n    id\n  }\n  me {\n    current_team {\n      dbid\n      slug\n      permissions\n      id\n    }\n    id\n  }\n}\n\nfragment FeedMetadata_feed on Feed {\n  dbid\n  created_at\n  updated_at\n  team {\n    name\n    id\n  }\n  user {\n    email\n    id\n  }\n}\n\nfragment FeedCollaboration_feed on Feed {\n  dbid\n  feed_teams(first: 100) {\n    edges {\n      node {\n        id\n        team {\n          dbid\n          avatar\n          name\n          id\n        }\n      }\n    }\n  }\n  feed_invitations(first: 100) {\n    edges {\n      node {\n        id\n        email\n        state\n      }\n    }\n  }\n  team {\n    dbid\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FeedInvitationRespondQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feed_invitation",
        "storageKey": null,
        "args": v1,
        "concreteType": "FeedInvitation",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:100)",
                "args": v6,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "team",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PublicTeam",
                            "plural": false,
                            "selections": [
                              v4,
                              v3
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "feed_metadata",
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FeedMetadata_feed",
                "args": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "feed_collaboration",
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FeedCollaboration_feed",
                "args": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v4,
              v7
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v3,
              v8,
              v9
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FeedInvitationRespondQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feed_invitation",
        "storageKey": null,
        "args": v1,
        "concreteType": "FeedInvitation",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:100)",
                "args": v6,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "team",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PublicTeam",
                            "plural": false,
                            "selections": [
                              v4,
                              v3,
                              v10
                            ]
                          },
                          v10
                        ]
                      }
                    ]
                  }
                ]
              },
              v10
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "feed_metadata",
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "created_at",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updated_at",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicTeam",
                "plural": false,
                "selections": [
                  v4,
                  v10
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v7,
                  v10
                ]
              },
              v10
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "feed_collaboration",
            "name": "feed",
            "storageKey": null,
            "args": null,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:100)",
                "args": v6,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          v10,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "team",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PublicTeam",
                            "plural": false,
                            "selections": [
                              v3,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "avatar",
                                "args": null,
                                "storageKey": null
                              },
                              v4,
                              v10
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_invitations",
                "storageKey": "feed_invitations(first:100)",
                "args": v6,
                "concreteType": "FeedInvitationConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedInvitationEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedInvitation",
                        "plural": false,
                        "selections": [
                          v10,
                          v7,
                          v2
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicTeam",
                "plural": false,
                "selections": [
                  v3,
                  v10
                ]
              },
              v10
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v4,
              v7,
              v10
            ]
          },
          v10
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v3,
              v8,
              v9,
              v10
            ]
          },
          v10
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b8f8c8df84aadc8262293f3ea8f6e8ab';
module.exports = node;
