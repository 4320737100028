/**
 * @flow
 * @relayHash be65d840f3841e0983d6b2ea3876b36f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteTeamStatusInput = {
  team_id: string,
  status_id: string,
  fallback_status_id: string,
  clientMutationId?: ?string,
};
export type StatusesComponentDeleteTeamStatusMutationVariables = {|
  input: DeleteTeamStatusInput
|};
export type StatusesComponentDeleteTeamStatusMutationResponse = {|
  +deleteTeamStatus: ?{|
    +team: ?{|
      +id: string,
      +verification_statuses: ?any,
    |}
  |}
|};
export type StatusesComponentDeleteTeamStatusMutation = {|
  variables: StatusesComponentDeleteTeamStatusMutationVariables,
  response: StatusesComponentDeleteTeamStatusMutationResponse,
|};
*/


/*
mutation StatusesComponentDeleteTeamStatusMutation(
  $input: DeleteTeamStatusInput!
) {
  deleteTeamStatus(input: $input) {
    team {
      id
      verification_statuses
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteTeamStatusInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteTeamStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteTeamStatusInput!"
      }
    ],
    "concreteType": "DeleteTeamStatusPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verification_statuses",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "StatusesComponentDeleteTeamStatusMutation",
  "id": null,
  "text": "mutation StatusesComponentDeleteTeamStatusMutation(\n  $input: DeleteTeamStatusInput!\n) {\n  deleteTeamStatus(input: $input) {\n    team {\n      id\n      verification_statuses\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "StatusesComponentDeleteTeamStatusMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "StatusesComponentDeleteTeamStatusMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c4dfbc7d524d92a2c6c81794b280db70';
module.exports = node;
