/**
 * @flow
 * @relayHash 7d95ad9991894d7d77e449ed94769d27
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateClaimDescriptionInput = {
  id?: ?string,
  description?: ?string,
  context?: ?string,
  project_media_id?: ?number,
  clientMutationId?: ?string,
};
export type RemoveArticleButtonUpdateClaimDescriptionMutationVariables = {|
  input: UpdateClaimDescriptionInput
|};
export type RemoveArticleButtonUpdateClaimDescriptionMutationResponse = {|
  +updateClaimDescription: ?{|
    +claim_description: ?{|
      +project_media_was: ?{|
        +title: ?string,
        +last_status: ?string,
        +status: ?string,
        +last_status_obj: ?{|
          +locked: ?boolean,
          +content: ?string,
        |},
        +dynamic_annotation_report_design: ?{|
          +data: ?any
        |},
        +claim_description: ?{|
          +description: ?string,
          +fact_check: ?{|
            +title: ?string
          |},
        |},
      |},
      +project_media: ?{|
        +articles_count: ?number,
        +report_status: ?string,
        +fact_check: ?{|
          +id: string
        |},
      |},
    |}
  |}
|};
export type RemoveArticleButtonUpdateClaimDescriptionMutation = {|
  variables: RemoveArticleButtonUpdateClaimDescriptionMutationVariables,
  response: RemoveArticleButtonUpdateClaimDescriptionMutationResponse,
|};
*/


/*
mutation RemoveArticleButtonUpdateClaimDescriptionMutation(
  $input: UpdateClaimDescriptionInput!
) {
  updateClaimDescription(input: $input) {
    claim_description {
      project_media_was {
        title
        last_status
        status
        last_status_obj {
          locked
          content
          id
        }
        dynamic_annotation_report_design {
          data
          id
        }
        claim_description {
          description
          fact_check {
            title
            id
          }
          id
        }
        id
      }
      project_media {
        articles_count
        report_status
        fact_check {
          id
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateClaimDescriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateClaimDescriptionInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "last_status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "locked",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "data",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "articles_count",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "report_status",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "fact_check",
  "storageKey": null,
  "args": null,
  "concreteType": "FactCheck",
  "plural": false,
  "selections": [
    v11
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "RemoveArticleButtonUpdateClaimDescriptionMutation",
  "id": null,
  "text": "mutation RemoveArticleButtonUpdateClaimDescriptionMutation(\n  $input: UpdateClaimDescriptionInput!\n) {\n  updateClaimDescription(input: $input) {\n    claim_description {\n      project_media_was {\n        title\n        last_status\n        status\n        last_status_obj {\n          locked\n          content\n          id\n        }\n        dynamic_annotation_report_design {\n          data\n          id\n        }\n        claim_description {\n          description\n          fact_check {\n            title\n            id\n          }\n          id\n        }\n        id\n      }\n      project_media {\n        articles_count\n        report_status\n        fact_check {\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RemoveArticleButtonUpdateClaimDescriptionMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateClaimDescription",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateClaimDescriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "project_media_was",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "last_status_obj",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Dynamic",
                    "plural": false,
                    "selections": [
                      v5,
                      v6
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "dynamic_annotation_report_design",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Dynamic",
                    "plural": false,
                    "selections": [
                      v7
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "claim_description",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimDescription",
                    "plural": false,
                    "selections": [
                      v8,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fact_check",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FactCheck",
                        "plural": false,
                        "selections": [
                          v2
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "project_media",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v9,
                  v10,
                  v12
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RemoveArticleButtonUpdateClaimDescriptionMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateClaimDescription",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateClaimDescriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "project_media_was",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "last_status_obj",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Dynamic",
                    "plural": false,
                    "selections": [
                      v5,
                      v6,
                      v11
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "dynamic_annotation_report_design",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Dynamic",
                    "plural": false,
                    "selections": [
                      v7,
                      v11
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "claim_description",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimDescription",
                    "plural": false,
                    "selections": [
                      v8,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fact_check",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FactCheck",
                        "plural": false,
                        "selections": [
                          v2,
                          v11
                        ]
                      },
                      v11
                    ]
                  },
                  v11
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "project_media",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v9,
                  v10,
                  v12,
                  v11
                ]
              },
              v11
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2a64f490cb1e048a25b99eac15aa74a4';
module.exports = node;
