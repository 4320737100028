/**
 * @flow
 * @relayHash 10685bd279178eceaa563a6ace4f0203
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SmoochBotQueryVariables = {|
  teamSlug: string
|};
export type SmoochBotQueryResponse = {|
  +root: ?{|
    +current_team: ?{|
      +id: string,
      +dbid: ?number,
      +slug: string,
      +name: string,
      +get_language: ?string,
      +get_languages: ?string,
      +permissions: ?string,
      +smooch_bot: ?{|
        +id: string,
        +json_settings: ?string,
        +lock_version: ?number,
        +smooch_enabled_integrations: ?any,
        +team_bot: ?{|
          +id: string,
          +dbid: ?number,
          +avatar: ?string,
          +name: ?string,
          +identifier: ?string,
          +default: ?boolean,
          +settings_as_json_schema: ?string,
          +settings_ui_schema: ?string,
          +description: ?string,
        |},
      |},
      +tipline_resources: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +dbid: ?number,
            +uuid: ?string,
            +language: ?string,
            +title: ?string,
            +header_type: ?string,
            +header_file_url: ?string,
            +header_overlay_text: ?string,
            +content_type: ?string,
            +content: ?string,
            +number_of_articles: ?number,
            +rss_feed_url: ?string,
          |}
        |}>
      |},
    |},
    +team_bots_listed: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dbid: ?number,
          +name: ?string,
        |}
      |}>
    |},
  |}
|};
export type SmoochBotQuery = {|
  variables: SmoochBotQueryVariables,
  response: SmoochBotQueryResponse,
|};
*/


/*
query SmoochBotQuery(
  $teamSlug: String!
) {
  root {
    current_team {
      id
      dbid
      slug
      name
      get_language
      get_languages
      permissions
      smooch_bot: team_bot_installation(bot_identifier: "smooch") {
        id
        json_settings
        lock_version
        smooch_enabled_integrations(force: true)
        team_bot: bot_user {
          id
          dbid
          avatar
          name
          identifier
          default
          settings_as_json_schema(team_slug: $teamSlug)
          settings_ui_schema
          description: get_description
        }
      }
      tipline_resources(first: 10000) {
        edges {
          node {
            id
            dbid
            uuid
            language
            title
            header_type
            header_file_url
            header_overlay_text
            content_type
            content
            number_of_articles
            rss_feed_url
          }
        }
      }
    }
    team_bots_listed(first: 10000) {
      edges {
        node {
          id
          dbid
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "current_team",
  "storageKey": null,
  "args": null,
  "concreteType": "Team",
  "plural": false,
  "selections": [
    v1,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_language",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_languages",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "smooch_bot",
      "name": "team_bot_installation",
      "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
      "args": [
        {
          "kind": "Literal",
          "name": "bot_identifier",
          "value": "smooch",
          "type": "String!"
        }
      ],
      "concreteType": "TeamBotInstallation",
      "plural": false,
      "selections": [
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "json_settings",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lock_version",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "smooch_enabled_integrations",
          "args": [
            {
              "kind": "Literal",
              "name": "force",
              "value": true,
              "type": "Boolean"
            }
          ],
          "storageKey": "smooch_enabled_integrations(force:true)"
        },
        {
          "kind": "LinkedField",
          "alias": "team_bot",
          "name": "bot_user",
          "storageKey": null,
          "args": null,
          "concreteType": "BotUser",
          "plural": false,
          "selections": [
            v1,
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "avatar",
              "args": null,
              "storageKey": null
            },
            v3,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "identifier",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "default",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "settings_as_json_schema",
              "args": [
                {
                  "kind": "Variable",
                  "name": "team_slug",
                  "variableName": "teamSlug",
                  "type": "String"
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "settings_ui_schema",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": "description",
              "name": "get_description",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tipline_resources",
      "storageKey": "tipline_resources(first:10000)",
      "args": v4,
      "concreteType": "TiplineResourceConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TiplineResourceEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TiplineResource",
              "plural": false,
              "selections": [
                v1,
                v2,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "uuid",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "language",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "header_type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "header_file_url",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "header_overlay_text",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "content_type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "content",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "number_of_articles",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "rss_feed_url",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team_bots_listed",
  "storageKey": "team_bots_listed(first:10000)",
  "args": v4,
  "concreteType": "BotUserConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "BotUserEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "BotUser",
          "plural": false,
          "selections": [
            v1,
            v2,
            v3
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SmoochBotQuery",
  "id": null,
  "text": "query SmoochBotQuery(\n  $teamSlug: String!\n) {\n  root {\n    current_team {\n      id\n      dbid\n      slug\n      name\n      get_language\n      get_languages\n      permissions\n      smooch_bot: team_bot_installation(bot_identifier: \"smooch\") {\n        id\n        json_settings\n        lock_version\n        smooch_enabled_integrations(force: true)\n        team_bot: bot_user {\n          id\n          dbid\n          avatar\n          name\n          identifier\n          default\n          settings_as_json_schema(team_slug: $teamSlug)\n          settings_ui_schema\n          description: get_description\n        }\n      }\n      tipline_resources(first: 10000) {\n        edges {\n          node {\n            id\n            dbid\n            uuid\n            language\n            title\n            header_type\n            header_file_url\n            header_overlay_text\n            content_type\n            content\n            number_of_articles\n            rss_feed_url\n          }\n        }\n      }\n    }\n    team_bots_listed(first: 10000) {\n      edges {\n        node {\n          id\n          dbid\n          name\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SmoochBotQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "root",
        "storageKey": null,
        "args": null,
        "concreteType": "RootLevel",
        "plural": false,
        "selections": [
          v5,
          v6
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SmoochBotQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "root",
        "storageKey": null,
        "args": null,
        "concreteType": "RootLevel",
        "plural": false,
        "selections": [
          v5,
          v6,
          v1
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '381eea676c0f6954725bb8a06c5cad0e';
module.exports = node;
