/**
 * @flow
 * @relayHash a885a60b51810a70d446789d9fe0e9e6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ApiKeys_team$ref = any;
export type CreateApiKeyInput = {
  title?: ?string,
  description?: ?string,
  clientMutationId?: ?string,
};
export type ApiKeyCreateMutationVariables = {|
  input: CreateApiKeyInput
|};
export type ApiKeyCreateMutationResponse = {|
  +createApiKey: ?{|
    +team: ?{|
      +id: string,
      +$fragmentRefs: ApiKeys_team$ref,
    |}
  |}
|};
export type ApiKeyCreateMutation = {|
  variables: ApiKeyCreateMutationVariables,
  response: ApiKeyCreateMutationResponse,
|};
*/


/*
mutation ApiKeyCreateMutation(
  $input: CreateApiKeyInput!
) {
  createApiKey(input: $input) {
    team {
      id
      ...ApiKeys_team
    }
  }
}

fragment ApiKeys_team on Team {
  api_keys(first: 10000) {
    edges {
      node {
        dbid
        ...ApiKeyEntry_apiKey
        id
      }
    }
  }
}

fragment ApiKeyEntry_apiKey on ApiKey {
  id
  title
  description
  access_token
  created_at
  expire_at
  user {
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateApiKeyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateApiKeyInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ApiKeyCreateMutation",
  "id": null,
  "text": "mutation ApiKeyCreateMutation(\n  $input: CreateApiKeyInput!\n) {\n  createApiKey(input: $input) {\n    team {\n      id\n      ...ApiKeys_team\n    }\n  }\n}\n\nfragment ApiKeys_team on Team {\n  api_keys(first: 10000) {\n    edges {\n      node {\n        dbid\n        ...ApiKeyEntry_apiKey\n        id\n      }\n    }\n  }\n}\n\nfragment ApiKeyEntry_apiKey on ApiKey {\n  id\n  title\n  description\n  access_token\n  created_at\n  expire_at\n  user {\n    name\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ApiKeyCreateMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createApiKey",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateApiKeyPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "FragmentSpread",
                "name": "ApiKeys_team",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ApiKeyCreateMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createApiKey",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateApiKeyPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "api_keys",
                "storageKey": "api_keys(first:10000)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000,
                    "type": "Int"
                  }
                ],
                "concreteType": "ApiKeyConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApiKeyEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApiKey",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "dbid",
                            "args": null,
                            "storageKey": null
                          },
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "description",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "access_token",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "expire_at",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              },
                              v2
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '345841d6a1ed1ba9be70388f375e6f1f';
module.exports = node;
