/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedTeamCard_team$ref: FragmentReference;
export type FeedTeamCard_team = {|
  +name?: string,
  +dbid?: ?number,
  +avatar?: ?string,
  +$refType: FeedTeamCard_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "dbid",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "avatar",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "FeedTeamCard_team",
  "type": "Node",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "InlineFragment",
      "type": "Team",
      "selections": v0
    },
    {
      "kind": "InlineFragment",
      "type": "PublicTeam",
      "selections": v0
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb62495052da9b40b13e2b8067552125';
module.exports = node;
