/**
 * @flow
 * @relayHash d67d3d6d470eeeee45dcba9814dc0c19
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamTaskInput = {
  id?: ?string,
  label: string,
  description?: ?string,
  order?: ?number,
  fieldset?: ?string,
  required?: ?boolean,
  task_type?: ?string,
  json_options?: ?string,
  json_schema?: ?string,
  keep_completed_tasks?: ?boolean,
  associated_type?: ?string,
  show_in_browser_extension?: ?boolean,
  conditional_info?: ?string,
  options_diff?: ?any,
  clientMutationId?: ?string,
};
export type TeamTasksListItemUpdateTeamTaskMutationVariables = {|
  input: UpdateTeamTaskInput
|};
export type TeamTasksListItemUpdateTeamTaskMutationResponse = {|
  +updateTeamTask: ?{|
    +team: ?{|
      +team_tasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +label: ?string,
            +order: ?number,
            +tasks_count: ?number,
          |}
        |}>
      |}
    |}
  |}
|};
export type TeamTasksListItemUpdateTeamTaskMutation = {|
  variables: TeamTasksListItemUpdateTeamTaskMutationVariables,
  response: TeamTasksListItemUpdateTeamTaskMutationResponse,
|};
*/


/*
mutation TeamTasksListItemUpdateTeamTaskMutation(
  $input: UpdateTeamTaskInput!
) {
  updateTeamTask(input: $input) {
    team {
      team_tasks(fieldset: "metadata", first: 10000) {
        edges {
          node {
            id
            label
            order
            tasks_count
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamTaskInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateTeamTaskInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team_tasks",
  "storageKey": "team_tasks(fieldset:\"metadata\",first:10000)",
  "args": [
    {
      "kind": "Literal",
      "name": "fieldset",
      "value": "metadata",
      "type": "String"
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000,
      "type": "Int"
    }
  ],
  "concreteType": "TeamTaskConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TeamTaskEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "TeamTask",
          "plural": false,
          "selections": [
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "label",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "order",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "tasks_count",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TeamTasksListItemUpdateTeamTaskMutation",
  "id": null,
  "text": "mutation TeamTasksListItemUpdateTeamTaskMutation(\n  $input: UpdateTeamTaskInput!\n) {\n  updateTeamTask(input: $input) {\n    team {\n      team_tasks(fieldset: \"metadata\", first: 10000) {\n        edges {\n          node {\n            id\n            label\n            order\n            tasks_count\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamTasksListItemUpdateTeamTaskMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeamTask",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamTaskPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamTasksListItemUpdateTeamTaskMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeamTask",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamTaskPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v3,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9716f4b63011df5a39d96b945be582e4';
module.exports = node;
