/**
 * @flow
 * @relayHash 0242cadaf1129e3835664b9404597a31
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateDynamicAnnotationFlagInput = {
  fragment?: ?string,
  annotated_id?: ?string,
  annotated_type?: ?string,
  action?: ?string,
  set_attribution?: ?string,
  action_data?: ?string,
  set_fields: string,
  clientMutationId?: ?string,
};
export type SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutationVariables = {|
  input: CreateDynamicAnnotationFlagInput
|};
export type SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutationResponse = {|
  +createDynamicAnnotationFlag: ?{|
    +project_media: ?{|
      +id: string,
      +show_warning_cover: ?boolean,
      +dynamic_annotation_flag: ?{|
        +id: string,
        +dbid: ?string,
        +content: ?string,
        +data: ?any,
        +annotator: ?{|
          +name: ?string
        |},
      |},
    |}
  |}
|};
export type SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutation = {|
  variables: SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutationVariables,
  response: SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutationResponse,
|};
*/


/*
mutation SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutation(
  $input: CreateDynamicAnnotationFlagInput!
) {
  createDynamicAnnotationFlag(input: $input) {
    project_media {
      id
      show_warning_cover
      dynamic_annotation_flag {
        id
        dbid
        content
        data
        annotator {
          name
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDynamicAnnotationFlagInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateDynamicAnnotationFlagInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "show_warning_cover",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "data",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutation",
  "id": null,
  "text": "mutation SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutation(\n  $input: CreateDynamicAnnotationFlagInput!\n) {\n  createDynamicAnnotationFlag(input: $input) {\n    project_media {\n      id\n      show_warning_cover\n      dynamic_annotation_flag {\n        id\n        dbid\n        content\n        data\n        annotator {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createDynamicAnnotationFlag",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateDynamicAnnotationFlagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "dynamic_annotation_flag",
                "storageKey": null,
                "args": null,
                "concreteType": "Dynamic",
                "plural": false,
                "selections": [
                  v2,
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "annotator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Annotator",
                    "plural": false,
                    "selections": [
                      v7
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SensitiveContentMenuButtonCreateDynamicAnnotationFlagMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createDynamicAnnotationFlag",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateDynamicAnnotationFlagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "dynamic_annotation_flag",
                "storageKey": null,
                "args": null,
                "concreteType": "Dynamic",
                "plural": false,
                "selections": [
                  v2,
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "annotator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Annotator",
                    "plural": false,
                    "selections": [
                      v7,
                      v2
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '559928d5bbf8c5efc902f6a12edd1d30';
module.exports = node;
