/**
 * @flow
 * @relayHash c2e8479567d292478683a7a0af8aac23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TeamTaskContainerQueryVariables = {|
  slug: string,
  dbid: number,
|};
export type TeamTaskContainerQueryResponse = {|
  +team: ?{|
    +team_task: ?{|
      +id: string,
      +dbid: ?number,
      +label: ?string,
      +description: ?string,
      +options: ?any,
      +type: ?string,
      +associated_type: ?string,
      +json_schema: ?string,
      +show_in_browser_extension: ?boolean,
      +required: ?boolean,
      +conditional_info: ?string,
      +tasks_count: ?number,
      +tasks_with_answers_count: ?number,
    |}
  |}
|};
export type TeamTaskContainerQuery = {|
  variables: TeamTaskContainerQueryVariables,
  response: TeamTaskContainerQueryResponse,
|};
*/


/*
query TeamTaskContainerQuery(
  $slug: String!
  $dbid: Int!
) {
  team(slug: $slug) {
    team_task(dbid: $dbid) {
      id
      dbid
      label
      description
      options
      type
      associated_type
      json_schema
      show_in_browser_extension
      required
      conditional_info
      tasks_count
      tasks_with_answers_count
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dbid",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team_task",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "dbid",
      "variableName": "dbid",
      "type": "Int!"
    }
  ],
  "concreteType": "TeamTask",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dbid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "label",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "options",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "associated_type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "json_schema",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "show_in_browser_extension",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "required",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "conditional_info",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tasks_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tasks_with_answers_count",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamTaskContainerQuery",
  "id": null,
  "text": "query TeamTaskContainerQuery(\n  $slug: String!\n  $dbid: Int!\n) {\n  team(slug: $slug) {\n    team_task(dbid: $dbid) {\n      id\n      dbid\n      label\n      description\n      options\n      type\n      associated_type\n      json_schema\n      show_in_browser_extension\n      required\n      conditional_info\n      tasks_count\n      tasks_with_answers_count\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamTaskContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamTaskContainerQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v3,
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '99ea4b1511729b48f5f98484e606a93c';
module.exports = node;
