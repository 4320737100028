/**
 * @flow
 * @relayHash d547574733152d6ca53588e2a4b2b373
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DrawerTiplineQueryVariables = {|
  teamSlug: string
|};
export type DrawerTiplineQueryResponse = {|
  +me: ?{|
    +id: string,
    +dbid: ?number,
  |},
  +team: ?{|
    +dbid: ?number,
    +slug: string,
    +permissions: ?string,
    +medias_count: ?number,
    +verification_statuses: ?any,
    +alegre_bot: ?{|
      +id: string,
      +alegre_settings: ?any,
    |},
    +smooch_bot: ?{|
      +id: string
    |},
    +saved_searches: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dbid: ?number,
          +title: ?string,
          +is_part_of_feeds: ?boolean,
          +medias_count: ?number,
        |}
      |}>
    |},
    +trash_count: ?number,
    +spam_count: ?number,
  |},
|};
export type DrawerTiplineQuery = {|
  variables: DrawerTiplineQueryVariables,
  response: DrawerTiplineQueryResponse,
|};
*/


/*
query DrawerTiplineQuery(
  $teamSlug: String!
) {
  me {
    id
    dbid
  }
  team(slug: $teamSlug) {
    dbid
    slug
    permissions
    medias_count
    verification_statuses
    alegre_bot: team_bot_installation(bot_identifier: "alegre") {
      id
      alegre_settings
    }
    smooch_bot: team_bot_installation(bot_identifier: "smooch") {
      id
    }
    saved_searches(first: 10000) {
      edges {
        node {
          id
          dbid
          title
          is_part_of_feeds
          medias_count: items_count
        }
      }
    }
    trash_count
    spam_count
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "Me",
  "plural": false,
  "selections": [
    v1,
    v2
  ]
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "medias_count",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "verification_statuses",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": "alegre_bot",
  "name": "team_bot_installation",
  "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
  "args": [
    {
      "kind": "Literal",
      "name": "bot_identifier",
      "value": "alegre",
      "type": "String!"
    }
  ],
  "concreteType": "TeamBotInstallation",
  "plural": false,
  "selections": [
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "alegre_settings",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "LinkedField",
  "alias": "smooch_bot",
  "name": "team_bot_installation",
  "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
  "args": [
    {
      "kind": "Literal",
      "name": "bot_identifier",
      "value": "smooch",
      "type": "String!"
    }
  ],
  "concreteType": "TeamBotInstallation",
  "plural": false,
  "selections": [
    v1
  ]
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "saved_searches",
  "storageKey": "saved_searches(first:10000)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000,
      "type": "Int"
    }
  ],
  "concreteType": "SavedSearchConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "SavedSearchEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "SavedSearch",
          "plural": false,
          "selections": [
            v1,
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "is_part_of_feeds",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": "medias_count",
              "name": "items_count",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "trash_count",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "spam_count",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DrawerTiplineQuery",
  "id": null,
  "text": "query DrawerTiplineQuery(\n  $teamSlug: String!\n) {\n  me {\n    id\n    dbid\n  }\n  team(slug: $teamSlug) {\n    dbid\n    slug\n    permissions\n    medias_count\n    verification_statuses\n    alegre_bot: team_bot_installation(bot_identifier: \"alegre\") {\n      id\n      alegre_settings\n    }\n    smooch_bot: team_bot_installation(bot_identifier: \"smooch\") {\n      id\n    }\n    saved_searches(first: 10000) {\n      edges {\n        node {\n          id\n          dbid\n          title\n          is_part_of_feeds\n          medias_count: items_count\n        }\n      }\n    }\n    trash_count\n    spam_count\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DrawerTiplineQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v4,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v5,
          v6,
          v7,
          v8,
          v9,
          v10,
          v11,
          v12,
          v13
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DrawerTiplineQuery",
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v4,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v5,
          v6,
          v7,
          v8,
          v9,
          v10,
          v11,
          v12,
          v13,
          v1
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0c1604015020118b02882f110b42f54e';
module.exports = node;
