/**
 * @flow
 * @relayHash c5e421781282f76c6a8380c299e3511f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTagTextInput = {
  id?: ?string,
  text?: ?string,
  clientMutationId?: ?string,
};
export type SaveTagUpdateTagTextMutationVariables = {|
  input: UpdateTagTextInput
|};
export type SaveTagUpdateTagTextMutationResponse = {|
  +updateTagText: ?{|
    +tag_text: ?{|
      +id: string,
      +text: ?string,
      +tags_count: ?number,
      +updated_at: ?string,
    |}
  |}
|};
export type SaveTagUpdateTagTextMutation = {|
  variables: SaveTagUpdateTagTextMutationVariables,
  response: SaveTagUpdateTagTextMutationResponse,
|};
*/


/*
mutation SaveTagUpdateTagTextMutation(
  $input: UpdateTagTextInput!
) {
  updateTagText(input: $input) {
    tag_text {
      id
      text
      tags_count
      updated_at
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTagTextInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateTagText",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateTagTextInput!"
      }
    ],
    "concreteType": "UpdateTagTextPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tag_text",
        "storageKey": null,
        "args": null,
        "concreteType": "TagText",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "text",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tags_count",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updated_at",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveTagUpdateTagTextMutation",
  "id": null,
  "text": "mutation SaveTagUpdateTagTextMutation(\n  $input: UpdateTagTextInput!\n) {\n  updateTagText(input: $input) {\n    tag_text {\n      id\n      text\n      tags_count\n      updated_at\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveTagUpdateTagTextMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveTagUpdateTagTextMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '93913ea516a772d8a7e7a30966e13b2c';
module.exports = node;
