/**
 * @flow
 * @relayHash 439914f551f3785334d1955c8372f9ae
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyAccountSourceInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type SourceInfoDeleteAccountSourceMutationVariables = {|
  input: DestroyAccountSourceInput
|};
export type SourceInfoDeleteAccountSourceMutationResponse = {|
  +destroyAccountSource: ?{|
    +deletedId: ?string,
    +source: ?{|
      +account_sources: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +permissions: ?string,
            +account: ?{|
              +id: string,
              +url: string,
            |},
          |}
        |}>
      |}
    |},
  |}
|};
export type SourceInfoDeleteAccountSourceMutation = {|
  variables: SourceInfoDeleteAccountSourceMutationVariables,
  response: SourceInfoDeleteAccountSourceMutationResponse,
|};
*/


/*
mutation SourceInfoDeleteAccountSourceMutation(
  $input: DestroyAccountSourceInput!
) {
  destroyAccountSource(input: $input) {
    deletedId
    source {
      account_sources(first: 10000) {
        edges {
          node {
            id
            permissions
            account {
              id
              url
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyAccountSourceInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DestroyAccountSourceInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deletedId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "account_sources",
  "storageKey": "account_sources(first:10000)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000,
      "type": "Int"
    }
  ],
  "concreteType": "AccountSourceConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AccountSourceEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "AccountSource",
          "plural": false,
          "selections": [
            v3,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "permissions",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "account",
              "storageKey": null,
              "args": null,
              "concreteType": "Account",
              "plural": false,
              "selections": [
                v3,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "url",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SourceInfoDeleteAccountSourceMutation",
  "id": null,
  "text": "mutation SourceInfoDeleteAccountSourceMutation(\n  $input: DestroyAccountSourceInput!\n) {\n  destroyAccountSource(input: $input) {\n    deletedId\n    source {\n      account_sources(first: 10000) {\n        edges {\n          node {\n            id\n            permissions\n            account {\n              id\n              url\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SourceInfoDeleteAccountSourceMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyAccountSource",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyAccountSourcePayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source",
            "storageKey": null,
            "args": null,
            "concreteType": "Source",
            "plural": false,
            "selections": [
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SourceInfoDeleteAccountSourceMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyAccountSource",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyAccountSourcePayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source",
            "storageKey": null,
            "args": null,
            "concreteType": "Source",
            "plural": false,
            "selections": [
              v4,
              v3
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a66aa9f7c6517f4c4b4d26597c3b585f';
module.exports = node;
