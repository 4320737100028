/**
 * @flow
 * @relayHash af338c5167b4e543e0b79eb09d4a965d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyFeedInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type SaveFeedDestroyFeedMutationVariables = {|
  input: DestroyFeedInput
|};
export type SaveFeedDestroyFeedMutationResponse = {|
  +destroyFeed: ?{|
    +deletedId: ?string,
    +team: ?{|
      +feed_teams: {|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +dbid: ?number,
            +feed_id: ?number,
            +saved_search_id: ?number,
            +feed: ?{|
              +name: ?string
            |},
            +type: string,
          |}
        |}>
      |},
      +feeds: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +dbid: ?number,
            +name: ?string,
            +team_id: ?number,
            +type: string,
          |}
        |}>
      |},
    |},
  |}
|};
export type SaveFeedDestroyFeedMutation = {|
  variables: SaveFeedDestroyFeedMutationVariables,
  response: SaveFeedDestroyFeedMutationResponse,
|};
*/


/*
mutation SaveFeedDestroyFeedMutation(
  $input: DestroyFeedInput!
) {
  destroyFeed(input: $input) {
    deletedId
    team {
      feed_teams(first: 10000) {
        edges {
          node {
            id
            dbid
            feed_id
            saved_search_id
            feed {
              name
              id
            }
            type: __typename
          }
        }
      }
      feeds(first: 10000) {
        edges {
          node {
            id
            dbid
            name
            team_id
            type: __typename
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyFeedInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DestroyFeedInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deletedId",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "feed_id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "saved_search_id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": "type",
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "feeds",
  "storageKey": "feeds(first:10000)",
  "args": v3,
  "concreteType": "FeedConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "FeedEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Feed",
          "plural": false,
          "selections": [
            v4,
            v5,
            v8,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "team_id",
              "args": null,
              "storageKey": null
            },
            v9
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveFeedDestroyFeedMutation",
  "id": null,
  "text": "mutation SaveFeedDestroyFeedMutation(\n  $input: DestroyFeedInput!\n) {\n  destroyFeed(input: $input) {\n    deletedId\n    team {\n      feed_teams(first: 10000) {\n        edges {\n          node {\n            id\n            dbid\n            feed_id\n            saved_search_id\n            feed {\n              name\n              id\n            }\n            type: __typename\n          }\n        }\n      }\n      feeds(first: 10000) {\n        edges {\n          node {\n            id\n            dbid\n            name\n            team_id\n            type: __typename\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveFeedDestroyFeedMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyFeed",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyFeedPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:10000)",
                "args": v3,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          v4,
                          v5,
                          v6,
                          v7,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "feed",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Feed",
                            "plural": false,
                            "selections": [
                              v8
                            ]
                          },
                          v9
                        ]
                      }
                    ]
                  }
                ]
              },
              v10
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveFeedDestroyFeedMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyFeed",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyFeedPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:10000)",
                "args": v3,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          v4,
                          v5,
                          v6,
                          v7,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "feed",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Feed",
                            "plural": false,
                            "selections": [
                              v8,
                              v4
                            ]
                          },
                          v9
                        ]
                      }
                    ]
                  }
                ]
              },
              v10,
              v4
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6407df8238fab12d192a981b8913c89f';
module.exports = node;
